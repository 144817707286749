import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import * as React from 'react';
import dayjs from 'dayjs';
import MDDatePicker from "components/MDDatePicker";
// import { Calendar } from 'react-date-range';

function TestPage() {
    const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const renderPackageList = () => <></>
    const handleSelect = (date) => {
        console.log(date); // native Date object
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
                <MDDatePicker
                    input={{ placeholder: "Select a date" }}
                    options={{
                        minDate: "2023-01-01",
                        enableTime: true,
                    }}/>


            <Footer />
        </DashboardLayout>
    );
    }

export default TestPage;
