import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getPackages = () => {
  return axios.get(`${getPrivateURL()}packages/`, {withCredentials:true} );
};
export const getMyPackages = () => {
  return axios.get(`${getPrivateURL()}my_package/`, {withCredentials:true} );
};
export const getPackage = (package_id) => {
  return axios.get(`${getPrivateURL()}packages/${package_id}` , {withCredentials:true} );
};
export const insertPackage = (values) => {
  return axios.post(`${getPrivateURL()}packages/`, values, {withCredentials:true} );
};
export const updatePackage = (package_id, data ) => {
  return axios.put(`${getPrivateURL()}packages/${package_id}`, data, {withCredentials:true});
};
export const removePackage = (package_id ) => {
  return axios.delete(`${getPrivateURL()}packages/${package_id}`, {withCredentials:true});
};
export const deliverPackage = (package_id , deliver_to) => {
  return axios.put(`${getPrivateURL()}packages_delivery/${package_id}?deliver_to=${deliver_to}`, {withCredentials:true});
};
export const disablePackage = (package_id ) => {
  return axios.patch(`${getPrivateURL()}packages/${package_id}`, {withCredentials:true});
};

const PackagesService = {
  getPackages,
  getMyPackages,
  getPackage,
  insertPackage,
  updatePackage,
  removePackage,
  deliverPackage,
  disablePackage,
};
export default PackagesService;
