import CustomerCell from "../../layouts/ecommerce/orders/order-list/components/CustomerCell";


export const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const userDashboardColumns = [
    { Header: "Name", accessor: "name", width: "20%" },
    { Header: "Amenity", accessor: "amenity", width: "25%" },
    { Header: "Client", accessor: "client" },
    { Header: "Book Date", accessor: "bookDate" },
    { Header: "Status", accessor: "status", width: "7%" },
    { Header: "Actions", accessor: "action", width: "7%" },
  ]

export const userColumns = [
    { Header: "Username", accessor: "username",  width: "20%" },
    { Header: "Name", accessor: "name",  width: "20%" },
    { Header: "Unit", accessor: "unit",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "PostalCode", accessor: "postal_code",  width: "20%" },
    { Header: "Role", accessor: "role",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const bookElevatorColumns = [
    // { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Elevator", accessor: "elevator",  width: "20%" },
    { Header: "Date", accessor: "date",  width: "20%" },
    { Header: "Start", accessor: "start",  width: "20%" },
    { Header: "End", accessor: "end",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const bookPartyRoomColumns = [
    // { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Party Room", accessor: "party_room",  width: "20%" },
    { Header: "Date", accessor: "date",  width: "20%" },
    { Header: "Start", accessor: "start",  width: "20%" },
    // { Header: "End", accessor: "end",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const reserveParkingColumns = [
    { Header: "Parking", accessor: "parking",  width: "20%" },
    { Header: "Level", accessor: "level",  width: "20%" },
    { Header: "Spot", accessor: "spot",  width: "20%" },
    { Header: "Date", accessor: "date",  width: "20%" },
    { Header: "Vehicle", accessor: "vehicle",  width: "20%" },
    { Header: "PlateNumber", accessor: "plate_number",  width: "20%" },
    { Header: "Time", accessor: "start",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const userListColumns = [
    { Header: "User", accessor: "name", width: "10%" },
    { Header: "Email", accessor: "email", width: "20%" },
    // { Header: "Register", accessor: "registerDate"},
    { Header: "Status", accessor: "status" },
    { Header: "IsMain", accessor: "is_main" },
    // { Header: "Role", accessor: "role" },
    // { Header: "Client", accessor: "client" },
    { Header: "Building", accessor: "building" },
    { Header: "Unit", accessor: "unit" },
    // { Header: "Client", accessor: "client" },
    // { Header: "Instance", accessor: "instance" },
    { Header: "Actions", accessor: "actions" },
  ]

export const userInsideColumns = [
    { Header: "Username", accessor: "username",  width: "20%" },
    { Header: "Name", accessor: "name",  width: "20%" },
    { Header: "Role", accessor: "role",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const unitColumns = [
  { Header: "Unit", accessor: "unit",  width: "20%" },
  { Header: "Client", accessor: "client",  width: "20%" },
  { Header: "Building", accessor: "building",  width: "20%" },
  { Header: "Floor", accessor: "floor",  width: "20%" },
  { Header: "Section", accessor: "section",  width: "20%" },
  { Header: "MemberCount", accessor: "members",  width: "20%" },
  { Header: "Actions", accessor: "action", width: "7%" },
];
export const clientColumns = [
    { Header: "Name", accessor: "name",  width: "20%" },
    { Header: "Address", accessor: "address",  width: "20%" },
    { Header: "Country/ Province/City", accessor: "city",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Instance", accessor: "instance",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const elevatorColumns = [
    { Header: "Name", accessor: "name",  width: "20%" },
    { Header: "Client", accessor: "client",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Weight (KG)", accessor: "weight",  width: "20%" },
    { Header: "Height (Inch)", accessor: "height",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const partyRoomColumns = [
    { Header: "Name", accessor: "name",  width: "20%" },
    { Header: "Client", accessor: "client",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Area", accessor: "area",  width: "20%" },
    { Header: "LateHours", accessor: "late_hours",  width: "20%" },
    { Header: "Maximum", accessor: "max_people_number",  width: "20%" },
    { Header: "Deposit", accessor: "deposit",  width: "20%" },
    { Header: "AutoApproval", accessor: "auto_approval",  width: "20%" },
    { Header: "AllowDrinks", accessor: "allow_drinks",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const bookingPartyRoomColumns = [
    { Header: "User", accessor: "booking_name",  width: "20%" },
    { Header: "Email", accessor: "booking_email",  width: "20%" },
    { Header: "Client", accessor: "client",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Party Room", accessor: "party_room",  width: "20%" },
    { Header: "Book Time", accessor: "book_time",  width: "20%" },
    { Header: "Image", accessor: "image_status",  width: "20%" },
    { Header: "Payment", accessor: "payment_status",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const eventTypeColumns = [
    { Header: "EventType", accessor: "type",  width: "20%" },
    { Header: "EventCount", accessor: "count",  width: "20%" },
    { Header: "Allow For Members", accessor: "allow_for_members",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const eventColumns = [
    { Header: "Name", accessor: "name",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Creator", accessor: "creator",  width: "20%" },
    { Header: "EventType", accessor: "type",  width: "20%" },
    { Header: "Date", accessor: "date",  width: "20%" },
    { Header: "Time", accessor: "time",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const myEventColumns = [
    { Header: "Name", accessor: "name",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Creator", accessor: "creator",  width: "20%" },
    { Header: "EventType", accessor: "type",  width: "20%" },
    { Header: "Date", accessor: "date",  width: "20%" },
    { Header: "Time", accessor: "time",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
];
export const myOrderColumns = [
    { Header: "OrderId", accessor: "order_id",  width: "20%" },
    { Header: "Transaction", accessor: "transaction",  width: "20%" },
    { Header: "Product", accessor: "product",  width: "20%" },
    { Header: "Count", accessor: "count",  width: "20%" },
    { Header: "Price", accessor: "total_price",  width: "20%" },
    { Header: "Order", accessor: "order_time",  width: "20%" },
    { Header: "Receive", accessor: "receive_time",  width: "20%" },
    { Header: "Time", accessor: "time",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Action", accessor: "action",  width: "20%" },
];
export const contractorColumns = [
    { Header: "Name", accessor: "name",  width: "20%" },
    { Header: "Phone", accessor: "phone",  width: "20%" },
    { Header: "Email", accessor: "email",  width: "20%" },
    { Header: "Company", accessor: "company",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const keyColumns = [
    { Header: "KeyName", accessor: "keyName",  width: "20%" },
    { Header: "Client", accessor: "client",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    // { Header: "Unit", accessor: "unit",  width: "20%" },
    { Header: "KeyType", accessor: "type",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const keyTypeColumns = [
    { Header: "KeyType", accessor: "keyType",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const spotTypeColumns = [
    { Header: "KeyType", accessor: "spotType",  width: "20%" },
    // { Header: "Icon", accessor: "spotTypeIcon",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const borrowKeyColumns = [
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Contractor", accessor: "trader",  width: "20%" },
    { Header: "Key", accessor: "key",  width: "20%" },
    { Header: "BorrowTime", accessor: "borrowTime",  width: "20%" },
    { Header: "BackTime", accessor: "backTime",  width: "20%" },
    { Header: "RealBackTime", accessor: "realBackTime",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const parcelColumns = [
    { Header: "Unit", accessor: "unit",  width: "20%" },
    { Header: "Code", accessor: "code",  width: "20%" },
    { Header: "Client", accessor: "client",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "ReceiveDate", accessor: "receive_date",  width: "20%" },
    { Header: "DeliverDate", accessor: "deliver_date",  width: "20%" },
    { Header: "DeliverTo", accessor: "deliver_to",  width: "20%" },
    { Header: "Description", accessor: "description",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const parkingColumns = [
    { Header: "Client", accessor: "client",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Parking", accessor: "parking",  width: "20%" },
    { Header: "Capacity", accessor: "capacity",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const parkingLevelColumns = [
    { Header: "Client", accessor: "client",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Parking", accessor: "parking",  width: "20%" },
    { Header: "Level", accessor: "level",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const parkingLevelSpotColumns = [
    { Header: "Client", accessor: "client",  width: "20%" },
    { Header: "Building", accessor: "building",  width: "20%" },
    { Header: "Parking", accessor: "parking",  width: "20%" },
    { Header: "Level", accessor: "level",  width: "20%" },
    { Header: "Spot", accessor: "spot",  width: "20%" },
    { Header: "Type", accessor: "type",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const holidayColumns = [
    { Header: "Holiday Name", accessor: "name",  width: "20%" },
    { Header: "Date", accessor: "holiday",  width: "20%" },
    { Header: "WeekDay", accessor: "weekday",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const closureColumns = [
    { Header: "Date", accessor: "date",  width: "20%" },
    { Header: "Start", accessor: "start",  width: "20%" },
    { Header: "End", accessor: "end",  width: "20%" },
    { Header: "Amenity", accessor: "amenity",  width: "20%" },
    { Header: "Status", accessor: "status",  width: "20%" },
    { Header: "Actions", accessor: "action", width: "7%" },
];
export const instanceColumns = [
    { Header: "Logo", accessor: "logo", width: "12%" },
    { Header: "Name", accessor: "name", width: "25%" },
    { Header: "Host", accessor: "domain", width: "12%" },
    { Header: "Status", accessor: "status", width: "25%" },
    { Header: "ClientCount", accessor: "client", width: "25%" },
    { Header: "MemberCount", accessor: "member", width: "25%" },
    { Header: "Actions", accessor: "action", width: "7%"  },
];
export const buildingColumns = [
    { Header: "Name", accessor: "name", width: "25%" },
    { Header: "Status", accessor: "status", width: "25%" },
    { Header: "MemberCount", accessor: "member", width: "25%" },
    { Header: "PostalCode", accessor: "postal_code", width: "25%" },
    { Header: "PhoneNumber", accessor: "phone_number", width: "25%" },
    { Header: "Client", accessor: "client", width: "25%" },
    { Header: "Actions", accessor: "action", width: "7%"  },
];