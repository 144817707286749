import {useState} from "react";
import MDSnackbar from "../MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../MDBox";
import {modalStyle, modalStyleUser} from "./ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../../layouts/pages/account/components/FormField";
import MDButton from "../MDButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Icon from "@mui/material/Icon";
import DataTable from "../../examples/Tables/DataTable";
import loadDataTable from "../../layouts/pages/instances/data";
import MDInput from "../MDInput";
import Switch from "@mui/material/Switch";


import LoadingOverlay from 'react-loading-overlay';
export function SnackShower({values, setValues, ...rest}) {
    if(values.type === "error"){
        return <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={values.error_message}
              dateTime=""
              open={values.show}
              onClose={()=>setValues({...values, show:false})}
              close={()=>setValues({...values, show:false})} />
    }
    return (
        <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={values.show}
              onClose={()=>setValues({...values, show:false})}
              close={()=>setValues({...values, show:false})}  />
    )
}



export function DialogShower({values, setValues, ...rest}) {
    let defaultTitle = <div><Icon color={"warning"} >warning</Icon>{" Delete confirmation"}</div>
    return (<Dialog
            open={values.show}
            keepMounted
            onClose={()=>setValues({...values, show:false})}
            aria-describedby="alert-dialog-slide-description" >
            <DialogTitle>
                {values.title !== undefined ? values.title : defaultTitle}
            </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              {values.body !== undefined ? values.body : "Are you sure to " + values.title + " this item ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={()=>setValues({...values, show:false})} >Disagree</MDButton>
          <MDButton onClick={values.onClickDone} id={values.id} color={"info"} size={"large"} >Agree</MDButton>
        </DialogActions>
      </Dialog>)
}

export function TableShower({values, setValues, ...rest}) {
    // console.log("TABALE");
    return (<div>
            <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                {values.showButton ?
                    <MDButton variant="gradient" color="warning" size="large" onClick={values.buttonClick} >
                        {values.buttonText}
                    </MDButton> : ""}

            </MDBox>
            <Card>
              <DataTable showTotalEntries={true}
                         table={values.data(values.progressChanger, values.onClickRow)}
                         canSearch
                         isSorted={true}
                         pagination={{"variant":"contained", "color":"dark"}}  />
            </Card>
    </div>)
}
export function ModalContent({values, setValues, ...rest}) {
    return (<div>
        Hello
    </div>)
}
