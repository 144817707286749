import MDBadge from "../../../../components/MDBadge";
import {Icon} from "@mui/material";


const dataTableDataAdmin = {
  columns: [
    { Header: "Name", accessor: "name", width: "20%" },
    { Header: "Username", accessor: "username", width: "25%" },
    { Header: "At", accessor: "at", width: "25%" },
    { Header: "Amenity", accessor: "amenity", width: "25%" },
    { Header: "Client", accessor: "client" },
    { Header: "Book Date", accessor: "bookDate" },
    { Header: "Status", accessor: "status", width: "7%" },
    { Header: "Ticket", accessor: "downloadData", width: "7%" },
  ],
  rows: [
    {
      name: "Hanny Baniard",
      amenity: "Beautiful Castle",
      username: "Mr.  ",
      at : "23 hours ago",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "4/11/2021",
      client: "Wolf Wallstreet",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
    {
      name: "Jessie Jackson",
      amenity: "Beautiful Castle",
      username: "Mr.Johan",
      at : "24 hours ago",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "4/11/2021",
      client: "Wolf Wallstreet",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
    {
      name: "Emma Watson",
      amenity: "Beautiful Castle",
      username: "Mr.Johan",
      at : "12 hours ago",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "4/11/2021",
      client: "Wolf Wallstreet",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
  ],
};

export default dataTableDataAdmin;
