import MDBadge from "../../../../components/MDBadge";
import {Icon} from "@mui/material";
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import CustomerCell from "../../../ecommerce/orders/order-list/components/CustomerCell";
import team1 from "../../../../assets/images/ads/ad.png";
import team2 from "../../../../assets/images/ads/ad2.png";
import {Image} from "react-bootstrap";
import GHIconButton from "../../../../components/GHIconButton";


const dataTableDataUsers = {
  columns: [

    { Header: "Ads", accessor: "ads", width: "45%" },
    { Header: "Type", accessor: "type", width: "25%" },
    { Header: "Link", accessor: "link", width: "25%" },
    { Header: "Actions", accessor: "actions", width: "7%" },
  ],
  rows: [
    {
      ads: <Image src={team1} height={70} width={500} thumbnail />,
      type: "Up-Banner",
      role: "Member",
      link: "https://google.com/",
      actions: (
        <div>
          <GHIconButton title={"Delete"} color={"error"} icon={"delete"} size={"medium"} />
        </div>
      ),
    },
    {
      ads: <Image src={team2}   height={70} width={500}  thumbnail />,
      type: "Down-Banner",
      role: "Member",
      link: "https://google.com/",
      actions: (
        <div>
          <GHIconButton title={"Delete"} color={"error"} icon={"delete"} size={"medium"} onClick />
        </div>
      ),
    },
  ],
};

export default dataTableDataUsers;
