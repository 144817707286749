import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Autocomplete from "@mui/material/Autocomplete";
import {Link} from "react-router-dom";
import MDButton from "../../../../components/MDButton";
import DataTable from "../../../../examples/Tables/DataTable";
import MDSnackbar from "../../../../components/MDSnackbar";
import FormField from "../../account/components/FormField";
import ClientsService from "../../../../services/clients.service";
import MDBadge from "../../../../components/MDBadge";
import GHIconButton from "../../../../components/GHIconButton";
import TextField from "@mui/material/TextField";
import AuthService, {getCurrentInstanceId, getUserRole} from "../../../../services/auth.service";
import {clientColumns, elevatorColumns, unitColumns, userColumns} from "../../../../components/Utils/HeaderColumns";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {modalStyleClients, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import MDInput from "../../../../components/MDInput";
import ElevatorsService, {updateElevator} from "../../../../services/elevators.service";

function Elevators() {

    const defaultInputValues = {  elevatorName: "", weight: ""};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        elevatorName: Yup.string()
            .min(6, 'Elevator Name must be at least 6 characters')
            .required('Elevator Name is required'),
        weight: Yup.number().required('Elevator Weight is required'),
        height: Yup.number().required('Elevator Height is required'),
    });

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [instanceList, setInstanceList] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadClients();
        loadElevator();
    }, [setClients, setBuildings]);

    const loadClients = () => {
        ClientsService.getClients()
        .then((res) => {
            if(res.data !== null && res.data !== undefined){
                setClients(res.data);
                if (res.data.length > 0){
                    loadBuilding(res.data[0].id);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadBuilding = (client_id) => {
        ClientsService.getClientsBuilding(client_id)
        .then((res) => {
            if(res.data !== null && res.data !== undefined){
                setBuildings(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }



    const [elevatorTableData, setElevatorTableData] = useState({"columns" : elevatorColumns, "rows":[]});
    const loadElevator = () => {
        setElevatorTableData({"columns" : elevatorColumns, "rows":[]})
        ElevatorsService.getElevators()
        .then((res) => {
            console.log(res.data);
            setElevatorTableData( {
                "columns": elevatorColumns,
                "rows": res.data.map(function(element) {
                        return {
                              id: element.id,
                              name: element.name,
                              client: element.client,
                              building: element.building,
                              height: element.height + " ″",
                              weight: element.weight +  " kg" ,
                              action: (
                                  <div>
                                      <GHIconButton onClick={handleClickItem} id={element.id} action={"edit"}
                                                    rowName={element.client_name} title={"Edit"} color={"warning"}
                                                    icon={"edit"} size={"medium"} />
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"delete"}
                                                    rowName={element.client_name} title={"Remove Client"} color={"error"}
                                                    icon={"delete"} size={"medium"} />
                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);

    const [editMode, setEditMode] = useState(false);

    const loadSingleElevator = (elevator_id) =>{
        // alert(clickedItemId)
        if (elevator_id > 0){
             ElevatorsService.getElevator(elevator_id)
            .then((result)=>{
                loadBuilding(result.data.client.id)
                setValues({
                    id: elevator_id,
                    elevatorName: result.data.name,
                    height: result.data.height,
                    weight: result.data.weight,
                    client: {id:result.data.client.id, client_name:result.data.client.client_name},
                    building: {id:result.data.building.id, name:result.data.building.building_name},
                });
                setOpenInsertModel(true);
            }).catch((reason) =>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
        }

    };

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setEditMode(false);
                setOpenInsertModel(true);
                break;
            case "edit":
                setEditMode(true);
                loadSingleElevator(event.target.id);
                break;
            case "delete" :
                handleOpenDialog(model);
                break;
            case "change_status":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
            case "user":
                setOpenUserModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            ElevatorsService.removeElevator(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }
    }


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "change_status":
                handleOpen(elem,"change_status");
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }
    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){
            let dataToSend = {
                "name": values.elevatorName,
                "height": values.height,
                "weight": values.weight,
                "client_id": values.client.id.toString(),
                "building_id": values.building.id,
            }
            if(editMode){
                ElevatorsService.updateElevator(values.id, dataToSend)
                    .then((response) =>{
                        console.log(response);
                        openInfoSB();
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                        setErrorMessage(err.response.data.status.toString());
                        openErrorSB();
                    });
            }else{
                ElevatorsService.insertElevator(dataToSend)
                    .then((response) =>{
                        console.log(response);
                        openInfoSB();
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                        setErrorMessage(err.response.data.status.toString());
                        openErrorSB();
                    });
            }

        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );

    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleClients}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Elevator": "Insert New Elevator"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={ clients}
                          value={values.client}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.client_name ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, client: value });
                            loadBuilding(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.client}
                                        helperText={errors.client?.message}
                                        label="Client"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("client")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={buildings}
                          value={values.building}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                              // alert(value);
                            handleChange({ ...values, building: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.building}
                                        helperText={errors.building?.message}
                                        label="Building"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("building")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.elevatorName}
                          helperText={errors.elevatorName?.message}
                          value={values.elevatorName}
                          {...register("elevatorName")}
                          onChange={(event) => {
                            handleChange({ ...values, elevatorName: event.target.value })
                          }}
                        label="Elevator Name"
                        placeholder="Right Building Elevator"  />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                          fullWidth
                          required
                          type={"number"}
                          min={0}
                          error={!!errors.weight}
                          helperText={errors.weight?.message}
                          value={values.weight}
                          {...register("weight")}
                          onChange={(event) => {
                            handleChange({ ...values, weight: event.target.value })
                          }}
                          label="Weight (KG)"
                          placeholder="4000"  />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                          fullWidth
                          required
                          type={"number"}
                          min={0}
                          error={!!errors.height}
                          helperText={errors.height?.message}
                          value={values.height}
                          {...register("height")}
                          onChange={(event) => {
                            handleChange({ ...values, height: event.target.value })
                          }}
                          label="Height (Inch)"
                          placeholder="200"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderUserListModal = () => {

        return <Modal open={openUserModel} onClose={(e)=>{handleClose("user")}} color={"warning"} >
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">{selectedClientTitle} Client Member's List</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                 <Grid pb={2}  >
                    <MDButton color={"info"} size={"large"} variant={"gradient"}
                              to={"/users/insert?client_id=" + clickedItemId} component={Link} target={"_blank"}  >
                        <Icon>add</Icon>Add Member To {selectedClientTitle}
                    </MDButton>
                 </Grid>
                <Grid container spacing={3}>

                    <DataTable table={userTableData} showTotalEntries canSearch />
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderElevatorList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderUserListModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                  <MDButton variant="gradient" color="warning" size="large"
                            disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>
                    new elevator
                  </MDButton>
                  <MDBox display={isAdmin ? "flex": "none"} >
                      <Autocomplete
                          sx={{ width: 300 }}
                          value={selectedInstance}
                          options={instanceList}
                          getOptionLabel={(option) => option.name ?? "--loading"}
                          onChange={(event,value) => {
                            setSelectedInstance(value);
                            loadClients(value.id);
                          }}
                          renderInput={(params) =>
                              <TextField  {...params} label={"Instance"} />
                          }
                        />
                  </MDBox>
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={elevatorTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderElevatorList()}
          <Footer />
        </DashboardLayout>
    );


}

export default Elevators;
