import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {getUserRole} from "../../../../services/auth.service";
import ClientsService from "../../../../services/clients.service";
import {
    contractorColumns,
    elevatorColumns,
    keyColumns,
    parcelColumns,
    userColumns
} from "../../../../components/Utils/HeaderColumns";
import GHIconButton from "../../../../components/GHIconButton";
import MDBadge from "../../../../components/MDBadge";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../../components/MDBox";
import {modalStyleAds, modalStyleClients, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import TextField from "@mui/material/TextField";
import {Link} from "react-router-dom";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import PackagesService from "../../../../services/packages.service";
import KeysService from "../../../../services/keys.service";
import ElevatorsService from "../../../../services/elevators.service";

function Traders() {

    const defaultInputValues = {  email: "", phone: "", company: "", first_name: "", last_name: ""};
    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Email is not Valid") .required('Email is required'),
        phone: Yup.string() .required('Phone is required'),
        company: Yup.string() .required('Company is required'),
        first_name: Yup.string() .required('First Name is required'),
        last_name: Yup.string(),
    });
    console.log(values);

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [units, setUnits] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        // loadClients();
        loadTrader();
    }, [setClients, setBuildings, setUnits]);

    // const loadClients = (instance_id) => {
    //     ClientsService.getClients(instance_id)
    //     .then((res) => {
    //         console.log(res.data);
    //         setClients(res.data);
    //         if (res.data.length > 0){
    //             loadBuilding(res.data[0].id);
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }
    //
    // const loadBuilding = (client_id) => {
    //     ClientsService.getClientsBuilding(client_id)
    //     .then((res) => {
    //         console.log(res.data);
    //         setBuildings(res.data);
    //         if(res.data[0].length > 0){
    //             loadUnit(res.data[0].id);
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }
    //
    // const loadUnit = (building_id) => {
    //     ClientsService.getBuildingUnits(building_id)
    //     .then((res) => {
    //         console.log(res.data);
    //         setUnits(res.data);
    //         // loadProvince(res.data[0]);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }



    const [traderTableData, setTreaderTableData] =
                        useState({"columns" : contractorColumns, "rows":[]});

    const loadTrader = () => {
        setTreaderTableData({"columns" : contractorColumns, "rows":[]})
        KeysService.getTraders()
        .then((res) => {
            console.log(res.data);
            setTreaderTableData( {
                "columns": contractorColumns,
                "rows": res.data.map(function(element) {
                        return {
                              id: element.id,
                              name: element.name,
                              phone: element.phone,
                              email: element.email,
                              company: element.company,
                              unit: element.unit,
                              client: element.client,
                              building: element.building,
                              status: <MDBadge badgeContent={"active"} size="sm" container
                                       color={element.status === "active" ? "success" : "success"} />,
                              action: (
                                  <div>
                                      <GHIconButton onClick={handleClickItem} id={element.id} action={"edit"}
                                                    rowName={element.client_name} title={"Edit"} color={"warning"}
                                                    icon={"edit"} size={"medium"} />
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"delete"}
                                                    rowName={element.client_name} title={"Remove"} color={"error"}
                                                    icon={"delete"} size={"medium"} />

                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(true);
                setEditMode(false)
                break;
            case "delete" :
                handleOpenDialog(model);
                break;
            case "change_status":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            KeysService.removeKey(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }if (dialogAction === "change_status") {
            KeysService.disableKey(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }
    }
    const loadSingleContractor = (trader_id) =>{
        // alert(clickedItemId)
        if (trader_id > 0){
             KeysService.getTrader(trader_id)
            .then((result)=>{
                // loadBuilding(result.data.client.id)
                setValues({
                    id: trader_id,
                    first_name: result.data.first_name,
                    last_name: result.data.last_name,
                    email: result.data.email,
                    phone: result.data.phone,
                    company: result.data.company,
                });
                setOpenInsertModel(true);
            }).catch((reason) =>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
        }

    };


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                // handleOpen(elem,"edit");
                loadSingleContractor(rowId)
                setEditMode(true)
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){
            let dataToSend = {
                "email": values.email.toString(),
                "phone": values.phone.toString(),
                "company": values.company.toString(),
                "first_name": values.first_name.toString(),
                "last_name": values.last_name.toString()
            }
            console.log(dataToSend);
            if (editMode){

            KeysService.updateTrader(values.id, dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
            }else{

            KeysService.insertTrader(dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
            }
            // let unitId = 0;
            // // console.log(values)
            // if (values.unit !== undefined && values.unit !== null ){
            //     unitId = values.unit.id;
            // }

        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [editMode, setEditMode] = useState(false);
    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleAds}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Contractor": "Insert New Contractor"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.first_name}
                          helperText={errors.first_name?.message}
                          value={values.first_name}
                          {...register("first_name")}
                          onChange={(event) => {
                            handleChange({ ...values, first_name: event.target.value })
                          }}
                        label="First Name"
                        placeholder="John"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.last_name}
                          helperText={errors.last_name?.message}
                          value={values.last_name}
                          {...register("last_name")}
                          onChange={(event) => {
                            handleChange({ ...values, last_name: event.target.value })
                          }}
                        label="Last Name"
                        placeholder="Dow"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.phone}
                          helperText={errors.phone?.message}
                          value={values.phone}
                          {...register("phone")}
                          onChange={(event) => {
                            handleChange({ ...values, phone: event.target.value })
                          }}
                        label="Phone"
                        placeholder="+1(123) 3224"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                          fullWidth
                          required
                          type={"email"}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                          value={values.email}
                          {...register("email")}
                          onChange={(event) => {
                            handleChange({ ...values, email: event.target.value })
                          }}
                        label="Email"
                        placeholder="info@company.com"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.company}
                          helperText={errors.company?.message}
                          value={values.company}
                          {...register("company")}
                          onChange={(event) => {
                            handleChange({ ...values, company: event.target.value })
                          }}
                        label="Company"
                        placeholder="Company"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };


    const renderTraderList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space" alignItems="flex-start" mb={2}>
                  <MDButton variant="gradient" color="warning" size="large"
                            disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>
                    new Contractor
                  </MDButton>
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={traderTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderTraderList()}
          <Footer />
        </DashboardLayout>
    );

}

export default Traders;