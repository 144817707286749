import {useState} from "react";
import MDSnackbar from "../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../account/components/FormField";
import MDButton from "../../../components/MDButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import DataTable from "../../../examples/Tables/DataTable";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import loadDataTable from "./data";
import {useEffect} from "react";
import CustomService from "../../../services/custome.service";
import validateForm from "../../../utils";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Cities() {

    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);

    const [country, setCountry] = useState({});
    const [province, setProvince] = useState({});
    const [city, setCity] = useState("");
    const [formData, setFormData] = useState({
        "city_name": { "validation":{is_required:true, min:3}, "error_status": false, "error_message":"", "value": ""},
        "country": { "validation":{is_required:true}, "error_status": false, "error_message":"", "value": province},
        "province": {"validation":{is_required:true}, "error_status": false, "error_message":"", "value": country},
    })
    const setValue = (source, valueOfElement) => {
        console.log(source + " -> "+ valueOfElement);
        if(source === "city_name") setCity(valueOfElement);
        // if(source === "province") setProvince(valueOfElement);
        // if(source === "country") setCountry(valueOfElement);
    }

    useEffect(() => {
        CustomService.getCountries()
        .then((res) => {
          setCountries(res.data);
          setCountry(res.data[0]);
          loadProvince(res.data[0].id)
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);
    const loadProvince = (country_id) => {
        // console.log("province" + country_id);
        CustomService.getProvinces(country_id)
        .then((res) => {
            console.log(res.data);
            setProvinces(res.data);
            setProvince(res.data[0]);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);
    const [errorMessage, setErrorMessage] = useState(false);

    const [open, setOpen] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);

    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);

    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
      CustomService.removeCity(clickedItemId)
          .then((response) =>{
              console.log(response);
              openInfoSB();
              window.location.reload();
          })
          .catch((err) => {
              console.log(err);
              setErrorMessage(err);
              openErrorSB();
          });
    };
    function handleDelete(elem) {
        handleOpenDialog();
        setClickedItemId(elem.target.id);
    }


    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to delete this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }
    const saveDataClicked = (e) =>{
        e.preventDefault();
        console.log(country.id + ", "+ province.id );
        if(!checkForm()){
            CustomService.insertCity(province.id + "", city )
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });
        }
        handleClose();
    }
    const onChangeInput = (element, source) => {
        let valueOfElement =  "";
        if("target" in element){
            valueOfElement = element.target.value;
        }else if("id" in element) {
            valueOfElement = element.id
        }
        // console.log(valueOfElement)
        let errorObject = validateForm(formData[source]["validation"], source, valueOfElement)
        if(errorObject != null){
            setFormData(prevErrors => ({...prevErrors,
                        [source]: {
                            "validation":prevErrors[source]["validation"],
                            "error_status":errorObject["error"],
                            "error_message":errorObject["errorMessage"],
                            "value": valueOfElement}}))

        }
        setValue(source, valueOfElement);
    }
    const checkForm = () => {
        let is_check = false;
        for (const validationKey in formData) {
            let xData = validateForm(formData[validationKey]["validation"], validationKey, formData[validationKey]["value"])
            // console.log(xData)
            if(xData.error){
                is_check = true;
            }
        }
        return is_check
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={closeInfoSB}
              close={closeInfoSB}
            />
            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={closeErrorSB}
              close={closeErrorSB}
            />
        </div>
    );

    const renderModal = () => {
    return <Modal open={open} onClose={handleClose} color={"warning"} >
      <MDBox sx={style}>
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">Insert City</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                      value={country}
                      clearIcon={false}
                      contentEditable={false}
                      options={countries}
                      onChange={(event,value) => {
                        loadProvince(value.id);
                        onChangeInput(value, "country");
                        setCountry(value);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <FormField {...params} label="Country" InputLabelProps={{ shrink: true }}   />
                      )}
                    />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                      // defaultValue={provinces[0]}
                      clearIcon={false}
                      contentEditable={false}
                      options={provinces}
                      value={province}
                      onChange={(event,value) => {
                          onChangeInput(value, "province")
                          setProvince(value);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <FormField {...params} label="Province" InputLabelProps={{ shrink: true }}   />
                      )}
                    />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormField label="City Name"
                           placeholder="CityName"
                           onChange={e => onChangeInput(e, "city_name")}
                           error={formData["city_name"].error_status}
                           helperText={formData["city_name"].error_message}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MDButton color={"info"} size={"large"} onClick={saveDataClicked} >Save Data</MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
    };


    const renderCitiesList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDButton variant="gradient" color="info" size="large" onClick={handleOpen}>
                  new City
                </MDButton>
              </MDBox>
              <Card>
                <DataTable showTotalEntries={true} table={loadDataTable(handleDelete)} entriesPerPage={false} canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderCitiesList()}
          <Footer />
        </DashboardLayout>
    );


}

export default Cities;