import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;


const getAllProvinces = () =>
    axios.get(`${getPrivateURL()}provinces/`);
const getProvinces = (country_id) =>
    axios.get(`${getPrivateURL()}provinces/${country_id.toString()}`);
const insertProvince = (country_id, province, abbreviation) =>
    axios.post(`${getPrivateURL()}provinces/`, {country_id, province, abbreviation});
const removeProvince = (province_id) =>
    axios.delete(`${getPrivateURL()}provinces/${province_id.toString()}`);
const getAllCities = () =>
    axios.get(`${getPrivateURL()}cities/`);

const getCities = (province_id) =>
    axios.get(`${getPrivateURL()}cities/${province_id.toString()}`);
const insertCity = (province_id, city) =>
    axios.post(`${getPrivateURL()}cities/`, {province_id, city});
const removeCity = (city_id) =>
    axios.delete(`${getPrivateURL()}cities/${city_id.toString()}`);

const getCountries = () =>
    axios.get(`${getPrivateURL()}countries/`);
const insertCountry = (name, code, short_name) =>
    axios.post(`${getPrivateURL()}countries/`, {name, code, short_name});
const removeCountry = (country_id) =>
    axios.delete(`${getPrivateURL()}countries/${country_id.toString()}`);

const getCurrencies = () =>
    axios.get(`${getPrivateURL()}currencies/`);
const insertCurrency = (name) =>
    axios.post(`${getPrivateURL()}currencies/`, {name});
const removeCurrency = (country_id) =>
    axios.delete(`${getPrivateURL()}currencies/${country_id.toString()}`);


const getHolidays = () =>
    axios.get(`${getPrivateURL()}holidays/`);
const insertHoliday = (holiday_date, holiday_name) =>
    axios.post(`${getPrivateURL()}holidays/`, {holiday_date, holiday_name});
const removeHoliday = (holiday_id) =>
    axios.delete(`${getPrivateURL()}holidays/${holiday_id.toString()}`);




const getClosures = () =>
    axios.get(`${getPrivateURL()}closures/`);
const insertClosure = (data) =>
    axios.post(`${getPrivateURL()}closures/`, data, {withCredentials:true});
const removeClosure = (closure_id) =>
    axios.delete(`${getPrivateURL()}closures/${closure_id.toString()}`);






const CustomService = {
  getCountries,
  insertCountry,
  removeCountry,

  getCurrencies,
  insertCurrency,
  removeCurrency,

  getAllProvinces,
  getCities,
  insertCity,
  removeCity,

  getAllCities,
  getProvinces,
  insertProvince,
  removeProvince,

  getHolidays,
  insertHoliday,
  removeHoliday,

  getClosures,
  insertClosure,
  removeClosure,


};
export default CustomService;
