

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import authService, {confirmResetPassword} from "../../../../services/auth.service";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import MDSnackbar from "../../../../components/MDSnackbar";


function Cover() {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [infoSB, setInfoSB] = useState(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const search = useLocation().search;
  const activationCode = new URLSearchParams(search).get('activation');
  const activationEmail = new URLSearchParams(search).get('email');
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault()
    if(password === undefined || password.length < 6){
      alert("password length must be minimum 6 character or number");
      return;
    }
    if(password !== confirmPassword){
      alert("Password and Confirmation Password is not equal");
      return;
    }
    const data ={
      'activation': activationCode,
      'email': activationEmail,
      'new_password':password,
    }

    authService.confirmResetPassword(data)
        .then((e)=>{
          openInfoSB()
          setTimeout(function () { navigate("/authentication/login"); }, 1000);
        }).catch((reason)=>{
          openErrorSB()
        });
  }

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Amenity System"
      content="Your password changed successfully, Redirecting to login page."
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Amenity System"
      content="Error in change password"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  return (
    <CoverLayout coverHeight="23vh" image={bgImage}>

        {renderInfoSB}
        {renderErrorSB}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="success"
          mx={2} mt={-3} py={2}  mb={1} textAlign="center" >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Change Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            After change password you can't login with old password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput type="password" label="Password" autoComplete="new-password"
                       variant="standard" fullWidth value={password} onChange={(e)=>{
                setPassword(e.target.value);
              }} />
            </MDBox>
            <MDBox mb={4}>
              <MDInput type="password" label="Confirmation Password" autoComplete="new-password"
                       variant="standard" fullWidth value={confirmPassword} onChange={(e)=>{
                          setConfirmPassword(e.target.value);
                       }} />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="secondary" fullWidth onClick={handleClick}>
                Change Password
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
