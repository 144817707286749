import {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {getUserRole} from "../../../../services/auth.service";
import ClientsService from "../../../../services/clients.service";
import {parkingColumns, parkingLevelColumns, parkingLevelSpotColumns} from "../../../../components/Utils/HeaderColumns";
import ParkingService, {getBuildingParking, getParkingByBuilding} from "../../../../services/parking.service";
import MDBadge from "../../../../components/MDBadge";
import GHIconButton from "../../../../components/GHIconButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../../components/MDBox";
import {modalStyleClients, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import TextField from "@mui/material/TextField";
import {Link} from "react-router-dom";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";

import { saveAs } from 'file-saver';
import userService from "../../../../services/user.service";
import parkingService from "../../../../services/parking.service";
function ParkingLevelSpot() {

    const defaultInputValues = {  number: "", type:"Car"};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        number: Yup.string().required('Spot Number is required'),
        type: Yup.string().required('Spot Type is required'),
    });

    const { register, handleSubmit, formState: { errors  } } = useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [units, setUnits] = useState([]);
    const [level, setLevel] = useState([]);
    const [parking, setParking] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadParking();
        loadParkingLevel();
    }, [setClients, setBuildings, setUnits]);

    const loadClients = (instance_id) => {
        // ClientsService.getClients(instance_id)
        // .then((res) => {
        //     if(res.data !== null && res.data !== undefined){
        //         console.log(res.data);
        //         setClients(res.data);
        //         if (res.data.length > 0){
        //             loadBuilding(res.data[0].id);
        //         }
        //     }
        // })
        // .catch((error) => {
        //     console.log(error);
        // });
    }

    const loadBuilding = (client_id) => {
        // ClientsService.getClientsBuilding(client_id)
        // .then((res) => {
        //     if(res.data !== null && res.data !== undefined){
        //         console.log(res.data);
        //         setBuildings(res.data);
        //         if (res.data.length > 0){
        //             loadParking(res.data[0].id);
        //         }
        //     }
        // })
        // .catch((error) => {
        //     console.log(error);
        // });
    }

    const loadParking = (building_id) => {
        ParkingService.getBuildingParking(building_id)
            .then((res) => {
                if(res.data !== null && res.data !== undefined){
                    console.log(res.data);
                    setParking(res.data);
                    if(res.data.length > 0){
                        loadLevel(res.data[0].id);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const loadLevel = (parking_id) => {
        ParkingService.getParkingLevel(parking_id)
        .then((res) => {

            if(res.data !== null && res.data !== undefined) {
                console.log(res.data);
                setLevel(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }


    const [selectedLevel, setSelectedLevel] = useState(null)
    const [parkingTableData, setParkingTableData] = useState({"columns" : parkingLevelSpotColumns, "rows":[]});

    const loadParkingLevel = () => {
        setParkingTableData({"columns" : parkingLevelSpotColumns, "rows":[]})
        let level = "";
        if (selectedLevel !== null){
            level = selectedLevel.id;
        }
        ParkingService.getParkingSpot(level)
        .then((res) => {
            console.log(res.data);
            setParkingTableData( {
                "columns": parkingLevelSpotColumns,
                "rows": res.data.map(function(element) {
                        return {
                              parking: element.parking_name,
                              level: element.level,
                              client: element.client,
                              building: element.building,
                              spot: element.number,
                              type: <Icon fontSize={"medium"}>{element.parking_type === "wan" ? "airport_shuttle" :  (element.parking_type === "car" ? "directions_car" : "motorcycle") }</Icon>,
                              status: <MDBadge badgeContent={element.status} size="sm" container color={element.status === "active" ? "success" : "dark"} />,
                              action: (
                                  <div>
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"delete"}
                                                    rowName={element.client_name} title={"Remove"} color={"error"}
                                                    icon={"delete"} size={"medium"} />
                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(true);
                break;
            case "insert_bulk":
                break;
            case "delete" :
                handleOpenDialog(model);
                break;
            case "change_status":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            ParkingService.removePackageSpot(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });

        }if (dialogAction === "change_status") {
            ParkingService.disableParking(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });

        }
    }


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "change_status":
                handleOpen(elem,"change_status");
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){
            // console.log(values)
            let dataToSend = {
                "type": values.type.toString(),
                "number": values.number.toString(),
                "parking": values.parking.id.toString(),
                "level": values.level.id.toString(),
                // "client": values.client.id.toString(),
                // "building": values.building.id.toString(),
            }
            console.log(dataToSend);

            ParkingService.insertParkingSpot(values.level.id, dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [editMode, setEditMode] = useState(false);
    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleClients}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Parking Spot": "Insert New Parking Spot"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} style={{"display":"none"}}>
                    <Autocomplete
                          required
                          options={clients}
                          value={values.client}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.client_name ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, client: value });
                            loadBuilding(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.client}
                                        helperText={errors.client?.message}
                                        label="Client"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("client")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={4} style={{"display":"none"}}>
                    <Autocomplete
                          required
                          options={buildings}
                          value={values.building}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, building: value });

                            loadParking(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.building}
                                        helperText={errors.building?.message}
                                        label="Building"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("building")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                          required
                          options={parking}
                          value={values.parking}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, parking: value });
                            // alert(value.id)
                            loadLevel(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.parking}
                                        helperText={errors.parking?.message}
                                        label="Parking"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("parking")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                          required
                          options={level}
                          value={values.level}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.level ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, level: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.level}
                                        helperText={errors.level?.message}
                                        label="Parking Level"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("level")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={4}>
                        <Autocomplete
                          required
                          options={["Car", "Wan", "Motorcycle"]}
                          value={values.type}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, type: value });
                          }}
                         renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.type}
                                        helperText={errors.type?.message}
                                        label="Spot Type"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("type")} />
                          )}/>
                    </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.number}
                          helperText={errors.number?.message}
                          value={values.number}
                          {...register("levelName")}
                          onChange={(event) => {
                            handleChange({ ...values, number: event.target.value })
                          }}
                        label="Spot Number"
                        placeholder="32-B"  />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };
    // const [currentFile, setCurrentFile] = useState(null);
    const onFileChange = event => {

        let currentFile = event.target.files[0];
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append( "myFile", currentFile, currentFile.name );


        // parkingService.createBulk(formData)
        //     .then((val)=>{
        //         console.log(val);
        //         setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
        //         window.location.reload(true);
        //     }).catch((e)=>{
        //         console.log(e);
        //         setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: e.toString()})
        //     })
      // Request made to the backend api
      // Send formData object
      // post("api/uploadfile", formData);
    };
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [buttonIcon, setButtonIcon] = useState("");

    const onFileUpload = () => {

    };

    const clickElement = (ref) => {
      ref.current.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      );
    };
    const myRef = useRef(null);
    const handleDownloadCsv = (e) => {saveAs(process.env.PUBLIC_URL + "/resource/template_parking.csv", "template.csv");};
    const renderParkingLevelSpotList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space" alignItems="flex-start" mb={2}>
                  <MDButton variant="gradient" color="warning" size="large" disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>
                    new Parking Spot
                  </MDButton>
                  <input type={"file"} accept={".csv"} style={{"display":"none"}} onChange={onFileChange} ref={myRef} />
                  <MDButton variant="gradient" color="secondary" sx={{"marginLeft":"20px"}}  size="large"  onClick={(e) => clickElement(myRef)}>
                    new Parking Spot (From CSV)
                  </MDButton>
                  <a href={"#"} onClick={handleDownloadCsv}>
                      <MDTypography variant={"caption"} fontWeight={"light"}
                                    sx={{"margin":"20px"}} opacity={"0.5"}>Download Template</MDTypography>
                  </a>
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={parkingTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderParkingLevelSpotList()}
          <Footer />
        </DashboardLayout>
    );

}

export default ParkingLevelSpot;