import {useState} from "react";
import MDSnackbar from "../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../account/components/FormField";
import MDButton from "../../../components/MDButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import DataTable from "../../../examples/Tables/DataTable";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import loadDataTable from "./data";
import {useEffect} from "react";
import CustomService from "../../../services/custome.service";
import validateForm from "../../../utils";
import ClientsService from "../../../services/clients.service";
import MDBadge from "../../../components/MDBadge";
import GHIconButton from "../../../components/GHIconButton";
import {Link} from "react-router-dom";
import TextField from "@mui/material/TextField";
import InstancesService from "../../../services/instances.service";
import AuthService, {getCurrentInstanceId, getUserRole} from "../../../services/auth.service";
import {clientColumns, userColumns} from "../../../components/Utils/HeaderColumns";
import {modalStyle, modalStyleClients, modalStyleUser} from "../../../components/Utils/ModalStyle";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import MDInput from "../../../components/MDInput";


function Clients() {

    const defaultInputValues = {  clientName: "", address: ""};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        clientName: Yup.string()
            .min(6, 'Client Name must be at least 6 characters')
            .required('Client Name is required'),
        address: Yup.string()
            .min(8, 'Address must be at least 8 characters')
            .required('Address is required'),
        // country: Yup.array()
        //     .required('Country is required'),
        // province: Yup.string()
        //     .required('Province is required'),
        // city: Yup.object.shape({Yu})
        //     .required('City is required'),
    });

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [instanceList, setInstanceList] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadCountries();
        if (AuthService.getUserRole() === "super_admin"){
            InstancesService.getInstances()
            .then((res) => {

                let defaultInstance = { "id":"", "name":"-- All Instance --" }
                if(res.data === null || res.data === undefined || res.data === ""){
                    res.data = [];
                }
                res.data.unshift(defaultInstance);
                setInstanceList(res.data);
                setSelectedInstance(res.data[0])
                // console.log(res.data);
                loadClients();
            })
            .catch((error) => { console.log(error); });
        }else{
            loadClients();
        }

    }, [setSelectedInstance, setInstanceList]);

    const loadCountries = () => {
        CustomService.getCountries()
        .then((res) => {
            console.log(res.data);
            setCountries(res.data);
            loadProvince(res.data[0]);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const loadProvince = (country) => {
        // console.log(country_id)
        CustomService.getProvinces(country.id)
        .then((res) => {
            // console.log(res.data);
            setProvinces(res.data);
            loadCities(res.data[0], country);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const loadCities = (province, country) => {
        CustomService.getCities(province.id)
        .then((res) => {
            console.log(res.data);
            setCities(res.data);
            handleChange({ ...values, province: province, country: country, city: res.data[0] })

        })
        .catch((error) => {
            console.log(error);
        });
    }



    const [clientTableData, setClientTableData] = useState({"columns" : clientColumns, "rows":[]});
    const loadClients = (instance_id) => {
        // alert(instance_id);
        setClientTableData({"columns" : clientColumns, "rows":[]})
        ClientsService.getClients(instance_id)
        .then((res) => {
            // console.log(res.data);
            setClientTableData( {
                "columns": clientColumns,
                "rows": res.data.map(function(element) {
                        return {
                              id: element.id,
                              name: element.client_name,
                              code: element.client_code,
                              city: (
                                  <div>
                                      <h4>{element.city.province.country.name}<br/></h4>
                                      <h5>{element.city.province.name} / {element.city.city_name}</h5>
                                  </div>
                              ),
                              province: element.city.province.name,
                              country: element.city.province.country.name,
                              status: <MDBadge badgeContent={element.status} size="sm" container  color={element.status === "active" ? "success" : "error"} />,
                              postal: element.postal_code,
                              address: element.address,
                              instance: element.instance.instance_name,
                              // members: 10,
                              action: (
                                  <div>
                                      <GHIconButton onClick={handleClickItem} id={element.id} action={"user_list"} rowName={element.client_name} title={"User List"} color={"info"} icon={"list_alt"} size={"medium"}  />
                                      {/*<GHIconButton onClick={handleClickItem} id={element.id} action={"edit"} rowName={element.client_name} title={"Edit"} color={"warning"} icon={"edit"} size={"medium"} />*/}
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"change_status"}
                                                            rowName={element.client_name}
                                                            title={element.status === "active"? "Disable" : "Active"}
                                                            color={element.status === "active"? "info" : "error"}
                                                            icon={element.status === "active"? "toggle_off" : "toggle_on"} />
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"delete"}  rowName={element.client_name} title={"Remove Client"} color={"error"}  icon={"delete"} size={"medium"} />


                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);


    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(true);
                break;
            case "user":
                // requestUserList(event.target.id);
                setOpenUserModel(true);
                break;
            case "delete" :
                handleOpenDialog(model);
                break;
            case "change_status":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
            case "user":
                setOpenUserModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            ClientsService.removeClient(clickedItemId)
                .then((response) => {
                    // console.log(response);
                    // openInfoSB();
                    loadClients();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        } else if (dialogAction === "change_status") {
            ClientsService.disableClient(clickedItemId)
                .then((response) => {
                    // console.log(response);
                    // openInfoSB();
                    loadClients();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });
        }
    }


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "user_list":
                requestUserList(rowId)
                handleOpen(elem,"user");
                break;
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "change_status":
                handleOpen(elem,"change_status");
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");

    const requestUserList = (clientId) => {
        setUserTableData({"columns" :userColumns, "rows":[]});
        const clickOnRowUser = (e) =>{
            // console.log(e.target);
        }
        ClientsService.getClientMembers(clientId)
            .then((res) => {
                console.log(res.data);
                let data = res.data;
                setUserTableData( {
                    "columns": userColumns,
                    "rows": data.map(function(element) {
                        return {
                              id: element.id,
                              username: element.user.username,
                              name: element.user.first_name + " " + element.user.last_name,
                              unit: element.unit,
                              role: element.role,
                              postal_code: element.postal_code,
                              building: element.building,
                              status: <MDBadge badgeContent={element.status} size="sm" container
                                               color={element.status === "active" ? "success" : "error"} />,
                              action: (
                                  <div>
                                      <GHIconButton size={"medium"} onClick={clickOnRowUser}
                                                    id={element.id} title={"Remove From Client"}
                                                    color={"error"} icon={"delete"}/>
                                  </div>
                              ),
                            }
                    })
                })
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }
    const saveDataClicked = (e) =>{
        e.preventDefault();
        console.log(selectedInstance);
        // alert(currentInstanceId);
        // let instanceId = currentInstanceId;
        // if(isAdmin && selectedInstance.id !== ""){
        //     instanceId = selectedInstance.id.toString();
        // }
        // console.log(country.id + ", "+ province.id );
        if(isValid){
            let dataToSend = {
                "clientName": values.clientName,
                "address": values.address,
                "city": values.city.id.toString(),
                "province": values.province.id.toString(),
                "instanceId":(selectedInstance.id ?? "").toString()
            }
            console.log(dataToSend);

            ClientsService.insertClient(dataToSend)
                .then((response) =>{
                    console.log(response);
                    // openInfoSB();
                    handleClose("insert");
                    loadClients();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    // openErrorSB();
                });
        }
        // handleClose();
    }

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );

    const clickUserItem = (event) => {
      console.log(event);
    }

    console.log(values)

    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleClients}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >Insert New Client</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                          required
                          options={countries}
                          value={values.country}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, country: value });
                            loadProvince(value);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.country}
                                        helperText={errors.country?.message}
                                        label="Country"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("country")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                          required
                          options={provinces}
                          value={values.province}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, province: value });
                            loadCities(value, values.country);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.province}
                                        helperText={errors.province?.message}
                                        label="Province"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("province")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                          required
                          options={cities}
                          value={values.city}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.city_name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, city: value });
                            // loadProvince(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.city}
                                        helperText={errors.city?.message}
                                        label="City"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("city")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.clientName}
                          helperText={errors.clientName?.message}
                          value={values.clientName}
                          {...register("clientName")}
                          onChange={(event) => {
                            handleChange({ ...values, clientName: event.target.value })
                          }}
                        label="Client Name"
                        placeholder="Address Hall"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.address}
                          helperText={errors.address?.message}
                          value={values.address}
                          {...register("address")}
                          onChange={(event) => {
                            handleChange({ ...values, address: event.target.value })
                          }}
                          label="Address"
                          placeholder="4680 Russell Avenue"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderUserListModal = () => {

        return <Modal open={openUserModel} onClose={(e)=>{handleClose("user")}} color={"warning"} >
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">{selectedClientTitle} Client Member's List</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                 <Grid pb={2}  >
                    <MDButton color={"info"} size={"large"} variant={"gradient"}
                              to={"/users?insert=true&client_id=" + clickedItemId} component={Link} target={"_blank"}  >
                        <Icon>add</Icon>Add Member To {selectedClientTitle}
                    </MDButton>
                 </Grid>
                <Grid container spacing={3}>

                    <DataTable table={userTableData} showTotalEntries canSearch />
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderClientsList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderUserListModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                  <MDButton variant="gradient" color="warning" size="large"
                            disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>
                    new client
                  </MDButton>
                  <MDBox display={isAdmin ? "flex": "none"} >
                      <Autocomplete
                          sx={{ width: 300 }}
                          value={selectedInstance}
                          options={instanceList}
                          getOptionLabel={(option) => option.name ?? "--loading"}
                          onChange={(event,value) => {
                            setSelectedInstance(value);
                            loadClients(value.id);
                          }}
                          renderInput={(params) =>
                              <TextField  {...params} label={"Instance"} />
                          }
                        />
                  </MDBox>
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={clientTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderClientsList()}
          <Footer />
        </DashboardLayout>
    );


}

export default Clients;