import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import BookingCard from "../../../../examples/Cards/BookingCard";
import amenitiesService, {getAmenities} from "../../../../services/amenities.service";
import {useEffect, useState} from "react";
import {getUserRole} from "../../../../services/auth.service";
import MDSocialButton from "../../../../components/MDSocialButton";
import {Link} from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDTypography from "../../../../components/MDTypography";


function AmenitiesList({filter}) {
    const [responseData, setResponseData] = useState([]);
    // const [sss, setSSS] = useState(false);
    const doDelete = (e) =>{
        if(confirm("Do you want to delete this amenity ? ")){
            amenitiesService.deleteAmenity(e.target.id)
            .then((value)=>{
                window.location.reload();
            }).catch((res)=>{

            });
        }
    }
    const doEdit = (e) =>{
        if(confirm("Do you want to edit this amenity ? ")){
            window.location = "/amenities/insert?id=" + e.target.id
        }
    }
    const renderAdminButton = () => {
        const currentRole = getUserRole();
        if (currentRole === "staff" || currentRole === "admin" || currentRole === "super_admin") {
        // setSSS(true);
          return (
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mt={3}>
                <MDSocialButton
                  color="tumblr"
                  size="medium"
                  fullWidth
                  component={Link}
                  to="/amenities/insert"
                >
                  <Icon color="inherit" sx={{ m: 0.5 }}>
                    add
                  </Icon>
                  Insert New Amenities
                </MDSocialButton>
              </MDBox>
            </Grid>
          );
        }
        return "";
      };
    useEffect(() => {
          getAmenities(filter)
            .then((res) => {
              console.log(res.data);
              setResponseData(res.data);

            })
            .catch((error) => {
              console.log(error);
            });
        }, [filter]);
    const renderButtons = (item) =>{
        // alert(getUserRole());
        if (getUserRole() === "super_admin"){ //  || getUserRole() === "staff"
            return (<>
                              <MDTypography
                                variant="body1"
                                color="primary"
                                lineHeight={1}
                                onClick={doDelete}
                                sx={{ cursor: "pointer", mx: 3 }}
                              >
                                <Icon color="inherit" id={item.id}>delete</Icon>
                              </MDTypography>
                              <MDTypography
                                  variant="body1"
                                  color="info"
                                  lineHeight={1}
                                onClick={doEdit}

                                  sx={{ cursor: "pointer", mx: 3}}
                              >
                                <Icon color="inherit" id={item.id}>edit</Icon>
                              </MDTypography>
                            </>)
        }else{
            return <></>
        }
    }
    return (
        <Grid container spacing={3} paddingBottom={2} padding={2}>
            {renderAdminButton()}
            {responseData.map((item) => (
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mt={3}>
                     <BookingCard
                       image={ item.image}
                       title={item.name}
                       description={item.description}
                       currency={item.currency}
                       price={item.price}
                       duration={item.period_duration + " Min"}
                       location=""
                       bookId={item.id}
                       action={renderButtons(item)}
                     />
                   </MDBox>
                </Grid>
            ))}
        </Grid>

    );
}


export default AmenitiesList;