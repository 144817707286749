import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getPartyRooms = () => {
  return axios.get(`${getPrivateURL()}party_rooms/`, {withCredentials:true} );
};
export const getMyPartyRoomBooking = () => {
  return axios.get(`${getPrivateURL()}party_room_booking/`, {withCredentials:true} );
};
export const getMyPartyRoomBookingAdmin = () => {
  return axios.get(`${getPrivateURL()}party_room_booking_admin/`, {withCredentials:true} );
};
export const bookPartyRoom = (data) => {
  return axios.post(`${getPrivateURL()}party_room_booking/`, data, {withCredentials:true} );
};
export const cancelBookPartyRoom = (id) => {
  return axios.get(`${getPrivateURL()}cancel_party_room_book/${id}`, {withCredentials:true} );
};
export const confirmBookPartyRoom = (id) => {
  return axios.get(`${getPrivateURL()}confirm_party_room_book/${id}`, {withCredentials:true} );
};
export const getPartyRoom = (id) => {
  return axios.get(`${getPrivateURL()}party_rooms/${id}`, {withCredentials:true} );
};
export const insertPartyRoom = (values) => {
  return axios.post(`${getPrivateURL()}party_rooms/`, values, {withCredentials:true} );
};
export const updatePartyRoom = (id, data ) => {
  return axios.put(`${getPrivateURL()}party_rooms/${id}`, data, {withCredentials:true});
};
export const updateStatus = (id, newStatus ) => {
  return axios.put(`${getPrivateURL()}party_rooms/${id}`, data, {withCredentials:true});
};
export const removePartyRoom = (id ) => {
  return axios.delete(`${getPrivateURL()}party_rooms/${id}`, {withCredentials:true});
};
export const disablePartyRoom = (id ) => {
  return axios.patch(`${getPrivateURL()}party_rooms/${id}`, {withCredentials:true});
};

const PartyRoomService = {
  getPartyRooms,
  getMyPartyRoomBooking,
  getMyPartyRoomBookingAdmin,
  getPartyRoom,
  insertPartyRoom,
  updatePartyRoom,
  removePartyRoom,
  updateStatus,
  disablePartyRoom,
  bookPartyRoom,
  cancelBookPartyRoom,
  confirmBookPartyRoom,
};
export default PartyRoomService;
