import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import {useState, useEffect, useMemo} from "react";
import Modal from '@mui/material/Modal';
import Autocomplete from "@mui/material/Autocomplete";
import {Link} from "react-router-dom";
import MDButton from "../../../../components/MDButton";
import DataTable from "../../../../examples/Tables/DataTable";
import MDSnackbar from "../../../../components/MDSnackbar";
import ClientsService from "../../../../services/clients.service";
import MDBadge from "../../../../components/MDBadge";
import GHIconButton from "../../../../components/GHIconButton";
import TextField from "@mui/material/TextField";
import  { getUserRole} from "../../../../services/auth.service";
import {bookingPartyRoomColumns, partyRoomColumns,} from "../../../../components/Utils/HeaderColumns";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {modalStyleClients, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import MDInput from "../../../../components/MDInput";
import PartyRoomService, {
    cancelBookPartyRoom,
    confirmBookPartyRoom,
    getMyPartyRoomBookingAdmin
} from "../../../../services/partyRooms.service";
import React, {useCallback} from 'react'

import Dropzone, {useDropzone} from 'react-dropzone'

// import Dropzone from "dropzone";
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};
function PartyRoomBookingList() {

    const defaultInputValues = {  partyRoomName: "", area: ""};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        partyRoomName: Yup.string()
            .min(6, 'Party Room Name must be at least 6 characters')
            .required('Party Room Name is required'),
        area: Yup.number().required('Party Room area is required'),
    });

    const { register, handleSubmit, formState: { errors  } } = useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [instanceList, setInstanceList] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadPartyRooms();
    }, [setClients, setBuildings]);



    const [bookingPartyRoomTableData, setBookingPartyRoomTableData] = useState({"columns" : bookingPartyRoomColumns, "rows":[]});
    const loadPartyRooms = () => {
        setBookingPartyRoomTableData({"columns" : bookingPartyRoomColumns, "rows":[]})
        PartyRoomService.getMyPartyRoomBookingAdmin()
        .then((res) => {
            console.log(res.data);
            setBookingPartyRoomTableData( {
                "columns": bookingPartyRoomColumns,
                "rows":  res.data.map(function(element)  {
                        return {
                                id: element.id,
                                booking_name: element.booking_name,
                                booking_email: element.booking_email,
                                building: element.building,
                                client: element.client,
                                party_room: element.party_room,
                                image_status: <MDBadge badgeContent={element.status === "confirmed" ? "Uploaded" : "Not-Uploaded"} color={element.status === "confirmed" ? "success" : "error"} size="sm" container />,
                                payment_status: <MDBadge badgeContent={element.status === "wait_for_payment" ? "Waiting" : "Accepted"} color={element.status === "confirmed" ? "success" : "dark"} size="sm" container />,
                                status: <MDBadge badgeContent={element.status} color={element.status === "confirmed" ? "success" : "secondary"} size="sm" container />,
                                // date: element.date,
                                book_time: (
                                    <div style={{"textAlign":"center"}}>
                                      <MDTypography variant={"caption"} color={"text"}>{element.date}</MDTypography><br/>
                                      <MDTypography variant={"caption"} color={"dark"}>{element.start_time}</MDTypography>
                                    </div>
                                ),
                                start: element.start_time,
                                // end: element.end_time,
                                action: (
                                  <div>
                                      <GHIconButton onClick={handleClickItem} id={"1"} action={"upload_image"}
                                                    rowName={"Gates of Bayview"} title={"Upload Image"} color={"dark"}
                                                    style={{"display": element.status === "confirmed" || element.status === "canceled" ? "none": "flex"}}
                                                    icon={"camera_alt"} size={"medium"} />
                                      <GHIconButton onClick={handleClickItem} id={element.id} action={"accept"}
                                                    rowName={ element.booking_name} title={"Accept"} color={"success"}
                                                    style={{"display": element.status === "canceled" || element.status === "confirmed" ? "none": "flex"}}
                                                    icon={"done"} size={"medium"} />
                                      <GHIconButton onClick={handleClickItem} id={element.id} action={"cancel"}
                                                    rowName={ element.booking_name} title={"Cancel"} color={"error"}
                                                    style={{"display": element.status === "canceled" ? "none": "flex"}}
                                                    icon={"close"} size={"medium"} />
                                  </div>
                                ),
                            }
                    }),
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);
    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const loadSinglePartyRoom = (id) =>{
        // alert(clickedItemId)
        if (id > 0){
             PartyRoomService.getPartyRoom(id)
            .then((result)=>{
                // loadBuilding(result.data.client.id)
                setValues({
                    id: id,
                    partyRoomName: result.data.name,
                    area: result.data.height,
                    // weight: result.data.weight,
                    client: {id:result.data.client.id, client_name:result.data.client.client_name},
                    building: {id:result.data.building.id, name:result.data.building.building_name},
                });
                setOpenInsertModel(true);
            }).catch((reason) =>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
        }

    };

    const [dialogTitle, setDialogTitle] = useState("")
    const [dialogBody, setDialogBody] = useState("")
    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setEditMode(false);
                setOpenInsertModel(true);
                break;
            case "edit":
                setEditMode(true);
                loadSinglePartyRoom(event.target.id);
                break;
            case "cancel" :
                setDialogTitle("Cancel Booking")
                setDialogBody("Are you sure to Cancel this party room booking?")
                handleOpenDialog(model);
                break;
            case "change_status":
                handleOpenDialog(model);
                break;
            case "accept":
                setDialogTitle("Approve Booking")
                setDialogBody("Are you sure to approve this party room booking?")
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
            case "user":
                setOpenUserModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "cancel") {
            PartyRoomService.cancelBookPartyRoom(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }else if(dialogAction === "accept"){

            PartyRoomService.confirmBookPartyRoom(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });
        }
    }

    const [openUploadImage, setOpenUploadImage] = useState(false)
    const toggleImageModal = (e) => setOpenUploadImage(!openUploadImage)
    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "change_status":
                handleOpen(elem,"change_status");
                break;
            case "accept":
                handleOpen(elem,"accept");
                break;
            case "upload_image":
                toggleImageModal()
                break;
            case "cancel":
                handleOpen(elem,"cancel");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");
    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle><Icon color={"success"} >check</Icon>{ dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                  {dialogBody}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }
    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){
            let dataToSend = {
                "client_id": values.client.id.toString(),
                "building_id": values.building.id,
                "name": values.partyRoomName,
                "area": values.area,
                "drinks": values.drinks,
                "late_hours": values.late_hours,
                "max_people_number": values.max_people_number,
            }
            if(editMode){
                PartyRoomService.updatePartyRoom(values.id, dataToSend)
                    .then((response) =>{
                        console.log(response);
                        openInfoSB();
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                        setErrorMessage(err.response.data.status.toString());
                        openErrorSB();
                    });
            }else{
                PartyRoomService.insertPartyRoom(dataToSend)
                    .then((response) =>{
                        console.log(response);
                        openInfoSB();
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                        setErrorMessage(err.response.data.status.toString());
                        openErrorSB();
                    });
            }

        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );

    const renderImageModal = () => {
          const onDrop = useCallback(acceptedFiles => {
            // Do something with the files
          }, [])
        const {getRootProps, getInputProps, acceptedFiles,
            isFocused,
            isDragAccept,
            isDragReject} = useDropzone({
                        onDrop:onDrop,
                        accept: {'image/*': []},
                        maxFiles:3,
                        multiple:true,
                    }
                );
        const focusedStyle = {
          borderColor: '#2196f3'
        };

        const acceptStyle = {
          borderColor: '#00e676'
        };

        const rejectStyle = {
          borderColor: '#ff1744'
        };
        const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);
      const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);

        return <Modal open={openUploadImage} onClose={toggleImageModal} color={"warning"} >
          <MDBox sx={modalStyleClients}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{"Upload Image"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                      <div  {...getRootProps({style})}>
                        <input  {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                      <aside>
                        <h4>Files</h4>
                        <ul>{files}</ul>
                      </aside>



                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton disabled={files.length === 0} color={"info"} size={"large"}
                              onClick={(e)=>window.location.reload()} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleClients}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Party Room": "Insert New Party Room"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                          required
                          options={ clients}
                          value={values.client}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.client_name ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, client: value });
                            loadBuilding(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.client}
                                        helperText={errors.client?.message}
                                        label="Client"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("client")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                          required
                          options={buildings}
                          value={values.building}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                              // alert(value);
                            handleChange({ ...values, building: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.building}
                                        helperText={errors.building?.message}
                                        label="Building"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("building")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.partyRoomName}
                          helperText={errors.partyRoomName?.message}
                          value={values.partyRoomName}
                          {...register("partyRoomName")}
                          onChange={(event) => {
                            handleChange({ ...values, partyRoomName: event.target.value })
                          }}
                        label="Party Room Name"
                        placeholder="Right Building Party Room"  />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                          fullWidth
                          required
                          type={"number"}
                          min={0}
                          error={!!errors.area}
                          helperText={errors.area?.message}
                          value={values.area}
                          {...register("area")}
                          onChange={(event) => {
                            handleChange({ ...values, weight: event.target.value })
                          }}
                          label="Area (Meter)"
                          placeholder="120"  />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                          fullWidth
                          required
                          type={"number"}
                          min={0}
                          error={!!errors.area}
                          helperText={errors.area?.message}
                          value={values.area}
                          {...register("area")}
                          onChange={(event) => {
                            handleChange({ ...values, weight: event.target.value })
                          }}
                          label="Area (Meter)"
                          placeholder="120"  />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                          fullWidth
                          required
                          type={"number"}
                          min={0}
                          error={!!errors.area}
                          helperText={errors.area?.message}
                          value={values.area}
                          {...register("area")}
                          onChange={(event) => {
                            handleChange({ ...values, weight: event.target.value })
                          }}
                          label="Area (Meter)"
                          placeholder="120"  />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                          fullWidth
                          required
                          type={"number"}
                          min={0}
                          error={!!errors.area}
                          helperText={errors.area?.message}
                          value={values.area}
                          {...register("area")}
                          onChange={(event) => {
                            handleChange({ ...values, weight: event.target.value })
                          }}
                          label="Area (Meter)"
                          placeholder="120"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"} onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderUserListModal = () => {

        return <Modal open={openUserModel} onClose={(e)=>{handleClose("user")}} color={"warning"} >
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">{selectedClientTitle} Client Member's List</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                 <Grid pb={2}  >
                    <MDButton color={"info"} size={"large"} variant={"gradient"}
                              to={"/users/insert?client_id=" + clickedItemId} component={Link} target={"_blank"}  >
                        <Icon>add</Icon>Add Member To {selectedClientTitle}
                    </MDButton>
                 </Grid>
                <Grid container spacing={3}>

                    <DataTable table={userTableData} showTotalEntries canSearch />
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderPartyRoomList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderUserListModal()}
              {renderDialog()}
              {renderImageModal()}
              {/*<MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>*/}
                  {/*<MDButton variant="gradient" color="warning" size="large"*/}
                  {/*          disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>*/}
                  {/*  new Party Room*/}
                  {/*</MDButton>*/}
                  {/*<MDBox display={isAdmin ? "flex": "none"} >*/}
                  {/*    <Autocomplete*/}
                  {/*        sx={{ width: 300 }}*/}
                  {/*        value={selectedInstance}*/}
                  {/*        options={instanceList}*/}
                  {/*        getOptionLabel={(option) => option.name ?? "--loading"}*/}
                  {/*        onChange={(event,value) => {*/}
                  {/*          setSelectedInstance(value);*/}
                  {/*          loadClients(value.id);*/}
                  {/*        }}*/}
                  {/*        renderInput={(params) =>*/}
                  {/*            <TextField  {...params} label={"Instance"} />*/}
                  {/*        }*/}
                  {/*      />*/}
                  {/*</MDBox>*/}
              {/*</MDBox>*/}
              <Card>
                    <DataTable
                        showTotalEntries={true}
                        table={bookingPartyRoomTableData}
                        entriesPerPage={false}
                        canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderPartyRoomList()}
          <Footer />
        </DashboardLayout>
    );


}

export default PartyRoomBookingList;
