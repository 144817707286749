
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import SignInCover from "./layouts/authentication/sign-in/cover";
import routes from "./routes";

ReactDOM.render(
  <BrowserRouter >
    <MaterialUIControllerProvider>
        <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
