import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import {useEffect, useMemo, useState} from "react";
import EventCalendar from "../../../../../../examples/Calendar";
import calendarEventsData from "../../../../../applications/calendar/data/calendarEventsData";
import MDBadgeDot from "../../../../../../components/MDBadgeDot";
import MDButton from "../../../../../../components/MDButton";
// import { IMaskInput } from 'react-imask';





function AmenityTimingWeekDay({validStep, uniqueId, oldValues, valueSetter, ...rest}) {

  const changeValue = (type, dayName, value) =>{
    const keyStore = type + "_" + dayName;
    valueSetter({...oldValues, [keyStore]: value});
    if(dayName === "monday"){
      setMonday(true);
    }else if(dayName === "sunday"){
      setSunday(true);
    }else if(dayName === "tuesday"){
      setTuesday(true);
    }else if(dayName === "wednesday"){
      setWednesday(true);
    }else if(dayName === "thursday"){
      setThursday(true);
    }else if(dayName === "friday"){
      setFriday(true);
    }else if(dayName === "saturday"){
      setSaturday(true);
    }else if(dayName === "holiday"){
      setHoliday(true);
    }
    validStep(true)
  }
  const [sunday, setSunday] = useState(false);
  const [start_time_sunday, setStart_time_sunday] = useState(oldValues.start_sunday || "08:00");
  const [end_time_sunday, setEnd_time_sunday] = useState(oldValues.end_sunday ||"16:00");

  const [monday, setMonday] = useState(false);
  const [start_time_monday, setStart_time_monday] = useState(oldValues.start_monday || "08:00");
  const [end_time_monday, setEnd_time_monday] = useState(oldValues.end_monday ||"16:00");

  const [tuesday, setTuesday] = useState(false);
  const [start_time_tuesday, setStart_time_tuesday] = useState(oldValues.start_tuesday || "08:00");
  const [end_time_tuesday, setEnd_time_tuesday] = useState(oldValues.end_tuesday || "16:00");

  const [wednesday, setWednesday] = useState(false);
  const [start_time_wednesday, setStart_time_wednesday] = useState(oldValues.start_wednesday || "08:00");
  const [end_time_wednesday, setEnd_time_wednesday] = useState(oldValues.end_wednesday || "16:00");

  const [thursday, setThursday] = useState(false);
  const [start_time_thursday, setStart_time_thursday] = useState(oldValues.start_wednesday ||  "08:00");
  const [end_time_thursday, setEnd_time_thursday] = useState(oldValues.end_wednesday || "16:00");

  const [friday, setFriday] = useState(false);
  const [start_time_friday, setStart_time_friday] = useState(oldValues.start_friday || "08:00");
  const [end_time_friday, setEnd_time_friday] = useState(oldValues.end_friday || "16:00");

  const [saturday, setSaturday] = useState(false);
  const [start_time_saturday, setStart_time_saturday] = useState(oldValues.start_friday || "08:00");
  const [end_time_saturday, setEnd_time_saturday] = useState(oldValues.end_friday || "16:00");


  const [holiday, setHoliday] = useState(false);
  const [start_time_holiday, setStart_time_holiday] = useState(oldValues.start_holiday || "08:00");
  const [end_time_holiday, setEnd_time_holiday] = useState(oldValues.end_holiday || "16:00");

  useEffect(()=>{
    validStep(false)
  }, [validStep])
  const handleSetSunday = () => {
    setSunday(!sunday);

  };
  const handleSetMonday = () => {
    // alert("setmonday")
    setMonday(!monday)

    changeValue("start", "monday", start_time_monday);
    changeValue("end", "monday", end_time_monday);
  };
  const handleSetTuesday = () => {
    setTuesday(!tuesday)
    changeValue("start", "tuesday", start_time_tuesday);
    changeValue("end", "tuesday", end_time_tuesday);
  };
  const handleSetWednesday = () => {
    setWednesday(!wednesday)
    changeValue("start", "wednesday", start_time_wednesday);
    changeValue("end", "wednesday", end_time_wednesday);
  };
  const handleSetThursday = () => {
    setThursday(!thursday)
    changeValue("start", "thursday", start_time_thursday);
    changeValue("end", "thursday", end_time_thursday);
  };
  const handleSetFriday = () => {
    setFriday(!friday)
    changeValue("start", "friday", start_time_friday);
    changeValue("end", "friday", end_time_friday);
  };
  const handleSetSaturday = () => {
    setSaturday(!saturday)
    changeValue("start", "saturday", start_time_saturday);
    changeValue("end", "saturday", end_time_saturday);
  };
  const handleSetHoliday = () => {
    setHoliday(!holiday)
    changeValue("start", "holiday", start_time_holiday);
    changeValue("end", "holiday", end_time_holiday);
  };


  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, info },
    typography: { size },
  }) => ({
    width: pxToRem(164),
    height: pxToRem(130),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `#1A73E8 !important`,
      border: `${borderWidth[2]} solid ${info.main} !important`,
      color: '#fff',
    },
    "& .material-icons-round": {
      fontSize: `${size["3xl"]} !important`,
    },
  });

  return (
    <MDBox>

      <MDBox pt={0}>
        <Grid container spacing={3}>
          <Grid item xl={12} sx={{ height: "max-content" }}>
            <MDBox mt={2}>
              <Grid container spacing={1} >
                <Grid container  xs={12} sm={4} item>
                  <Grid item sm={6}>
                    <MDBox textAlign="center">
                    <MDButton
                      color="info"
                      variant={monday ? "contained" : "outlined"}
                      onClick={handleSetMonday}
                      sx={customButtonStyles} >
                      Monday
                    </MDButton>
                  </MDBox>
                  </Grid>
                  <Grid item sm={6} >
                    <Grid item sm={12} mt={2}>
                      <FormField label={"Start"} type={"time"} value={start_time_monday}
                                 onChange={ (e)=> {
                                    setStart_time_monday(e.target.value);
                                    changeValue("start", "monday", e.target.value);
                                  } }  />
                    </Grid>
                    <Grid item sm={12} mt={2}>
                      <FormField label={"End"} type={"time"} value={end_time_monday}
                                 onChange={(e)=> {
                                   setEnd_time_monday(e.target.value);
                                   changeValue("end", "monday", e.target.value);
                                 }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container  xs={12} sm={4} item>
                  <Grid item sm={6}>
                    <MDBox textAlign="center">
                    <MDButton
                      color="info"
                      variant={tuesday ? "contained" : "outlined"}
                      onClick={handleSetTuesday}
                      sx={customButtonStyles}
                    >
                      Tuesday
                    </MDButton>
                  </MDBox>
                  </Grid>
                  <Grid item sm={6} >
                    <Grid item sm={12} mt={2}>
                      <FormField label={"Start"} type={"time"} value={start_time_tuesday} onChange={(e)=> {
                        setStart_time_tuesday(e.target.value);
                        changeValue("start", "tuesday", e.target.value);
                      }}  />
                    </Grid>
                    <Grid item sm={12} mt={2}>
                      <FormField label={"End"} type={"time"} value={end_time_tuesday} onChange={(e)=> {
                        setEnd_time_tuesday(e.target.value);
                        changeValue("end", "tuesday", e.target.value);
                      }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container  xs={12} sm={4} item>
                  <Grid item sm={6}>
                    <MDBox textAlign="center">
                    <MDButton
                      color="info"
                      variant={wednesday ? "contained" : "outlined"}
                      onClick={handleSetWednesday}
                      sx={customButtonStyles}
                    >
                      Wednesday
                    </MDButton>
                  </MDBox>
                  </Grid>
                  <Grid item sm={6} >
                    <Grid item sm={12} mt={2}>
                      <FormField label={"Start"} type={"time"} value={start_time_wednesday} onChange={(e)=> {
                        setStart_time_wednesday(e.target.value);
                        changeValue("start", "wednesday", e.target.value);
                      }}  />
                    </Grid>
                    <Grid item sm={12} mt={2}>
                      <FormField label={"End"} type={"time"} value={end_time_wednesday}  onChange={(e)=> {
                        setEnd_time_wednesday(e.target.value);
                        changeValue("end", "wednesday", e.target.value);
                      }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container  xs={12} sm={4} item>
                  <Grid item sm={6}>
                    <MDBox textAlign="center">
                    <MDButton
                      color="info"
                      variant={thursday ? "contained" : "outlined"}
                      onClick={handleSetThursday}
                      sx={customButtonStyles}
                    >
                      Thursday
                    </MDButton>
                  </MDBox>
                  </Grid>
                  <Grid item sm={6} >
                    <Grid item sm={12} mt={2}>
                      <FormField label={"Start"} type={"time"} value={start_time_thursday}  onChange={(e)=> {
                        setStart_time_thursday(e.target.value);
                        changeValue("start", "thursday", e.target.value);
                      }} />
                    </Grid>
                    <Grid item sm={12} mt={2}>
                      <FormField label={"End"} type={"time"} value={end_time_thursday} onChange={(e)=> {
                        setEnd_time_thursday(e.target.value);
                        changeValue("end", "thursday", e.target.value);
                      }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container  xs={12} sm={4} item>
                  <Grid item sm={6}>
                    <MDBox textAlign="center">
                    <MDButton
                      color="info"
                      variant={friday ? "contained" : "outlined"}
                      onClick={handleSetFriday}
                      sx={customButtonStyles}
                    >
                      Friday
                    </MDButton>
                  </MDBox>
                  </Grid>
                  <Grid item sm={6} >
                    <Grid item sm={12} mt={2}>
                      <FormField label={"Start"} type={"time"} value={start_time_friday}  onChange={(e)=> {
                        setStart_time_friday(e.target.value);
                        changeValue("start", "friday", e.target.value);
                      }} />
                    </Grid>
                    <Grid item sm={12} mt={2}>
                      <FormField label={"End"} type={"time"} value={end_time_friday} onChange={(e)=> {
                        setEnd_time_friday(e.target.value);
                        changeValue("end", "friday", e.target.value);
                      }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container  xs={12} sm={4} item>
                  <Grid item sm={6}>
                    <MDBox textAlign="center">
                    <MDButton
                      color="warning"
                      variant={saturday ? "contained" : "outlined"}
                      onClick={handleSetSaturday}
                      sx={customButtonStyles}
                    >
                      Saturday
                    </MDButton>
                  </MDBox>
                  </Grid>
                  <Grid item sm={6} >
                    <Grid item sm={12} mt={2}>
                      <FormField label={"Start"} type={"time"} value={start_time_saturday} onChange={(e)=> {
                        setStart_time_saturday(e.target.value);
                        changeValue("start", "saturday", e.target.value);
                      }} />
                    </Grid>
                    <Grid item sm={12} mt={2}>
                      <FormField label={"End"} type={"time"} value={end_time_saturday} onChange={(e)=> {
                        setEnd_time_saturday(e.target.value);
                        changeValue("end", "saturday", e.target.value);
                      }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container  xs={12} sm={4} item>
                  <Grid item sm={6}>
                    <MDBox textAlign="center">
                    <MDButton
                      color="warning"
                      variant={sunday ? "contained" : "outlined"}
                      onClick={handleSetSunday}
                      sx={customButtonStyles}
                    >
                      Sunday
                    </MDButton>
                  </MDBox>
                  </Grid>
                  <Grid item sm={6} >
                    <Grid item sm={12} mt={2}>
                      <FormField label={"Start"} type={"time"} value={start_time_sunday}  onChange={(e)=> {
                        setStart_time_sunday(e.target.value);
                        changeValue("start", "sunday", e.target.value);
                      }}  />
                    </Grid>
                    <Grid item sm={12} mt={2}>
                      <FormField label={"End"} type={"time"} value={end_time_sunday} onChange={(e)=> {
                        setEnd_time_sunday(e.target.value);
                        changeValue("end", "sunday", e.target.value);
                      }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container  xs={12} sm={4}  item>
                  <Grid item sm={6}>
                    <MDBox textAlign="center">
                    <MDButton
                      color="secondary"
                      variant={holiday ? "contained" : "outlined"}
                      onClick={handleSetHoliday}
                      sx={customButtonStyles}
                    >
                      Holiday
                    </MDButton>
                  </MDBox>
                  </Grid>
                  <Grid item sm={6} >
                    <Grid item sm={12} mt={2}>
                      <FormField label={"Start"} type={"time"} value={start_time_holiday} onChange={(e)=> {
                        setStart_time_holiday(e.target.value);
                        changeValue("start", "holiday", e.target.value);
                      }}  />
                    </Grid>
                    <Grid item sm={12} mt={2}>
                      <FormField label={"End"} type={"time"} value={end_time_holiday} onChange={(e)=> {
                        setEnd_time_holiday(e.target.value);
                        changeValue("end", "holiday", e.target.value);
                      }} />
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default AmenityTimingWeekDay;
