

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Flatpickr from "react-flatpickr";

import "flatpickr/dist/themes/material_green.css";
// import "flatpickr/dist/flatpickr.css";

import MDInput from "components/MDInput";

function MDDatePicker({ input, ...rest }) {
  return (
    <Flatpickr {...rest}
                render={({ defaultValue }, ref) => (
                  <MDInput {...input} sx={{width:"100%"}} defaultValue={defaultValue} inputRef={ref} />
                )}
    />
  );
}

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {},
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePicker;
