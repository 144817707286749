import {useState} from "react";
import MDSnackbar from "../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../account/components/FormField";
import MDButton from "../../../components/MDButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import DataTable from "../../../examples/Tables/DataTable";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import loadDataTable from "./data";
import {modalStyle} from "../../../components/Utils/ModalStyle";



function CurrencyList() {

  const [open, setOpen] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


  const saveDataClicked = (e) =>{
    e.preventDefault();
    handleClose();
    openInfoSB();
  }

   const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="notifications"
      title="Amenity System"
      content="Your data saved successfully"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderModal = () => {
    return <Modal open={open} onClose={handleClose} color={"warning"} >
      <MDBox sx={modalStyle}>
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">Insert Type</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  defaultValue="Pool"
                  options={["Pool", "In-Ground Pool" , "Swimming Pool"]}
                  renderInput={(params) => (
                    <FormField {...params} label="Parent" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormField label="First Name" placeholder="Alec"  />
              </Grid>
              <Grid item xs={12} md={12}>
                <MDButton color={"info"} size={"large"} onClick={saveDataClicked} >Save Data</MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  };


  const renderDialog = () => {
    return (
        <Dialog
            open={openDialog}
            keepMounted
            onClose={handleCloseDialog}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete this item ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
          <MDButton onClick={handleCloseDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
        </DialogActions>
      </Dialog>
    )
   }


  const renderCitiesList = () => {
    function handleDelete(elem) {
      console.log(elem.target.id);
      handleOpenDialog();
    }
    return(
        <MDBox my={3}>
          {renderInfoSB}
          {renderModal()}
          {renderDialog()}
          <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <MDButton variant="gradient" color="info" size="large" onClick={handleOpen}>
              new Currency
            </MDButton>
          </MDBox>
          <Card>
            <DataTable showTotalEntries={true} table={loadDataTable(handleDelete)} entriesPerPage={false} canSearch />
          </Card>
        </MDBox>
    )
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
        {renderCitiesList()}
      <Footer />
    </DashboardLayout>
  );


}

export default CurrencyList;