import React, {useEffect, useState} from 'react';

import {Modal, TextField} from "@mui/material";
import Fade from "@mui/material/Fade";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutForm from "./CheckoutForm";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";

export default function CheckoutFormGenerator({show, product, price, showCount}) {
    const ModalStyleHalf = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 800,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4
    };
    const [count,setCount] = useState(1)
    const [error,setError] = useState("")

    return (
         <Modal open={show}>
            <Fade in={show}>
              <Card sx={ModalStyleHalf}>
                  <Card id="basic-info" sx={{ overflow: "visible" }}>
                      <Box p={2} mb={2}>
                        <Typography variant="h5">Choose Payment Method</Typography>
                          <MDButton color={"dark"} sx={{"float":"right"}} size={"medium"} variant={"text"} onClick={(e)=>window.location.reload()} ><Icon>close</Icon></MDButton>
                      </Box>
                      <Box component="form" pb={1} px={1}>
                          <Grid container spacing={1}>
                              <Grid item xs={12} md={12} sx={{"marginBottom":"3rem"}}>
                                  <MDTypography color={"info"} textGradient={true} variant={"h3"} size={"medium"}>Total Price : {price*count} $</MDTypography>
                              </Grid>
                              <Grid item xs={12} md={4} sx={{"display": showCount ? "block": "none"}}>
                                  <MDTypography color={"text"} textGradient={true} variant={"h6"} size={"small"}>Count:</MDTypography>
                              </Grid>
                              <Grid item xs={12} md={2}  sx={{"display": showCount ? "block": "none"}}>
                                  <MDButton size={"medium"}  onClick={(e)=>{
                                      setError("")
                                      if(count > 1){
                                          setCount(count - 1)
                                      }else{
                                          setError("You can order in minimum 1 item")
                                      }
                                  }} color={"dark"} ><Icon>south</Icon></MDButton>
                              </Grid>
                              <Grid item xs={12} md={3}  sx={{"display": showCount ? "block": "none"}}>
                                  <MDInput type={"text"} value={count}  fullWidth readOnly style={{textAlign:"center"}}  />
                              </Grid>
                              <Grid item xs={12} md={2}  sx={{"display": showCount ? "block": "none"}}>
                                  <MDButton size={"medium"} onClick={(e)=>{
                                      setError("")
                                      if(count < 6){
                                          setCount(count + 1)
                                      }else{
                                          setError("You can order in maximum 6 item's")
                                      }
                                  }} color={"dark"} sx={{"marginLeft":"2rem"}} ><Icon>north</Icon></MDButton>
                              </Grid>
                              {/*<hr />*/}
                              <Grid item xs={12} md={12}>
                                  <MDTypography color={"error"} textGradient={true} fontWeight={"bold"} variant={"h6"} style={{"textAlign":"center"}}   opacity={0.9} >{error}</MDTypography>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                  <hr style={{"margin":"1rem"}}/>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                  <CheckoutForm productId={product} count={count}  />
                              </Grid>


                        </Grid>
                      </Box>
                  </Card>
              </Card>
            </Fade>
          </Modal>
        )
}
