import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getElevators = () => {
  return axios.get(`${getPrivateURL()}elevators/`, {withCredentials:true} );
};
export const getMyElevatorBooking = () => {
  return axios.get(`${getPrivateURL()}elevator_booking/`, {withCredentials:true} );
};
export const bookElevator = (data) => {
  return axios.post(`${getPrivateURL()}elevator_booking/`, data, {withCredentials:true} );
};
export const cancelBookElevator = (booking_id) => {
  return axios.delete(`${getPrivateURL()}elevator_booking/${booking_id}`, {withCredentials:true} );
};
export const getElevator = (elevator_id) => {
  return axios.get(`${getPrivateURL()}elevators/` + elevator_id, {withCredentials:true} );
};
export const insertElevator = (values) => {
  return axios.post(`${getPrivateURL()}elevators/`, values, {withCredentials:true} );
};
export const updateElevator = (elevator_id, data ) => {
  return axios.put(`${getPrivateURL()}elevators/${elevator_id}`, data, {withCredentials:true});
};
export const removeElevator = (elevator_id ) => {
  return axios.delete(`${getPrivateURL()}elevators/${elevator_id}`, {withCredentials:true});
};
export const disableElevator = (elevator_id ) => {
  return axios.patch(`${getPrivateURL()}elevators/${elevator_id}`, {withCredentials:true});
};

const ElevatorsService = {
  getElevators,
  getMyElevatorBooking,
  getElevator,
  insertElevator,
  updateElevator,
  removeElevator,
  disableElevator,
  bookElevator,
  cancelBookElevator,
};
export default ElevatorsService;
