import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements, AddressElement, CartElement, ElementsConsumer, CardElement, PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
// import SoftButton from "../../components/SoftButton";
// import SubscriptionService, {getSubscriptionProduct} from "../../services/subscription.service";
// import {toast} from "react-toastify";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import PaymentService from "../../../services/payment.service";
// import SoftTypography from "../../components/SoftTypography";
// import {getFrontEndURL} from "../../services/core.service";

const CheckoutForm2 = ({productId, clientSecret}) => {
    const stripe = useStripe();
    const elements = useElements();


    const [errorMessage, setErrorMessage] = useState(null);
    const [readyState, setReadyState] = useState(false)
    const handleSubmit = async (event) => {
        event.preventDefault();
        // alert(elements)
        if (elements == null) {
          return;
        }
        const {error: submitError} = await elements.submit();
        if (submitError) {
          setErrorMessage(submitError.message);
          return;
        }
        let url = window.location.href + `/order?product=${productId}&pi=${clientSecret}&status=completed`;
        // alert(url);
        // const option = {
        //       //`Elements` instance that was used to create the Payment Element
        //       paymentMethodType: 'Card',
        //       elements,
        //       clientSecret,
        //       confirmParams: {
        //           return_url: url,
        //       },
        // };
        // if (clientSecret.startsWith("seti_")){
        //     const {error} = await stripe.confirmSetup(option);
        // }else{
        const {error} = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams:{
                return_url: url,
                receipt_email: "alighorbanali.99@gmail.com",
            },
            redirect: "always",
        });
        if (error) {
          setErrorMessage(error.message);
        } else {
            localStorage.setItem("active_plan", productId)
        }
        // }


    };
    return (
        <form onSubmit={handleSubmit} style={{"textAlign":"center"}}>
          <PaymentElement onReady={(e)=>setReadyState(true)}  options={{
              business:{
                  name:"hello world",
              },
              wallets:{
                  applePay:"never",
                  googlePay:"never"
              },
          }} />
          <MDButton size={"large"} color={"primary"}  sx={{"marginTop":"2rem"}}  type="submit" disabled={!stripe || !elements} onClick={handleSubmit}>
            Payment Now
          </MDButton>
          {/* Show error message to your customers */}
          {errorMessage && <MDTypography color={"error"} fontWeight={"bold"} sx={{"paddingTop":"1rem"}}>{errorMessage}</MDTypography>}
        </form>
      );
  };


export default function CheckoutForm ({productId, count}) {

  const stripePromise =  loadStripe("pk_test_51MWzCRBbljgouQ8eN7y5ZyCfbzn4CbiEfGlS95bKWAVo60m2b2rRn7dCOKwXAILzuXQFBgdMVtyQ8qTpD7AQyB1J00eTzFovTS");
  const [productInfo, setProductInfo] = useState({})
    useEffect(()=>{
        PaymentService.getProductInfo(productId)
        .then((data)=>{
            console.log(data)
            setProductInfo(data.data)
        }).catch((e)=>{
            // toast.error( e.message, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
            // alert("error in fetch product")
        })
    },[setProductInfo])

    // alert(productId)
  return (
      productInfo.hasOwnProperty("client_secret") && <Elements stripe={stripePromise} options={{
          mode: 'payment',
          amount: productInfo.price ,
          currency: "cad",
          appearance:{
              labels:"floating",
              theme:"flat",
              disableAnimations:false,
          },
          loader:"auto",
          // clientSecret:productInfo.client_secret,
      }}>
        <CheckoutForm2 productId={productId} clientSecret={productInfo.client_secret} />
      </Elements>)
}
