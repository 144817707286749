import { v4 as uuidv4 } from "uuid";
import Card from "layouts/applications/kanban/components/Card";

// Images
import officeDark from "assets/images/office-dark.jpg";
import meeting from "assets/images/meeting.jpg";
import homeDecore from "assets/images/home-decor-1.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";

const boards = {
  columns: [
    {
      id: uuidv4(),
      title: "Questions",
      cards: [
        {
          id: uuidv4(),
          template: {
            "question":"Do you have any of the following symptoms: fever/feverish, new or existing cough and difficulty breathing",
            "answer":"No"
          },
        },
        {
          id: uuidv4(),
          template: {
            "question":"Have you traveled internationally within the last 14 days (outside Canada)?",
            "answer":"No",
          },
        },
        {
          id: uuidv4(),
          template: {
            "question":"Have you had close contact with a confirmed or probable COVID-19 case?",
            "answer":"No",
          },
        },
        {
          id: uuidv4(),
          template: {
            "question":"Have you had close contact with a person with acute respitory illness who has been outside Canada in the last 14 days?",
            "answer":"No"
          },
        },
      ],
    },
  ],
};

export default boards;
