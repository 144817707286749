import customeService from "../../../../services/custome.service";
import {useEffect, useState} from "react";
import amenitiesService from "../../../../services/amenities.service";

export let categoriesList = JSON.parse(localStorage.getItem("cats"));
// const [categories, setCategories] = useState([])
amenitiesService.getAmenitiesType()
                .then((data)=> {categoriesList = data.data; localStorage.setItem("cats", JSON.stringify(data.data))})
                .catch((err) => console.log(err))

export const minorList =["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
export const guestList =["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
export const durationList = ["10 Minutes", "15 Minutes", "20 Minutes", "25 Minutes", "30 Minutes", "35 Minutes", "40 Minutes", "45 Minutes", "50 Minutes", "60 Minutes"];
export const gapList = ["5 Minutes", "10 Minutes", "15 Minutes", "20 Minutes", "25 Minutes", "30 Minutes", "35 Minutes", "40 Minutes", "45 Minutes", "50 Minutes", "60 Minutes"];
