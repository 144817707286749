import {useEffect, useMemo, useState} from "react";
import GHIconButton from "../../../../components/GHIconButton";
import CustomService from "../../../../services/custome.service";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {dayNames, holidayColumns} from "../../../../components/Utils/HeaderColumns";

function loadDataTable(clickItem){

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(holidayColumns);
    useEffect(()=> {
      CustomService.getHolidays()
        .then((res) => {
          setRows(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [setColumns, setRows] )
    return {
        "columns": columns,
        "rows": rows.map(function(element)  {
            return {
                  name: element.name,
                  holiday: element.holiday_date.split("T")[0],
                  weekday: dayNames[element.week_day],
                  action: (
                    <GHIconButton  onClick={clickItem} id={element.id} title={"Delete"} icon={"delete"} size={"small"}  color="error" />
                  ),
                }
            }),
    }
}

export default loadDataTable;