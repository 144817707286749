

import {useEffect, useState} from "react";

// react-router-dom components
import {Link, useNavigate, useSearchParams} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import MDSnackbar from "../../../../components/MDSnackbar";
import {getUserIsLogin, login} from "../../../../services/auth.service";
import CoreService from "../../../../services/core.service";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

function Cover() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [infoSB, setInfoSB] = useState(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  // const [cookies, setCookie] = useCookies(["Authorization"]);
  const [isFirstImpression, setIsFirstImpression] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [searchParams] = useSearchParams();


   const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Amenity System"
      content="Your logged in successfully"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Amenity System"
      content={errorMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  useEffect(() => {
    let timeout = searchParams.get('timeout')
    if(timeout !== undefined && timeout !== null && timeout === "true"){
      // alert("xxx")
      setErrorMessage("Your session is timeout")
      openErrorSB()
    }

    // if (localStorage.getItem("first_impression") === null){
    //   localStorage.setItem("first_impression", true);
    //   setIsFirstImpression(true);
    // }else{
    //   setIsFirstImpression(false);
    // }




  }, [setErrorMessage, setErrorSB])

  const loadWellcomeModal = () => {

    return (<Dialog
          open={isFirstImpression}
          keepMounted
          onClose={()=>setIsFirstImpression(false)}
          aria-describedby="alert-dialog-slide-description" >
          <DialogTitle>
              Welcome To New Amenity Booking Panel
          </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
            Dear valued client,<br/>
            We have updated our system.<br/>
            To be able to log in for the first time, you should change the password. <br/>
            You can click on the link previously sent to your email or use the link below.<br/>
            Thank you and have fun.<br/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={()=>setIsFirstImpression(false)} size={"large"} color={"info"} >Ok</MDButton>
      </DialogActions>
    </Dialog>)

  }



  // const loading = () => setLoading(false);

  // if (getUserIsLogin()) {
  //   navigate("/dashboard");
  // }


  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);
    console.log("login");
    login(username, password).then(
      (data) => {
        // alert("hello")
        // setCookie("Authorization", "Barrier " + data.data["barrier"], { path: "/", httpOnly:false, secure:false, domain: "login2.amenitybooking.ca" });
        //console.log(data);
        localStorage.setItem("Authentication",  data.data["barrier"])
        localStorage.setItem("permission", data.data["role"]);
        localStorage.setItem("avatar", CoreService.BASE_API_URL + data.data["avatar"]);
        localStorage.setItem("nick_name", data.data["nick_name"]);
        localStorage.setItem("first_name", data.data["first_name"]);
        localStorage.setItem("last_name", data.data["last_name"]);
        localStorage.setItem("email", data.data["email"]);
        localStorage.setItem("unit", data.data["unit"]);
        localStorage.setItem("client", data.data["client"]);
        openInfoSB();
        setLoading(false);
        setTimeout(()=>{navigate("/dashboard");}, 2000);
        //
      },
      (error) => {
         // alert("hello2")
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
          openErrorSB();
          setLoading(false);
          localStorage.setItem("permission", "");
          // setErrorType("danger");
          //console.log(resMessage);
          // setErrorMessage("username or password was incorrect !");
        // showNotification();
      }
    );
  };
  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      handleLogin(event)
    }
  }
  return (
    <CoverLayout image={bgImage}>
      {/*{loadWellcomeModal()}*/}
      <Card>
        <MDBox variant="gradient" bgColor="warning" borderRadius="lg" coloredShadow="success" mx={2} mt={-3} p={3} mb={1} textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" onSubmit={handleLogin} role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                onChange={onChangeUsername}
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true, required: true }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                onKeyPress={handleKeyPress}
                onChange={onChangePassword}
                placeholder="************"
                InputLabelProps={{ shrink: true, required: true }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={handleLogin}
                disabled={loading}
                variant="gradient"
                color="secondary"
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot your password?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/reset_password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Forgot Password
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        {renderInfoSB}
        {renderErrorSB}
      </Card>
    </CoverLayout>
  );
}

export default Cover;
