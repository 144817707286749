import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import MDEditor from "../../../../../../components/MDEditor";
import Switch from "@mui/material/Switch";

function AmenityTiming({validStep, uniqueId, oldValues, valueSetter, ...rest}) {
  return (
    <MDBox>
      <MDTypography variant="h5">Amenity Timing</MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          {/*<Grid item xs={12} sm={6}>*/}
          {/*    <FormField type="date" label="Start At"  value={"2022-10-10"} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} sm={6}>*/}
          {/*    <FormField type="date" label="Expire At" value={"2999-12-29"} />*/}
          {/*</Grid>*/}
          <Grid item xs={12} sm={4}>
              <Autocomplete
                  defaultValue="1"
                  getOptionLabel={option => option.toString()}
                  options={["1","2","3","4","6","7","8","9","10"]}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }}  label={"Day Of Booking Advanced"}/>
                  )}
                  onChange={(e,data)=> {
                      valueSetter({...oldValues, advanced_day:data})
                  }}
                />
          </Grid>
          <Grid item xs={12} sm={4}>
              <Autocomplete
                  defaultValue="1"
                  options={["1","2","3","4","6","7","8","9","10"]}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }}  label={"Number of days head"}/>
                  )}
                  onChange={(e,data)=> {
                      valueSetter({...oldValues, days_a_head:data})
                  }}
                />
          </Grid>
          <Grid item xs={12} sm={3}>

              <Switch title={"Hello"} size={"medium"} onChange={(e,x)=>
                  valueSetter({...oldValues, same_day_booking:x})} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Same day booking
              </MDTypography>

          </Grid>
          {/*<Grid item xs={12} sm={3}>*/}

          {/*    <Switch title={"Hello"} size={"medium"} />*/}
          {/*    <MDTypography*/}
          {/*      variant="button"*/}
          {/*      fontWeight="regular"*/}
          {/*      color="text"*/}
          {/*      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}*/}
          {/*    >*/}
          {/*      &nbsp;&nbsp;Last day booking*/}
          {/*    </MDTypography>*/}

          {/*</Grid>*/}
        </Grid>
      </MDBox>
    </MDBox>
  );
}


export default AmenityTiming;
