
import { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import {Icon} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';


function GHIconButton({ icon, color, size, title,  id,  onClick, action, rowName, ...res }) {
  // console.log(onClick)
  return (
    <Tooltip title={title}>
      <IconButton>
        <Icon fontSize={size} color={color} onClick={onClick} action={action}  rowName={rowName} id={id}
              sx={{ padding:0.1, display: "inline", placeItems: "center"}} {...res}>{icon}</Icon>
      </IconButton>
    </Tooltip>
  )
}

// Setting default values for the props of MDAlert
GHIconButton.defaultProps = {
  color: "info",
  size: "medium",
  rowName:"Amenity",
};

// Typechecking props of the MDAlert
GHIconButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
  ]),
  title:PropTypes.string.isRequired,
  rowName:PropTypes.string,
};

export default GHIconButton;