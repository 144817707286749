import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as React from 'react';
import MDButton from "../../../../components/MDButton";
import MDBox from "../../../../components/MDBox";
import Stepper from "@mui/material/Stepper";
import StepLabelClass from "../../../../assets/theme/components/stepper/stepLabel";
import {
    Paper,
    StepLabel,
    StepContent,
    stepLabelClasses,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions
} from "@mui/material";
import MDInput from "../../../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

import FormField from "layouts/pages/account/components/FormField";
import DataTable from "../../../../examples/Tables/DataTable";
import MDTypography from "../../../../components/MDTypography";
import Card from "@mui/material/Card";
import MDDatePicker from "../../../../components/MDDatePicker";
import TextField from "@mui/material/TextField";
import {bookPartyRoomColumns, dayNames} from "../../../../components/Utils/HeaderColumns";
import dayjs from "dayjs";
import {useState} from "react";
import ElevatorsService from "../../../../services/elevators.service";
import MDSnackbar from "../../../../components/MDSnackbar";
import GHIconButton from "../../../../components/GHIconButton";
import Icon from "@mui/material/Icon";
import {DigitalTimePicker} from "../components";
import PartyRoomService from "../../../../services/partyRooms.service";
import MDBadge from "../../../../components/MDBadge";
import CheckoutFormGenerator from "../../checkout";
import Checkbox from "@mui/material/Checkbox";



function BookPartyRoom() {

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [activeStep, setActiveStep] = React.useState(0);
    const [partyRoomDataTable, setPartyRoomDataTable] = useState({"columns" : bookPartyRoomColumns, "rows":[]});
    const [dialogAction, setDialogAction] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => setOpenDialog(false);
    const openInfoSB = () => setInfoSB(true);
    // const closeInfoSB = () => setInfoSB(false);
    const openErrorSB = () => setErrorSB(true);
    // const closeErrorSB = () => setErrorSB(false);
    const renderSB  = () => (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [allowDrinks,setAllowDrinks] = useState(false)
    const [lateHours,setLateHours] = useState(false)
    const [maxPeopleNumber,setMaxPeopleNumber] = useState(false)
    // const [confirmTerm,setConfirmTerm] = useState(true)
    const [date,setDate] = useState("")
    const [startTime,setStartTime] = useState("")
    const [endTime,setEndTime] = useState("")
    const [partyRooms, setPartyRooms] = React.useState([]);
    const [selectedRoom, setSelectedRoom] = React.useState({"name":"Select Room"});

    let currentDate = new Date().getFullYear().toString() + "-" + (new Date().getMonth()+1).toString() + "-" + new Date().getDate().toString()
    let maxDate = new Date().getFullYear().toString() + "-" + (new Date().getMonth()+1).toString() + "-" + (new Date().getDate() + 30).toString()

    React.useEffect(()=>{
        loadPartyRooms();
        loadPartyRoomBooking();
    },[setPartyRooms, setPartyRoomDataTable, setErrorMessage]);

    // const [questions, setQuestions] = useState([])
    const loadPartyRooms = () =>{
        PartyRoomService.getPartyRooms()
            .then((res) => {
                // alert(res.data)
                if (res?.data !== null && res?.data !== undefined){
                    // console.log(res.data)
                    setPartyRooms(res?.data);
                    if(res?.data?.length > 0){
                        setSelectedRoom(res?.data[0])
                    }
                }
            })
            .catch((reason)=>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
    }
    // alert(currentDate)
    let steps = [
          {
            label: 'Select Room',
            description: ``,
            input:<Grid item xs={12} mb={2} sm={12} sx={{ mt: 2 }} color={"white"} bgcolor={"white"} >
                    <MDBox variant="gradient" color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >
                        <Autocomplete
                            defaultValue={selectedRoom}
                            clearIcon={false}
                            getOptionLabel={(option) => option.name ||  "--loading--"}
                            options={ partyRooms}
                            onChange={(e,v)=> setSelectedRoom(v)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </MDBox>
                  </Grid>
          },
          {
            label: 'Select Date',
            description: '' ,
            input: <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                          <MDBox variant="gradient" color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >
                          <MDDatePicker
                              fullWidth
                              value={date}
                              onChange={(date)=>setDate(date[0])}
                              input={{ placeholder: "Select Date" }}
                                options={{
                                    minDate: currentDate,
                                    maxDate: maxDate,
                                    enableTime: false,
                                    altInput: true,
                                    position: 'below',
                                }}/>
                          </MDBox>
                      </Grid>
                    </Grid>
          },
          {
            label: 'Select Time',
            description:'',
            input: <Grid container spacing={1} mb={2}>
                      <Grid item xs={12} sm={12} >
                          <MDTypography opacity={"0.8"} fontWeight={"bold"}  color={"white"}>Start At</MDTypography>
                          <MDBox variant="gradient" color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >
                              <DigitalTimePicker onChange={(v)=>setStartTime(('0' + v.hour()).slice(-2) + ":" + ('0' + v.minute()).slice(-2))}  />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{"display": "none"}} >
                          <MDTypography opacity={"0.8"} fontWeight={"bold"}  color={"white"}>Book For</MDTypography>
                          <MDBox variant="gradient" p color={"white"} borderRadius="md" shadow="md" coloredShadow={"dark"} >

                              <Autocomplete
                                  defaultValue={{name:"One Hour", value:60}}
                                  clearIcon={false}
                                  isOptionEqualToValue={(o,v)=>console.log(o)}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(e,v)=>setEndTime(v.value)}
                                  options={[
                                      {name:"One Hour", value:60},
                                      {name:"2 Hours", value:120},
                                      {name:"3 Hours", value:180},
                                      {name:"4 Hours", value:240},
                                      {name:"5 Hours", value:300}
                                  ]}
                                  renderInput={(params) => (
                                    <FormField {...params} label="Select Time" InputLabelProps={{ shrink: true }} />
                                  )}
                                />
                        </MDBox>
                      </Grid>
                    </Grid>
          },
          {
            label: 'Questions',
            description: ``,
            input:
                    <>
                        <div className={"row"} style={{"display":selectedRoom.allow_drinks ? "flex" : "none"}} >
                            <div className={"col-12"}>
                                <Checkbox checked={allowDrinks} onChange={(e)=>setAllowDrinks(e.target.checked)}  />
                                <MDTypography variant="button" fontWeight="bold" color="light" sx={{  userSelect: "none", ml: -1 }}>&nbsp;&nbsp;I agree alcoholic drinks are forbidden in the party room.&nbsp;&nbsp;</MDTypography>
                            </div>
                        </div>
                        <div className={"row"} style={{"display":selectedRoom.max_people_number ? "flex" : "none"}}>
                            <div className={"col-12"}>
                                <Checkbox checked={maxPeopleNumber} onChange={(e)=>setMaxPeopleNumber(e.target.checked)}  />
                                <MDTypography variant="button" fontWeight="bold" color="light" sx={{  userSelect: "none", ml: -1 }}>&nbsp;&nbsp;I agree maximum number of people in party room is {selectedRoom.max_people_number}.&nbsp;&nbsp;</MDTypography>
                            </div>
                        </div>
                        <div className={"row"} style={{"display":selectedRoom.late_hours ? "flex" : "none"}}>
                            <div className={"col-12"}>
                                <Checkbox checked={lateHours} onChange={(e)=>setLateHours(e.target.checked)}  />
                                <MDTypography variant="button" fontWeight="bold" color="light" sx={{  userSelect: "none", ml: -1 }}>&nbsp;&nbsp;I agree party room must be evacuated before {selectedRoom.late_hours} .&nbsp;&nbsp;</MDTypography>
                            </div>
                        </div>
                    </>
          },
    ];

    const handleNext = () => {
        if (activeStep === steps.length - 1){
            if (selectedRoom.allow_drinks){
                setAllowDrinks(true)
            }
            if((!allowDrinks && selectedRoom.allow_drinks) ||
                (!lateHours && selectedRoom.late_hours) ||
                (!maxPeopleNumber && selectedRoom.max_people_number)){
                alert("you must confirm term and policy of this party room");
                return
            }
            let data = {
                "party_room":selectedRoom.id.toString(),
                "date":date.toString(),
                "start":startTime.toString(),
                // "end":endTime.toString(),
            }
            console.log(data);

            PartyRoomService.bookPartyRoom(data)
                .then((value)=>{
                    openInfoSB();
                    window.location.reload();
                })
                .catch((reason)=>{
                    setErrorMessage(reason.response.data.status.toString());
                    openErrorSB();
                });
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleFinish = () => {setActiveStep((prevActiveStep) => prevActiveStep + 1);};

    const handleBack = () => {setActiveStep((prevActiveStep) => prevActiveStep - 1);};

    const handleReset = () => {setActiveStep(0);};



    const [clickedItemId, setClickedItemId] = useState(0);


    const handleOpen = (event, model, id) => {
        switch (model){
            case "cancel":
                handleOpenDialog(model,id);
                break;
        }
    }
    const handleOpenDialog = (action, id) => {

        setOpenDialog(true);
        setDialogAction(action)
    }

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "cancel") {
            // if(confirm("Do you want to cancel party room ? ")){
            // alert(clickedItemId)
                PartyRoomService.cancelBookPartyRoom(clickedItemId)
                .then((response) => {
                    // openInfoSB();
                    window.location.reload()
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });
            // }
        }
    }


    const renderDialog = () => {
        return (
            <Dialog open={openDialog} keepMounted onClose={handleCloseDialog} aria-describedby="alert-dialog-slide-description">
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }

    const [openPayment, setOpenPayment] = useState(false)
    const clickItem = (elem) =>{
        let actionToDo = elem.target.getAttribute("action");
        let rowId = elem.target.id;
        setClickedItemId(rowId);
        console.log(actionToDo)
        switch (actionToDo) {
            case "cancel":
                handleOpen(elem,"cancel", rowId);
                break;
            case "payment":
                setOpenPayment(true);
                // handleOpen(elem,"cancel");
                break;
        }
    }
    const loadPartyRoomBooking = () => {
        setPartyRoomDataTable({"columns" : bookPartyRoomColumns, "rows":[]})
        PartyRoomService.getMyPartyRoomBooking()
        .then((res) => {
            console.log(res.data);
            setPartyRoomDataTable( {
                "columns": bookPartyRoomColumns,
                "rows": res.data.map(function(element)  {
                            return {
                                id: element.id,
                                party_room: element.party_room,
                                date: element.date,
                                start: element.start_time,
                                // end: element.end_time,
                                status: <MDBadge badgeContent={element.status} color={element.status === "confirmed" ? "success" : "secondary"} size="sm" container />,
                                action: (
                                  <div style={{"display":element.status === "canceled"? "none":"flex"}}>
                                      <GHIconButton onClick={clickItem} rowName={element.id} id={element.id} action={"cancel"}
                                                    title={"Cancel Booking"} color={"error"}
                                                    icon={"cancel"} size={"medium"} />
                                  </div>
                                ),
                            }
                        }),
            })
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const renderMain = () => {
        return (
            <Card>
                {renderDialog()}
                {renderSB()}

                <CheckoutFormGenerator product={"1"} showCount={false} show={openPayment} price={10.9} />
                <MDTypography ml={2} variant="h3" fontWeight="medium" color="dark" mt={1}></MDTypography>
                <MDBox mt={2} pb={3} px={2}>

                      <Grid container  spacing={3}>

                        <Grid item xs={12} md={4} >
                          <MDBox coloredShadow={"dark"}  variant={"gradient"} shadow={2} borderRadius={"3"}   >

                            <Stepper elevation={2} aria-busy={"true"}  activeStep={activeStep}
                                     orientation="vertical" nonLinear={true}
                                       autoSave={true} sx={{"padding":1}} >
                                { steps.map((step, index) => (
                                  <Step key={step.label}>
                                    <StepLabel sx={StepLabelClass}
                                      optional={null }
                                    >
                                      {step.label}
                                    </StepLabel>
                                    <StepContent sx={{"marginLeft":0.85,color:"white"}} >
                                      <Typography variant={"h5"} color={"#ffffff"} sx={{"marginBottom":1.3}}>{step.description}</Typography>
                                        {step.input}
                                      <MDBox sx={{ mb: 2 }}>
                                        <div>
                                          <MDButton
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled={index === steps.length - 1 && (date.length === 0 || startTime.length === 0 ||  !selectedRoom.hasOwnProperty("id"))}
                                          >
                                            {index === steps.length - 1 ? 'Confirm And Book' : 'Continue'}
                                          </MDButton>
                                          <MDButton
                                              variant={"outlined"}
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                          >
                                            Back
                                          </MDButton>
                                        </div>
                                      </MDBox>
                                    </StepContent>
                                  </Step>
                                ))
                                }
                              </Stepper>
                              {activeStep === steps.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                  <Typography>All steps completed - you&apos;re finished</Typography>
                                  <MDButton onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Book New Party Room
                                  </MDButton>
                                </Paper>
                              )}


                        </MDBox>
                         </Grid>
                        <Grid item xs={12} md={8} minHeight={"220px"}>
                          <MDBox variant={"gradient"}  borderRadius={"1"}   >
                            <Card>
                                <MDTypography sx={{"marginLeft":2, "marginTop":2, "marginBottom":2,}}
                                              color={"text"} fontWeight={"bold"} >Party Room Booking</MDTypography>
                                <DataTable
                                     table={partyRoomDataTable}
                                     canSearch={false}
                                     showTotalEntries={false}
                                     entriesPerPage={false}
                                     noEndBorder={false}
                                     isSorted={true} />
                            </Card>
                          </MDBox>
                        </Grid>
                      </Grid>
                </MDBox>
            </Card>
          );
    }

    return (
        <DashboardLayout>
          <DashboardNavbar />
          {renderMain()}
          <Footer />
        </DashboardLayout>
      );
}

export default BookPartyRoom;