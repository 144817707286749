import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {getUserRole} from "../../../../services/auth.service";
import ClientsService from "../../../../services/clients.service";
import {
    elevatorColumns,
    eventColumns,
    myOrderColumns,
    parcelColumns,
    userColumns
} from "../../../../components/Utils/HeaderColumns";
import GHIconButton from "../../../../components/GHIconButton";
import MDBadge from "../../../../components/MDBadge";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../../components/MDBox";
import {modalStyleClients, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import TextField from "@mui/material/TextField";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import PackagesService from "../../../../services/packages.service";
import EventsService from "../../../../services/events.service";
import Switch from "@mui/material/Switch";

function OrdersList() {

    const defaultInputValues = {  title: "", description: "",
        notificationUser:false, startTime: "08:00" ,
        endTime:"21:00", doDate:  "" };

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        description: Yup.string()
            .min(4, 'Event Description must be at least 4 characters')
            .required('Event Description is required'),
    });

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [units, setUnits] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);
    const [eventType, setEventType] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadClients();
        loadPackage();
        loadEventType();
    }, [setClients, setBuildings, setUnits]);

    const loadClients = (instance_id) => {
        ClientsService.getClients(instance_id)
        .then((res) => {
            console.log(res.data);
            setClients(res.data);
            if (res.data.length > 0){
                loadBuilding(res.data[0].id);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadBuilding = (client_id) => {
        ClientsService.getClientsBuilding(client_id)
        .then((res) => {
            console.log(res.data);
            setBuildings(res.data);
            if(res.data[0].length > 0){
                loadUnit(res.data[0].id);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadUnit = (building_id) => {
        ClientsService.getBuildingUnits(building_id)
        .then((res) => {
            console.log(res.data);
            setUnits(res.data);
            // loadProvince(res.data[0]);
        })
        .catch((error) => {
            console.log(error);
        });
    }



    const loadEventType = () => {
        EventsService.getEventTypes()
        .then((res) => {
            console.log(res.data);
            setEventType(res.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }



    const [eventTableData, setEventTableData] =
                        useState({"columns" : myOrderColumns, "rows":[]});

    const loadPackage = () => {
        setEventTableData({"columns" : myOrderColumns, "rows":[]})
        // EventsService.getMyEvents()
        // .then((res) => {
        //     console.log(res.data);
            setEventTableData( {
                "columns": myOrderColumns,
                "rows": [
                    {
                          order_id: "11001",
                          transaction: <MDTypography color={"text"} variant={"caption"}>
                              OR-12334331<br/>
                              pi_3NqbjkBbljgouQ8e1e8wf8Oq
                          </MDTypography> ,
                          product: "Product 2",
                          count: 2,
                          order_time: "2023-09-12 23:30",
                          total_price: "200 $CAD",
                          receive_time: "-",
                          status: <MDBadge badgeContent={"WAIT_FOR_ACCEPT"} size="sm" container color={"dark"} />,
                          action: (
                              <div>
                                  <GHIconButton onClick={handleClickItem} id={"11003"} action={"received"}
                                                title={"Received"} color={"info"} icon={"download"} size={"medium"}  />
                              </div>
                          ),
                    },
                    {
                          order_id: "11002",
                          transaction: <MDTypography color={"text"} variant={"caption"}>
                              OR-12334332<br/>
                              pi_3NqbmYBbljgouQ8e1oUyXRR5
                          </MDTypography> ,
                          product: "Product 3",
                          total_price: "124 $CAD",
                          count: 2,
                          order_time: "2023-09-10 23:30",
                          receive_time: "2023-09-12 16:30",
                          status: <MDBadge badgeContent={"RECEIVED"} size="sm" container color={"success"} />,
                    },
                    {
                        order_id: "11003",
                        transaction: <MDTypography color={"text"} variant={"caption"}>
                          OR-12334333<br/>
                          pi_3Nqbje1oUyXRR5e1e8wfYBblj
                        </MDTypography> ,
                        product: "Product 1",
                        total_price: "141 $CAD",
                        count: 2,
                        order_time: "2023-09-09 12:22",
                        receive_time: "2023-09-12 16:30",
                        status: <MDBadge badgeContent={"RECEIVED"} size="sm" container color={"success"} />,

                    },
                ]
            })

        // })
        // .catch((error) => {
        //   console.log(error);
        // });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(true);
                break;
            case "delete" :
                handleOpenDialog(model);
                break;
            case "change_status":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            PackagesService.removePackage(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }if (dialogAction === "change_status") {
            PackagesService.disablePackage(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }
    }


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "change_status":
                handleOpen(elem,"change_status");
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){
            // console.log(values)
            let dataToSend = {
                "description": values.description.toString(),
                "client": values.client.id.toString(),
                "building": values.building.id.toString(),
                "unit": values.unit.id.toString(),
            }
            console.log(dataToSend);

            PackagesService.insertPackage(dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [editMode, setEditMode] = useState(false);
    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleClients}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Event": "Insert New Event"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.title}
                          helperText={errors.title?.message}
                          value={values.title}
                          {...register("title")}
                          onChange={(event) => {
                            handleChange({ ...values, title: event.target.value })
                          }}
                        label="Event Title"
                        placeholder="Glass Cleaner"  />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                          fullWidth
                          required
                          type={"date"}
                          error={!!errors.doDate}
                          helperText={errors.doDate?.message}
                          value={values.doDate}
                          {...register("doDate")}
                          onChange={(event) => {
                            handleChange({ ...values, doDate: event.target.value })
                          }}  />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                          fullWidth
                          required
                          type={"time"}
                          variant={"standard"}
                          error={!!errors.startTime}
                          helperText={errors.startTime?.message}
                          value={values.startTime}
                          {...register("startTime")}
                          onChange={(event) => {
                            handleChange({ ...values, startTime: event.target.value })
                          }}
                        label="Start Time"
                        placeholder="18:30"  />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                          fullWidth
                          required
                          type={"time"}
                          variant={"standard"}
                          label={"End Time"}
                          error={!!errors.endTime}
                          helperText={errors.endTime?.message}
                          value={values.endTime}
                          {...register("endTime")}
                          onChange={(event) => {
                            handleChange({ ...values, endTime: event.target.value })
                          }}
                        placeholder="20:30"  />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                          required
                          options={clients}
                          value={values.client}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.client_name ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, client: value });
                            loadBuilding(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.client}
                                        helperText={errors.client?.message}
                                        label="Client"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("client")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                          required
                          options={buildings}
                          value={values.building}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, building: value });
                            loadUnit(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.building}
                                        helperText={errors.building?.message}
                                        label="Building"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("building")} />
                          )}
                        />

                  </Grid>
                  <Grid item xs={12} md={4}>
                     <Autocomplete
                          required
                          options={eventType}
                          value={values.eventType}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.type ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, eventType: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.eventType}
                                        helperText={errors.eventType?.message}
                                        label="EventType"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("eventType")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDTypography color={"secondary"} textGradient={true} opacity={0.5} component="caption"
                                  sx={{"paddingBottom":3}} >If You want to select custom units you can choose from list below</MDTypography>
                    <Autocomplete
                          required
                          options={units}
                          value={values.unit}
                          clearIcon={false}
                          contentEditable={false}
                          multiple
                          getOptionLabel={(option) => option.unit ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, unit: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.unit}
                                        helperText={errors.unit?.message}
                                        label="Unit (If Exist)"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("unit")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          multiline={true}
                          aria-multiline={true}
                          error={!!errors.description}
                          helperText={errors.description?.message}
                          value={values.description}
                          {...register("description")}
                          onChange={(event) => {
                            handleChange({ ...values, description: event.target.value })
                          }}
                        label="Event Description (Multi Line)"
                        placeholder="Write Event Description Here"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDBox mt={0.5} >
                        <Switch checked={values.notificationUser}   onChange={() =>
                            handleChange({ ...values, notificationUser: !values.notificationUser })
                        } />
                      </MDBox>
                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button"  fontWeight="regular" color="text">
                          Notification To Member
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };


    const renderEventList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                  {/*<MDButton variant="gradient" color="warning" size="large"*/}
                  {/*          disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>*/}
                  {/*  new event*/}
                  {/*</MDButton>*/}
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={eventTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderEventList()}
          <Footer />
        </DashboardLayout>
    );


}

export default OrdersList;