import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

const createOrder = (productId) => {
    return axios.get(`${getPrivateURL()}order/create/${productId.toString()}`, {withCredentials:true})
};
const confirmOrder = (productId) => {
    return axios.get(`${getPrivateURL()}confirmOrder/${productId.toString()}`, {withCredentials:true})
};
const getProductInfo = (productId) => {
    return axios.get(`${getPrivateURL()}product/${productId.toString()}`, {withCredentials:true})
};

const PaymentService = {
  createOrder,
  getProductInfo,
  confirmOrder,
};
export default PaymentService;
