
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";

import DefaultProjectCard from "../../../examples/Cards/ProjectCards/DefaultProjectCard";

import homeDecor1 from "../../../assets/images/products/p1.webp";
import homeDecor2 from "../../../assets/images/products/p2.jpg";
import homeDecor3 from "../../../assets/images/products/p3.jpg";
import homeDecor4 from "../../../assets/images/products/p4.jpg";
import homeDecor5 from "../../../assets/images/products/p5.jpg";
import Modal from "@mui/material/Modal";
import {modalStyleClients} from "../../../components/Utils/ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";

import MDButton from "../../../components/MDButton";
import {useState} from "react";
import CheckoutFormGenerator from "../checkout";

function ShopList() {



    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(0)
    const [selectedProductPrice, setSelectedProductPrice] = useState(100)
    const ToggleShowModal  = e => setShowPaymentModal(!showPaymentModal)
    const onPaymentButtonClicked = e =>{
        alert("payment clicked")
    }
    const onClickProduct = (e) =>{
        setSelectedProduct(e.target.id)
        ToggleShowModal()
    }
    const renderPaymentModal = () => {
        return <Modal open={showPaymentModal} onClose={ToggleShowModal} color={"warning"} >
          <MDBox sx={modalStyleClients}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{"Payment Method"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>

                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton  color={"success"} size={"large"} onClick={onPaymentButtonClicked} >Order Now</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

  return (
    <DashboardLayout>
      <DashboardNavbar />
        {/*{renderPaymentModal()}*/}
        <CheckoutFormGenerator product={selectedProduct} showCount={true} show={showPaymentModal} price={selectedProductPrice} />
        <MDBox my={3}>
             <Grid container spacing={5}>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor1}
                    title="Pump"
                    label="32.2 $CAD"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.."
                    action={{ color: "success", label: "Buy Now", id:1 }}
                    onClick={onClickProduct}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor2}
                    title="Water filter"
                    label="32.2 $CAD"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.."
                    action={{ color: "success", label: "Buy Now", id:2 }}
                    onClick={onClickProduct}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor3}
                    title="Thermodynamic Pump"
                    label="32.2 $CAD"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.."
                    action={{ color: "success", label: "Buy Now", id:3 }}
                    onClick={onClickProduct}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor4}
                    title="Filter"
                    label="32.2 $CAD"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.."
                    action={{ color: "success", label: "Buy Now", id:4 }}
                    onClick={onClickProduct}
                  />
                </Grid>

                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor3}
                    title="Thermodynamic Pump"
                    label="32.2 $CAD"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.."
                    action={{ color: "success", label: "Buy Now", id:5 }}
                    onClick={onClickProduct}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor1}
                    title="Pump"
                    label="32.2 $CAD"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.."
                    action={{ color: "success", label: "Buy Now", id:6 }}
                    onClick={onClickProduct}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor4}
                    title="Filter"
                    label="32.2 $CAD"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.."
                    action={{ color: "success", label: "Buy Now", id:7 }}
                    onClick={onClickProduct}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={homeDecor2}
                    title="Water filter"
                    label="32.2 $CAD"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.."
                    action={{ color: "success", label: "Buy Now", id:8 }}
                    onClick={onClickProduct}
                  />
                </Grid>
            </Grid>
        </MDBox>
      <Footer />
    </DashboardLayout>
  );

}

export default ShopList;