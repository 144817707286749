

const calendarEventsData = [
  {
    title: "Available",
    start: "2022-09-30",
    end: "2022-09-30",
    className: "success",
  },
  {
    title: "Available",
    start: "2022-10-01",
    end: "2022-10-01",
    className: "success",
  },
  {
    title: "Full",
    start: "2022-10-02",
    end: "2022-10-02",
    className: "error",
  },
  {
    title: "Available",
    start: "2022-10-03",
    end: "2022-10-03",
    className: "success",
  },
];

export default calendarEventsData;
