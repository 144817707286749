import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import {useEffect, useState} from "react";
import MDButton from "../../../../components/MDButton";
import DataTableDataStaffs from "./data";
import Modal from '@mui/material/Modal';
import InsertUserComponent from "../../users/insert";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DialogShower, SnackShower, TableShower} from "../../../../components/Utils/Tools";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {modalStyle} from "../../../../components/Utils/ModalStyle";
import {TextField} from "@mui/material";
import LoadingOverlay from 'react-loading-overlay';
import UserService from "../../../../services/user.service";


function Staffs() {


    const title= "New Staff"
    const defaultInputValues = { newPassword: '', passwordConfirmation: ''}
    const [values, setValues] = useState(defaultInputValues);
    const [isValid, setIsValid] = useState(false);
    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required('New Password is required')
            .min(6, 'New Password must be at least 6 characters'),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });
    const { register, handleSubmit, formState: { errors  } } =
                        useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const [activeLoading, setActiveLoading] = useState(false);
    const [showModalChangePassword, setShowModalChangePassword] = useState(false);
    const [showModalChangeUnit, setShowModalChangeUnit] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [snackBarSetting, setSnackBarSetting] = useState({type:"error", error_message:"", show:false});

    const [userInsertSetting, setUserInsertSetting] = useState({ show:false, showBulk:false, title:"Main Building"});
    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));
    };
    setTimeout(function (){
        setIsValid(validationSchema.isValidSync(values));
    }, 1000);

    const onClickSaveData = (e) => {
        console.log("onClickSaveData");

        e.preventDefault();
        if(isValid){
            setActiveLoading(true);
            values["user"] = selectedUser.id
            UserService.ChangePasswordAdminMode(values)
                .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                window.location.reload(true);
                setActiveLoading(false);
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status})
                setActiveLoading(false);
            });
        }

    }


    const onClickSaveDataUnits = (e) => {
        console.log("onClickSaveDataUnits");

        e.preventDefault();
         const createdValues = {
                'user':selectedUser.id,
                'unit':selectedUnit,
                'firstName':selectedFirstName,
                'lastName':selectedLastName,
                'email':selectedEmail,
                'client':selectedUser.client,
            }

            setActiveLoading(true);
            UserService.updateUser(selectedUser.id, createdValues)
                .then((e)=>{

                setActiveLoading(false);
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                window.location.reload(true);
            }).catch((err)=>{
                setActiveLoading(false);
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status})

            });

    }



    const onClickResendEmailItem = (elem) => {
        let id = elem.target.id;
        setActiveLoading(true);
        UserService.resendConfirmEmail(id)
            .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                // window.location.reload(true);
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status});
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
            });
    }
    const onClickDisableItem = (elem) => {
        let id = elem.target.id;
        setActiveLoading(true);
        UserService.disableUser(id)
            .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                // window.location.reload(true);
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
                window.location.reload(true);
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status});
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
            });
    }
    const onClickRemoveItem = (elem) => {
        let id = elem.target.id;
        setActiveLoading(true);
        UserService.deleteUser(id)
            .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})

                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
                window.location.reload(true);
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status});
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
            });
    }

    const [dialogSetting, setDialogSetting] = useState({ show:false, onClickDone: onClickRemoveItem, id:""});

    const [searchParams, setSearchParams] = useSearchParams();
    const onClickRow = (elem) => {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let client = elem.target.getAttribute("client");
        let rowId = elem.target.id;
        setSelectedUser({...selectedUser, id:rowId, name:rowName, client:client});
        console.log(actionToDo + rowName + rowId)
        switch (actionToDo) {
            case "change_password":
                setShowModalChangePassword(true);
                break;
            case "edit":
                setShowModalChangeUnit(true);
                getUserData(rowId);
                break;
            case "book_amenity":
                const navigate = useNavigate();
                //alert(searchParams["amenity_id"])
                if(searchParams["amenity_id"] && searchParams["amenity_id"] !== undefined && searchParams["amenity_id"] !== null){
                    navigate(`/amenities/book?id=${searchParams["amenity_id"]}&userid=${rowId}`);
                }else{
                    navigate(`/amenities?userid=${rowId}`);
                }
                break;
            case "delete":
                setDialogSetting({...dialogSetting, title:"Delete", show:true, id:rowId, onClickDone: onClickRemoveItem})
                break;
            case "disable":
                setDialogSetting({...dialogSetting, title:"Disable/Enable", show:true, id:rowId, onClickDone:onClickDisableItem})
                break;
            case "resend_confirm":
                setDialogSetting({...dialogSetting, title:"Resend Confirmation Email", show:true, id:rowId, onClickDone:onClickResendEmailItem})
                break;
        }
    }
    const onClickNew = (e) => {
        console.log("onClickNew");
        // setShowModal(true);

        setUserInsertSetting({...userInsertSetting, show: true});
    }


    const [tableSetting, setTableSetting] = useState({ data: DataTableDataStaffs,
                                                                    progressChanger:setActiveLoading,
                                                                    showButton: true,
                                                                    buttonText: title,
                                                                    buttonClick: onClickNew,
                                                                    onClickRow:onClickRow});



    const renderChangePasswordModel = () =>{
       return <Modal open={showModalChangePassword}   color={"warning"}
                     onClose={(e)=>{setShowModalChangePassword(false)}}>
          <MDBox sx={modalStyle}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3} pb={2}>
                <MDTypography variant="h6">Change Password ({selectedUser.name})</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} >
                  <Grid container >
                    <Grid container spacing={2} pt={2} px={2} xs={12} md={12}   >
                        <Grid item xs={12} md={12}>
                            <TextField
                              required fullWidth
                              type="password" label="New Password" name="newPassword"
                              error={!!errors.newPassword}
                              helperText={errors.newPassword?.message}
                              value={values.newPassword}
                              {...register("newPassword")}
                              onChange={(event) =>
                                  handleChange({ ...values, newPassword: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                              required fullWidth
                              type="password" label="Confirm Password" name="passwordConfirmation"
                              error={!!errors.passwordConfirmation}
                              helperText={errors.passwordConfirmation?.message}
                              value={values.passwordConfirmation}
                              {...register("passwordConfirmation")}
                              onChange={(event) =>
                                    handleChange({ ...values, passwordConfirmation: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDButton color={"info"} size={"large"} sx={{width:'50%'}}
                                      disabled={!isValid} onClick={onClickSaveData}  >Save Data</MDButton>
                        </Grid>

                    </Grid>
                  </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    }



    const [selectedUnit, setSelectedUnit] = useState("")
    const [selectedFirstName, setSelectedFirstName] = useState("")
    const [selectedLastName, setSelectedLastName] = useState("")
    const [selectedEmail, setSelectedEmail] = useState("")


    const getUserData = (userId) =>{
        UserService.getUser(userId)
            .then((result)=>{
                setSelectedFirstName(result.data["first_name"]);
                setSelectedLastName(result.data["last_name"]);
                setSelectedEmail(result.data["email"]);
            }).catch((reason) =>{
                alert(reason)
                setSelectedUnit("");
                setSelectedFirstName("");
                setSelectedLastName("");
                setSelectedEmail("");
            });
    }


    const renderChangeUnits = () => {
       return <Modal open={showModalChangeUnit}   color={"warning"}
                     onClose={(e)=>{setShowModalChangeUnit(false)}}>
          <MDBox sx={modalStyle}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3} pb={2}>
                <MDTypography variant="h6">Edit User ({selectedUser.name})</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} >
                  <Grid container >
                    <Grid container spacing={2} pt={2} px={2} xs={12} md={12}   >
                        <Grid item xs={12} md={6}>
                            <TextField
                              required fullWidth
                              value={selectedFirstName}
                              type="text" label="FirstName" name="firstName"
                              onChange={(event) =>
                                  setSelectedFirstName(event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                              required fullWidth
                              value={selectedLastName}
                              type="text" label="LastName" name="lastName"
                              onChange={(event) =>
                                  setSelectedLastName(event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                               fullWidth
                              value={selectedEmail}
                              type="text" label="Email" name="email"
                              onChange={(event) =>
                                  setSelectedEmail(event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                              type="text" label="Unit Name" name="unit"
                              value={selectedUnit}
                              placeholder={"If you want change unit"}
                              onChange={(event) =>
                                  setSelectedUnit(event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDButton color={"info"} size={"large"} sx={{width:'50%'}}
                                      onClick={onClickSaveDataUnits} >Save Data</MDButton>
                        </Grid>

                    </Grid>
                  </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    }



    const renderUserList = () => {
        console.log('renderUserList')
        return(
            <MDBox my={3}>
                {renderChangePasswordModel()}
                {renderChangeUnits()}
                <SnackShower values={snackBarSetting} setValues={setSnackBarSetting} />
                <DialogShower values={dialogSetting} setValues={setDialogSetting} />
                <TableShower values={tableSetting} setValues={setTableSetting} />
                {/*{renderTable()}*/}
                <InsertUserComponent settings={userInsertSetting} setSettings={setUserInsertSetting}
                                     snackBarSetting={snackBarSetting} setSnackBarSetting={setSnackBarSetting}  />
            </MDBox>
        )

        // return(
    //     <MDBox my={3}>
    //       {renderInfoSB}
    //       {renderModal()}
    //     <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
    //       <MDButton variant="gradient" color="info" size="large" onClick={handleOpen} to={"/users/insert"} component={Link}>
    //         new member
    //       </MDButton>
    //       {/*<MDButton variant="gradient" color="warning" size="large" onClick={handleOpen} to={"/users/insert"} component={Link}>*/}
    //       {/*  new staff*/}
    //       {/*</MDButton>*/}
    //       <MDBox display="flex">
    //         <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
    //           filters&nbsp;
    //           <Icon>keyboard_arrow_down</Icon>
    //         </MDButton>
    //         {renderMenu}
    //         <MDBox ml={1}>
    //           <MDButton variant="outlined" color="dark">
    //             <Icon>description</Icon>
    //             &nbsp;export csv
    //           </MDButton>
    //         </MDBox>
    //       </MDBox>
    //     </MDBox>
    //     <Card>
    //       <DataTable showTotalEntries={true} table={dataTableDataUsers} entriesPerPage={false} canSearch />
    //     </Card>
    //   </MDBox>
    // )
  };

    return (
      <DashboardLayout>
        <DashboardNavbar />
          <LoadingOverlay active={activeLoading} spinner text='Loading...'>
            {renderUserList()}
          </LoadingOverlay>
        <Footer />
      </DashboardLayout>
    );
}

export default Staffs;
