

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import authService from "../../../../services/auth.service";
import {useState} from "react";
import MDSnackbar from "../../../../components/MDSnackbar";


function Cover() {
  const [email,setEmail] = useState("");

  const [infoSB, setInfoSB] = useState(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Amenity System"
      content="Password reset link send to your email address."
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Amenity System"
      content="Error in send password !"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const handleClick = (e) => {
    e.preventDefault()
    if(email === undefined || email.length < 5){
      alert("email is not valid");
      return;
    }
    authService.resetPassword(email)
        .then((e)=>{
          openInfoSB()
        }).catch((reason)=>{
          openErrorSB()
          // alert("error in reset password");
        });
  }

  return (
    <CoverLayout coverHeight="23vh" image={bgImage}>
      {renderInfoSB}
      {renderErrorSB}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="success" mx={2} mt={-3} py={2} mb={1} textAlign="center" >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput type="email" label="Email" autoComplete="new-password" variant="standard" fullWidth value={email} onChange={(e)=>{
                setEmail(e.target.value);
              }} />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="secondary" fullWidth onClick={handleClick}>
                Send Reset Link
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
