import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import AmenityInfo from "./components/amenityInfo";
import AmenityMedia from "./components/aminetyMedia";
import AmenityPricing from "./components/amenityPricing";
import AmenityInsertReview from "./components/amenityInsertReview";
import AmenityTiming from "./components/amenityTiming";
import AmenityTimingWeekDay from "./components/amenityTimingWeekDay";
import AmenityQuestions from "../questions";
import AmenityComment from "../comments";

import { v4 as uuidv4 } from 'uuid';
import AmenitiesService, {uploadImage} from "../../../../services/amenities.service";

import {useEffect} from "react";

import MDSnackbar from "../../../../components/MDSnackbar";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {useLocation, useNavigate} from "react-router-dom";
import CheckoutForm from "../payment/CheckoutForm";
function getSteps() {
  // return ["1. Product Info", "2. Media", "3. Pricing", "4. WeekDay", "5. Timing", "6. Questions", "7. Ads", "8. Review"];
  return ["1. Info", "2. Media", "3. Pricing", "4. WeekDay", "5. Timing", "6. Questions"];
}

function InsertAmenity() {

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const[values, setValues]=useState({});
    const[validStep, setValidStep]=useState(false);
    const[uniqueId, setUniqueId]=useState(uuidv4().toString());
    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, SetErrorMessage] = useState("");
    // console.log(uniqueId);
    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [activeLoading, setActiveLoading] = useState(false);

    console.log(values);


    function getStepContent(stepIndex) {
      switch (stepIndex) {
        case 0:
          return <AmenityInfo validStep={setValidStep} uniqueId={uniqueId} valueSetter={setValues} oldValues={values} />;
        case 1:
          return <AmenityMedia validStep={setValidStep} uniqueId={uniqueId} valueSetter={setValues} oldValues={values} />;
        case 2:
          return <AmenityPricing validStep={setValidStep} uniqueId={uniqueId} valueSetter={setValues} oldValues={values} />;
        case 3:
          return <AmenityTimingWeekDay validStep={setValidStep} uniqueId={uniqueId} valueSetter={setValues} oldValues={values} />;
        case 4:
          return <AmenityTiming validStep={setValidStep} uniqueId={uniqueId} valueSetter={setValues} oldValues={values} />;
        case 5:
          return <AmenityQuestions validStep={setValidStep} uniqueId={uniqueId} valueSetter={setValues} oldValues={values} />;
        // case 6:
        //   return <AmenityAds />;
        // case 6:
        //   return <AmenityInsertReview  validStep={setValidStep} uniqueId={uniqueId} valueSetter={setValues} oldValues={values} />;
        default:
          return null;
      }
    }

  // console.log(values)
  // console.log("fromParent -> " + validStep);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleFinish = () => {
    console.log(values)
    AmenitiesService.insertAmenity(values)
        .then((response)=>{
            if(values.image !== undefined && values.image !== null){
                let fd = new FormData();
                fd.append("image", values.image, values.image.name);
                fd.append("id",response.data.id );
                AmenitiesService.uploadImage(fd)
                    .then((data)=>{
                        setInfoSB(true);
                        setActiveLoading(false);
                        window.location = "/amenities";
                        // navigate("/amenities");
                    }).catch((res)=>{

                        SetErrorMessage(res.response.data.status);
                        setErrorSB(true);
                        setActiveLoading(false);
                    });
            }
        }).catch((reason)=>{
            SetErrorMessage(reason.response.data.status);
            setErrorSB(true);
            setActiveLoading(false);
        });
  };
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox  mb={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={11}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h2" fontWeight="bold">
                    {(id > 0 ? "Edit Amenity" : "Add New Amenity")}
                </MDTypography>
              </MDBox>
              <MDTypography variant="h3" fontWeight="regular" color="secondary">
                This information will describe more about the amenity.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel >
                  {steps.map((label) => (
                    <Step key={label} >
                      <StepLabel  >{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="gradient" color="light" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    <MDButton
                      variant="gradient"
                      color="success"
                      size="large"
                      disabled={!validStep}
                      onClick={!isLastStep ? handleNext : handleFinish}
                    >
                      {isLastStep ? "Save And Confirm" : "Next Step"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InsertAmenity;
