import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import {useEffect, useState} from "react";
// import Autocomplete from "@mui/material/Autocomplete";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDButton from "../../../../components/MDButton";
import SelectDate from "./components/dateselect";
// import SelectTime from "./components/timeselect";
import BookingData from "./components/bookingdata";
import ReviewData from "./components/reviewdata";
import Account from "../../../applications/wizard/components/Account";
import * as PropTypes from "prop-types";
import Ads from "../../ads";
import QuestionApprove from "../questions/components/question_approve";
import {useLocation, useNavigate} from "react-router-dom";
import AmenitiesService, {getAmenityInfo} from "../../../../services/amenities.service";
import ReCAPTCHA from "react-google-recaptcha";


import LoadingOverlay from 'react-loading-overlay';
import MDSnackbar from "../../../../components/MDSnackbar";
import PaymentService from "../../../../services/payment.service";
import {createRef} from "react";
const recaptchaRef = createRef();

function getSteps() {
  // if (isMobile){
  // }else{
  //     return ["1. Select Date", "2. Select Time", "3. Your Data", "4. Questions", "4. Review and payment"];
  // }
  //
    return ["1", "2", "3", "4", "5"];
}
// function getSteps() {
//
// }
function onChange(value) {
  // console.log("Captcha value:", value);
}


function AmenitiesBook() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const search = useLocation().search;

  const [values, setValues] = useState({'name': '...'});

  const handleNext = () => {
    setValues({...values, isValid:false, autoNext:false});
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  if (values.autoNext !== undefined && values.autoNext !== null && values.autoNext === true){
      handleNext();
  }
  function getStepContent(stepIndex) {
      switch (stepIndex) {
        case 0:
          return <SelectDate values={values} setValues={setValues}  />;
        case 1:
          return <Account values={values} setValues={setValues} />;
        case 2:
          return <BookingData  values={values} setValues={setValues}/>;
        case 3:
          return <QuestionApprove  values={values} setValues={setValues}/>;
        case 4:
          return <ReviewData values={values} setValues={setValues} />;
        default:
          return null;
      }
    }
    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, SetErrorMessage] = useState("");
    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
  const navigate = useNavigate();
  const [activeLoading, setActiveLoading] = useState(false);
  const finishAndPayment = () => {

    const id = new URLSearchParams(search).get('id');
    setActiveLoading(true);
    // alert("createBooking");

      // if (values.price > 0){
      //   PaymentService.createOrder(id)
      //   .then((value)=>{
      //       window.location = value["url"]
      //   }).catch((reason)=>{
      //       SetErrorMessage(reason.response.data.status);
      //       setErrorSB(true);
      //       setActiveLoading(false);
      //   });
      // }else{
          AmenitiesService.createBooking(id, values)
            .then(value => {
                setInfoSB(true);
                setActiveLoading(false);
                navigate("/dashboard");
            }).catch(reason => {
                SetErrorMessage(reason.response.data.status);
                setErrorSB(true);
                setActiveLoading(false);
            });
      // }

  };

  const getAmenityInfo = () => {
    // alert("getAmenityInfo");
    setActiveLoading(true);
    const id = new URLSearchParams(search).get('id');
    const userid = new URLSearchParams(search).get('userid');
    if(userid !== undefined && userid !== null){
          AmenitiesService.getAmenityInfoCustomUser(id, userid)
        .then(value => {
            console.log(value.data)
            setValues(value.data);
            setActiveLoading(false);
        }).catch(reason => {
            SetErrorMessage(reason.response.data.status);
            setErrorSB(true);
            setActiveLoading(false);
        });
    }else{
          AmenitiesService.getAmenityInfo(id)
        .then(value => {
            console.log(value.data)
            setValues(value.data);
            setActiveLoading(false);
        }).catch(reason => {
            SetErrorMessage(reason);
            setErrorSB(true);
            setActiveLoading(false);
        });
    }

  }

  useEffect(() => {
    getAmenityInfo()
  },[])


  const renderMain = () => (
      <LoadingOverlay active={activeLoading} spinner text='Saving content...'>
        {renderSB}
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LfDYAQlAAAAAMw3XZ3XxVmcV_LJGxYrSAzZ60aF"
          onChange={onChange}
        />

        <Grid xs={12} lg={12}>
          <MDBox mt={0.1} mb={9}>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={12}>
              <Ads type={"header"} />
                <MDBox mt={6} mb={8} textAlign="center">
                  <MDBox mb={1}>
                    <MDTypography variant="h2" fontWeight="bold">
                      Booking {values.name}
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="h3" fontWeight="regular" color="secondary">
                    {values.description}
                  </MDTypography>
                </MDBox>

                <Card>

                  <MDBox mt={-3} mb={3} mx={2}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step  key={label}>
                          <StepLabel  ><h2>{label}</h2></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox>
                      {getStepContent(activeStep)}
                      <MDBox mt={3} width="100%" display="flex" justifyContent="right">
                      {/*{activeStep === 0 ? (*/}
                      {/*                      <MDBox />*/}
                      {/*                    ) : (*/}
                      {/*                      <MDButton variant="gradient" color="light" onClick={handleBack}>*/}
                      {/*                        back*/}
                      {/*                      </MDButton>*/}
                      {/*                    )}*/}
                        <MDButton
                          variant="gradient"
                          color="success"
                          size="large"
                          disabled={!values.isValid}
                          onClick={!isLastStep ? handleNext : finishAndPayment}
                        >

                          {isLastStep ? (values.price > 0 ? "Confirm And Proceed to Checkout" : "Confirm And Book") : "Next"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              <Ads type={"footer"} />
              </Grid>
            </Grid>
          </MDBox>

        </Grid>
      </LoadingOverlay>
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderMain()}
      <Footer />
    </DashboardLayout>
  );
}

export default AmenitiesBook;
