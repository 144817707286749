import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTimePicker, DigitalClock, TimePicker, TimeClock} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {DateRangePicker} from "@mui/x-date-pickers-pro";
import "./mui_custom.css"



export function DigitalTimePicker({onChange}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <DigitalClock disablePast={false} onChange={onChange} ampm={false} />
      </DemoContainer>
    </LocalizationProvider>
  );
}
export function BasicTimePicker({onChange}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <TimeClock views={["hours"]}  formatDensity={"spacious"} sx={{"display":"inline"}}  onChange={onChange} ampm={false} />
      </DemoContainer>
    </LocalizationProvider>
  );
}
export function BasicDateTimePicker({label, onChange}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <DateTimePicker  label={label}
                         sx={{"width":"100%"}}  onChange={onChange} ampm={false} />
      </DemoContainer>
    </LocalizationProvider>
  );
}
export function BasicDateRangePicker({onAccept}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}  >
      <DemoContainer components={['DatePicker']}  >
        <DateRangePicker onAccept={onAccept}   disablePast={true} displayWeekNumber={true}  sx={{width:"100%"}}  className={"fc-timegrid-axis-frame-liquid"} />
      </DemoContainer>
    </LocalizationProvider>
  );
}