import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import Divider from "@mui/material/Divider";
import ReviewInfoCard from "../../../book/components/reviewCard";

function AmenityInsertReview({validStep, uniqueId, ...rest}) {
  return (
   <MDBox>
      <MDBox>
        <Grid item spacing={2} lg={6}>
          <MDTypography variant="h5">Review Information</MDTypography>
        </Grid>
      </MDBox>
      <MDBox pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <ReviewInfoCard
              title="profile information"
              description="You are booking Mark's pool on 12-12-2022 at 14:30 with the following information:"
              info={{
                amenityName: "Alec M. Thompson",
                periodCapacity: "3",
                category: "Pool",
                periodTime: "45 min",
                restTime: "15 min",
                guestsCount: "0",
                childCount: "0",
                email: "alie M@mail.com",
                building: "November Building",
                paymentAmount: "12.6 $",
              }}
              action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
            <Divider orientation="vertical" sx={{ mx: 0 }} />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default AmenityInsertReview;
