import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

const editProfile = (country_id) => {
    return axios.get(`${getPrivateURL()}provinces/${country_id.toString()}`)
};

const getProfile = () =>
    axios.post(`${getPrivateURL()}users/me/`, {} ,{withCredentials:true})

const saveInfo = (first_name, last_name, birthday, phone, sendEmail) =>
    axios.put(`${getPrivateURL()}users/me/` ,
        {"first_name": first_name, "last_name": last_name, "birthday": birthday, "phone": phone, "send_mail": sendEmail},
        {withCredentials:true})
const changePassword = (values) =>
    axios.put(`${getPrivateURL()}users/change_password/` , values, {withCredentials:true})
const ChangePasswordAdminMode = (values) =>
    axios.post(`${getPrivateURL()}users/change_password/` , values, {withCredentials:true})
const changeUnit = (values) =>
    axios.post(`${getPrivateURL()}users/change_unit/` , values, {withCredentials:true})
const updateUser = (user_id, values) =>
    axios.put(`${getPrivateURL()}users/` + user_id , values, {withCredentials:true})

const changeAvatar = (avatarFormData) =>
    axios.post(`${getPrivateURL()}users/change_avatar/` ,
                avatarFormData,{withCredentials:true, headers: {'Content-Type': 'multipart/form-data'}})

export const getMe = () => localStorage.getItem("user_object");
export const getUsers = () => axios.get(`${getPrivateURL()}users/` ,{withCredentials:true})
export const getStaffs = () => axios.get(`${getPrivateURL()}staffs/` ,{withCredentials:true})
export const getUser = (user_id) => axios.get(`${getPrivateURL()}users/` + user_id ,{withCredentials:true})
export const addUser = (data) => {
    return axios.post(`${getPrivateURL()}users/` , data,{withCredentials:true})
};
export const addBulkUser = (bulkFormData) => {
    return axios.post(`${getPrivateURL()}users/` , data,
        {withCredentials:true, headers: {'Content-Type': 'multipart/form-data'}})
};
export const resendConfirmEmail = (user_id) => {
    return axios.post(`${getPrivateURL()}users/${user_id}/resend_confirm` , null, {withCredentials:true})
};
export const disableUser = (user_id) => {
    return axios.patch(`${getPrivateURL()}users/${user_id}` , null, {withCredentials:true})
};
export const deleteUser = (user_id) => {
    return axios.delete(`${getPrivateURL()}users/${user_id}` , null, {withCredentials:true})
};
export const createSingle = (values) => {
    return axios.post(`${getPrivateURL()}users/create_single` , values, {withCredentials:true})
};
export const createBulk = (values_form) => {
    return axios.post(`${getPrivateURL()}users/create_bulk` , values_form, {withCredentials:true,
                        headers:{'Content-Type':'multipart/form-data'}
    })
};

const UserService = {
  createSingle,
  createBulk,
  editProfile,
  saveInfo,
  changeAvatar,
  changePassword,
  ChangePasswordAdminMode,
  updateUser,
  resendConfirmEmail,
  disableUser,
  deleteUser,
  getProfile,
  getUsers,
  getStaffs,
  getUser,
  getMe,
};
export default UserService;
