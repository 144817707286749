// @mui material components
import Icon from "@mui/material/Icon";

const pageRoutes = [
  // {
  //   name: "pages",
  //   columns: 3,
  //   rowsPerColumn: 2,
  //   collapse: [
  //     {
  //       name: "dashboards",
  //       icon: <Icon>dashboard</Icon>,
  //       collapse: [
  //         {
  //           name: "analytics",
  //           route: "/dashboards/analytics",
  //         },
  //         {
  //           name: "sales",
  //           route: "/dashboards/sales",
  //         },
  //       ],
  //     },
  //     {
  //       name: "users",
  //       icon: <Icon>people</Icon>,
  //       collapse: [
  //         {
  //           name: "reports",
  //           route: "/pages/users/reports",
  //         },
  //       ],
  //     },
  //     {
  //       name: "extra",
  //       icon: <Icon>queue_play_next</Icon>,
  //       collapse: [
  //         {
  //           name: "pricing page",
  //           route: "/pages/pricing-page",
  //         },
  //         { name: "RTL", route: "/pages/rtl" },
  //         { name: "widgets", route: "/pages/widgets" },
  //         { name: "charts", route: "/pages/charts" },
  //         {
  //           name: "notfications",
  //           route: "/pages/notifications",
  //         },
  //       ],
  //     },
  //     {
  //       name: "projects",
  //       icon: <Icon>precision_manufacturing</Icon>,
  //       collapse: [
  //         {
  //           name: "timeline",
  //           route: "/pages/projects/timeline",
  //         },
  //       ],
  //     },
  //     {
  //       name: "account",
  //       icon: <Icon>account_balance</Icon>,
  //       collapse: [
  //         {
  //           name: "settings",
  //           route: "/pages/account/setting",
  //         },
  //         {
  //           name: "billing",
  //           route: "/pages/account/billing",
  //         },
  //         {
  //           name: "invoice",
  //           route: "/pages/account/invoice",
  //         },
  //       ],
  //     },
  //     {
  //       name: "profile",
  //       icon: <Icon>badge</Icon>,
  //       collapse: [
  //         {
  //           name: "profile overview",
  //           route: "/pages/profile/profile-overview",
  //         },
  //         {
  //           name: "all projects",
  //           route: "/pages/profile/all-projects",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "authenticaton",
    collapse: [
      {
        name: "Login",
        dropdown: false,
        icon: <Icon>login</Icon>,
        route: "/authentication/login",
        // collapse: [
        //   {
        //     name: "cover",
        //     route: "/authentication/login",
        //   },
        // ],
      },
      {
        name: "sign up",
        dropdown: false,
        icon: <Icon>assignment</Icon>,
        route: "/authentication/register",
        // collapse: [
        //   {
        //     name: "cover",
        //     route: "/authentication/register",
        //   },
        // ],
      },
      {
        name: "reset password",
        dropdown: false,
        icon: <Icon>restart_alt</Icon>,
        route: "/authentication/reset-password",
        // collapse: [
        //   {
        //     name: "cover",
        //     route: "/authentication/reset-password/cover",
        //   },
        // ],
      },
    ],
  },
  // {
  //   name: "application",
  //   collapse: [
  //     {
  //       name: "kanban",
  //       route: "/applications/kanban",
  //       icon: "widgets",
  //     },
  //     {
  //       name: "wizard",
  //       route: "/applications/wizard",
  //       icon: "import_contacts",
  //     },
  //     {
  //       name: "data tables",
  //       route: "/applications/data-tables",
  //       icon: "backup_table",
  //     },
  //     {
  //       name: "calendar",
  //       route: "/applications/calendar",
  //       icon: "event",
  //     },
  //   ],
  // },
  // {
  //   name: "ecommerce",
  //   columns: 2,
  //   rowsPerColumn: 1,
  //   collapse: [
  //     {
  //       name: "orders",
  //       icon: <Icon>shopping_cart</Icon>,
  //       collapse: [
  //         {
  //           name: "order list",
  //           route: "/ecommerce/orders/order-list",
  //         },
  //         {
  //           name: "order details",
  //           route: "/ecommerce/orders/order-details",
  //         },
  //       ],
  //     },
  //     {
  //       name: "products",
  //       icon: <Icon>memory</Icon>,
  //       collapse: [
  //         {
  //           name: "new product",
  //           route: "/ecommerce/products/new-product",
  //         },
  //         {
  //           name: "edit product",
  //           route: "/ecommerce/products/edit-product",
  //         },
  //         {
  //           name: "product page",
  //           route: "/ecommerce/products/product-page",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "docs",
  //   collapse: [
  //     {
  //       name: "getting started",
  //       href: "https://amenitybooking.ca/learning-lab/react/quick-start/material-dashboard/",
  //       description: "All about overview, quick start, license and contents",
  //       icon: <Icon>article</Icon>,
  //     },
  //     {
  //       name: "foundation",
  //       href: "https://amenitybooking.ca/learning-lab/react/colors/material-dashboard/",
  //       description: "See our colors, icons and typography",
  //       icon: <Icon>grading</Icon>,
  //     },
  //     {
  //       name: "components",
  //       href: "https://amenitybooking.ca/learning-lab/react/alerts/material-dashboard/",
  //       description: "Explore our collection of fully designed components",
  //       icon: <Icon>apps</Icon>,
  //     },
  //     {
  //       name: "plugins",
  //       href: "https://amenitybooking.ca/learning-lab/react/datepicker/material-dashboard/",
  //       description: "Check how you can integrate our plugins",
  //       icon: <Icon>extension</Icon>,
  //     },
  //   ],
  // },
];

export default pageRoutes;
