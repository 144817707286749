import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
// import MDEditor from "../../../../../../components/MDEditor";

function AmenityComment() {
  return (
    <MDBox>
      <MDTypography variant="h5">Amenity Timing</MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
              <FormField type="date" label="Start At"  value={"2022-10-10"} />
          </Grid>
          <Grid item xs={12} sm={6}>
              <FormField type="date" label="Expire At" value={"2999-12-29"} />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default AmenityComment;
