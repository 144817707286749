import axios from "axios";

import CoreService, {getPrivateURL, getPublicURL} from "./core.service";


export const getUser = (token) => axios.get(`${getPublicURL()}getUser?activation=` + token);

export const resetPassword = (email) => {
  return axios.get(`${getPublicURL()}resetPassword?email=` + email);
};
export const registerComplete = (data) => {
  return axios.post(`${getPublicURL()}register`, data);
};
export const confirmResetPassword = (data) => {
  return axios.post(`${getPublicURL()}resetPassword`, data);
};
export const login = (email, password) => {

  eraseCookie("Authorization");
  const raw = JSON.stringify({
    password,
    email,
  });
  return axios.post(`${getPublicURL()}login`, raw);
};

export const logout = () => {

  axios.post(`${getPublicURL()}logout`, {withCredentials: true}).then(r => console.log(r.data)) ;
  localStorage.clear();

  eraseCookie("Authorization");
  localStorage.setItem("second_load2", "1");
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};


export const doLogout = () => {
  logout();
  window.location.reload();
};


export const getUserRole = () => localStorage.getItem("permission") || "";
export const getCurrentInstanceId = () => localStorage.getItem("instance_id") || 1;
export const getUserNickName = () => localStorage.getItem("nick_name") || "John Doe";
export const getUserFirstName = () => localStorage.getItem("first_name") || "John";
export const getUserLastName = () => localStorage.getItem("last_name") || "Doe";
export const getUserUnit = () => localStorage.getItem("unit") || "1234";
export const getUserEmail = () => localStorage.getItem("email") || "12345@gmail.com";
export const getClientName = () => localStorage.getItem("client") || "North Building";
export const getUserAvatar = () => localStorage.getItem("avatar");
export const getCurrentInstanceName = () => localStorage.getItem("instance_name") || "AAIS";
export const getCurrentInstanceLogo = () => localStorage.getItem("instance_logo") ||
    CoreService.BASE_API_URL + "statics/instances/AB_Logo.png" ;

export const getUserIsLogin = () => {
  if(localStorage.getItem("second_load2") === null || localStorage.getItem("second_load2") === undefined){
    localStorage.setItem("second_load2", "1");
    eraseCookie("Authorization");
    return false;
  }
  if((document.cookie.indexOf('Authorization=') > -1 ||
          (localStorage.getItem("Authentication") !== null &&
            localStorage.getItem("Authentication") !== undefined &&
            localStorage.getItem("Authentication").length > 0) )&&
      localStorage.getItem("permission") !== null &&
      localStorage.getItem("permission") !== undefined){
    return true;
  }else{
    localStorage.clear();
    eraseCookie("Authorization");
    // alert("not login 2")
    return false;

    // window.location.reload()
  }

};


function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=0'
    localStorage.removeItem(name)
}

export const changeLocalAvatar = (avatar) => localStorage.setItem("avatar", CoreService.BASE_API_URL + avatar)

const AuthService = {
  login,
  logout,
  getUserNickName,
  getClientName,
  getUserAvatar,
  getUserRole,
  getUserIsLogin,
  changeLocalAvatar,
  getUser,
  registerComplete,
  resetPassword,
  confirmResetPassword,
};
export default AuthService;
