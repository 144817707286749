import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BookingCard from "examples/Cards/BookingCard";
// import Modal from 'react-modal';

// Images
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import MDButton from "../../../../components/MDButton";
import DataTable from "../../../../examples/Tables/DataTable";
import Modal from '@mui/material/Modal';
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../../account/components/FormField";
import MDSnackbar from "../../../../components/MDSnackbar";
import loadDataTable from "../data";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {modalStyle, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SnackShower, ModalShower, DialogShower, TableShower, ModalContent} from "../../../../components/Utils/Tools";
import {ImageOutlined} from "@mui/icons-material";
import {Image} from "react-bootstrap";
import DefaultLogo from "assets/images/aais-logo-black.png";
import LoadingOverlay from 'react-loading-overlay';
import InstancesService, {insertInstance} from "../../../../services/instances.service";


function Instances() {

    const title= "New Instance"
    const defaultInputValues = { instanceName: '', instanceDomain: '',instanceDisplayName: '', instanceLogo: '',
                                logoUrl: DefaultLogo, instanceDescription: '', instanceAdminMail: ''}
    const [values, setValues] = useState(defaultInputValues);
    const validationSchema = Yup.object().shape({
        instanceName: Yup.string()
            .min(6, 'Instance Name must be at least 6 characters')
            .required('Instance Name is required'),
        instanceDisplayName: Yup.string()
            .min(6, 'Instance Display Name must be at least 6 characters')
            .required('Instance Display Name is required'),
        instanceDomain: Yup.string()
            .matches(/^\S*$/, 'Whitespace is not allowed')
            .min(3, 'Subdomain must be at least 3 characters')
            .required('Subdomain is required'),
        instanceDescription: Yup.string()
            .min(10, 'Instance Description must be at least 10 numbers') .nullable(),
        instanceAdminMail: Yup.string().required("email is required.").email("email is not valid"),

    });

    const { register, handleSubmit, formState: { errors  } } = useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const [imageUrl, setImageUrl] = useState(DefaultLogo)
    const [isValid, setIsValid] = useState(false);
    const [activeLoading, setActiveLoading] = useState(false);
    const [modalContent, setModalContent] = useState( );


    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));
    };


    const onClickSaveData = (e) => {
        console.log("onClickSaveData");
        setActiveLoading(true);
        let f = new FormData();
        for (const valuesKey in values) {
            console.log(valuesKey + " ->" + values[valuesKey])
            f.append(valuesKey, values[valuesKey])
        }
        console.log(f);
        InstancesService.insertInstance(f).then((e)=>{
            setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
            window.location.reload(true);
            setActiveLoading(false);
        }).catch((err)=>{
            setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status})
            setActiveLoading(false);
        });
    }


    const [snackBarSetting, setSnackBarSetting] = useState({type:"error", error_message:"", show:false});
    const [modalSetting, setModalSetting] = useState({ modalTitle:title, show:false, modalContent: modalContent });


    const onClickDisableItem = (elem) => {
        let id = elem.target.id;
        setActiveLoading(true);
        InstancesService.disableInstance(id)
            .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                window.location.reload(true);
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status})
            });
    }
    const onClickRemoveItem = (elem) => {
        let id = elem.target.id;
        setActiveLoading(true);
        InstancesService.removeInstance(id)
            .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                window.location.reload(true);
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status})
            });
    }


    const [dialogSetting, setDialogSetting] = useState({ show:false, onClickDone: onClickRemoveItem, id:""});

    const [showModal, setShowModal] = useState(false);

    const onClickRow = (elem) => {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        console.log(actionToDo + rowName + rowId)
        switch (actionToDo) {
            case "user_list":
                setModalSetting({...modalSetting, show:true, modalContent:<ModalContent type={"user_list"}/>})
                break;
            case "client_list":
                setModalSetting({...modalSetting, show:true, modalContent:<ModalContent type={"client_list"}/>})
                break;
            case "building_list":
                setModalSetting({...modalSetting, show:true, modalContent:<ModalContent type={"building_list"}/>})
                break;
            case "edit":
                setShowModal(true);
                break;
            case "delete":
                setDialogSetting({...dialogSetting, title:"Delete", show:true, id:rowId, onClickDone: onClickRemoveItem})
                break;
            case "disable":
                setDialogSetting({...dialogSetting, title:"Disable/Enable", show:true, id:rowId, onClickDone:onClickDisableItem})
                break;
        }
    }
    const onClickNew = (e) => {
        console.log("onClickNew");
        setShowModal(true);
    }

    const [tableSetting, setTableSetting] = useState({ data: loadDataTable, showButton: true, buttonText: title,
                                                                    buttonClick: onClickNew,  onClickRow:onClickRow});

    const renderModel = () =>{
       return <Modal open={showModal}   color={"warning"}  onClose={(e)=>{setShowModal(false)}}>
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">{title}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}  textAlign={"center"}>
                      <Image src={imageUrl} id={"imgLogo"} thumbnail alt={"Instance Logo"} width={100} height={100} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                          required fullWidth variant={"outlined"} type="file" name="instanceLogo"
                          error={!!errors.instanceLogo}
                          helperText={errors.instanceLogo?.message}
                          // value={values.instanceLogo}
                          {...register("instanceLogo")}
                          onChange={(event) =>{
                              // setImageUrl();
                              handleChange({ ...values, instanceLogo: event.target.files[0]});
                              document.getElementById("imgLogo").src = URL.createObjectURL(event.target.files[0]);
                          }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                          required fullWidth type="text" label="Instance Name" name="instanceName"
                          error={!!errors.instanceName}
                          helperText={errors.instanceName?.message}
                          value={values.instanceName}
                          {...register("instanceName")}
                          onChange={(event) =>
                              handleChange({ ...values, instanceName: event.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                          required fullWidth type="text" label="Subdomain" name="instanceDomain"
                          error={!!errors.instanceDomain}
                          helperText={errors.instanceDomain?.message}
                          value={values.instanceDomain}
                          {...register("instanceDomain")}
                          onChange={(event) =>
                              handleChange({ ...values, instanceDomain: event.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                          required fullWidth type="text" label="Display Name" name="instanceDisplayName"
                          error={!!errors.instanceDisplayName}
                          helperText={errors.instanceDisplayName?.message}
                          value={values.instanceDisplayName}
                          {...register("instanceDisplayName")}
                          onChange={(event) =>
                              handleChange({ ...values, instanceDisplayName: event.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                          required fullWidth type="text" label="Description" name="instanceDescription"
                          error={!!errors.instanceDescription}
                          helperText={errors.instanceDescription?.message}
                          value={values.instanceDescription}
                          {...register("instanceDescription")}
                          onChange={(event) =>
                              handleChange({ ...values, instanceDescription: event.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                          required fullWidth type="email" label="Admin E-Mail" name="instanceAdminMail"
                          error={!!errors.instanceAdminMail}
                          helperText={errors.instanceAdminMail?.message}
                          value={values.instanceAdminMail}
                          {...register("instanceAdminMail")}
                          onChange={(event) =>
                              handleChange({ ...values, instanceAdminMail: event.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <MDButton color={"info"} size={"large"} disabled={!isValid} onClick={onClickSaveData} >Save Data</MDButton>
                    </Grid>
                  </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    }


    const renderInstanceList = () => {
        return(
            <MDBox my={3}>
                {renderModel()}
                <SnackShower values={snackBarSetting} setValues={setSnackBarSetting} />
                <DialogShower values={dialogSetting} setValues={setDialogSetting} />
                <TableShower values={tableSetting} setValues={setTableSetting} />
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
      <DashboardNavbar />
        <LoadingOverlay active={activeLoading} spinner text='Saving content...'>
            {renderInstanceList()}
        </LoadingOverlay>
      <Footer />
    </DashboardLayout>
    );
}

export default Instances;
