

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Account({values, setValues, ...other}) {
  const [design, setDesign] = useState(false);
  const [code, setCode] = useState(false);
  const [develop, setDevelop] = useState(false);


  const [selected, setSelected] = useState("");
  const handleSetDevelop = (selected_item) => {
    if(selected_item.target.tagName === 'H3'){
      selected_item = selected_item.target.parentNode;
    }else{
      selected_item = selected_item.target;
    }
    // console.log(selected_item.tagName);
    let start = selected_item.getAttribute("start_time");
    let end = selected_item.getAttribute("end_time");
    // console.log(start);
    if(start !== null  && start !== "" && start.length> 0){
      setSelected(start);
      setValues({...values, isValid:true,  start_time:start, end_time:end, autoNext: true})
    }else{
      setValues({...values, isValid:false})
    }
  };

  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, info },
    typography: { size },
  }) => ({
    width: pxToRem(130),
    height: pxToRem(80),
    fontSize: pxToRem(17),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `#1A73E8 !important`,
      border: `${borderWidth[2]} solid ${info.main} !important`,
      color: '#fff',
    },

    "& .material-icons-round": {
      fontSize: `${size["3xl"]} !important`,
    },
  });

  function renderTime() {
    let x = [];
    // console.log(values.selectedDate);
    let i = 0;
    values.selectedDate["times"].forEach(function (data) {
      i += 1;
      x.push(<Grid item xs={6} sm={3}>
            <MDBox textAlign="center">
              <MDButton
                color="info"
                disabled={data["capacity"] <= 0}
                variant={selected === data["start_time"] ? "contained" : "outlined"}
                start_time={data["start_time"]}
                end_time={data["end_time"]}
                key={i}

                onClick={handleSetDevelop}
                sx={customButtonStyles}
              >
                <h3>{data["start_time"]}
                  <hr/>
                  {data["end_time"]}</h3>
              </MDButton>
              <MDTypography variant="h6" sx={{ mt: 1 }}>
                Capacity : {data["capacity"]}
              </MDTypography>
            </MDBox>
          </Grid>)
    });
    return x;

  }


  return (
    <MDBox>
      <MDBox width="80%" textAlign="center" mx="auto" my={1}>
        <MDBox mb={1}>
          <MDTypography variant="h2" fontWeight="regular">
            What time do you want to reserve ?
          </MDTypography>
        </MDBox>
        <MDTypography variant="body3" color="text">
          select time to rserve and click on next
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          {renderTime()}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Account;
