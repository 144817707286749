

// react-router-dom components
import {Link, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import {useEffect, useState} from "react";
import authService from "../../../../services/auth.service";
import MDSnackbar from "../../../../components/MDSnackbar";

function RegisterCover() {

  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({'password':'', 'first_name':'', 'username':''})
  const [checkBox, setCheckBox] = useState(false)
  const search = useLocation().search;
  const activationCode = new URLSearchParams(search).get('activation');
  const [infoSB, setInfoSB] = useState(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  // console.log(activationCode)
  useEffect(()=>{

    authService.getUser(activationCode)
        .then((res)=>{
            //console.log(res);
            setCurrentUser(res.data);
        }).catch((reason)=>{
            //console.log(reason);
            authService.logout()
            navigate("/authentication/login");
        });
  },[setCurrentUser])


  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Amenity System"
      content="Data saved successfully"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Amenity System"
      content="Error in saving data"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const handleClick = (e) => {
    e.preventDefault();
    if(currentUser["username"] === undefined || currentUser["username"].length === 0){
        alert("username is empty")
        return;
    }
    if(currentUser["first_name"] === undefined || currentUser["first_name"].length === 0){
        alert("first_name is empty")
        return
    }
    if(currentUser["password"] === undefined || currentUser["password"].length === 0){
        alert("password is empty")
        return;
    }
    if(currentUser["password"].length < 6){
        alert("password length must be minimum 6 character")
        return;
    }
    if(checkBox === false){
        alert("You must accept our terms")
        return;
    }
    const data = {
      'activation': activationCode,
      'email': currentUser["username"],
      'password': currentUser["password"],
      'first_name': currentUser["first_name"],
    };
    authService.registerComplete(data)
    .then((res)=>{
        //console.log(res);
        openInfoSB()
        setTimeout(function () { navigate("/authentication/login"); }, 1000);
        // setCurrentUser(res.data);
    }).catch((reason)=>{
        //console.log(reason);
        authService.logout()
        openErrorSB()
        // navigate("/authentication/login");
    });
  }

  const handlePasswordChange = (e) =>{
       setCurrentUser({...currentUser, password : e.target.value})
  }
  const handleFirstNameChange = (e) =>{
       setCurrentUser({...currentUser, first_name : e.target.value})
  }
  return (
    <CoverLayout image={bgImage}>
      <Card>
        {renderInfoSB}
        {renderErrorSB}
        <MDBox variant="gradient" bgColor="warning" borderRadius="lg" coloredShadow="success" mx={2} mt={-3} p={3} mb={1} textAlign="center" >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Complete Registration
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Please complete your registration information
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="label" label="Email" variant="standard" value={currentUser["username"]}
                        fullWidth readonly={true} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Name" variant="standard" value={currentUser["first_name"]}
                       onChange={handleFirstNameChange} fullWidth autoComplete="off" />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" variant="standard" value={currentUser["password"]}
                       fullWidth autoComplete="new-password" onChange={handlePasswordChange} />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox checked={checkBox} onChange={(e)=> setCheckBox(e.target.checked)} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="secondary" fullWidth onClick={handleClick}>
                complete registration
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default RegisterCover;
