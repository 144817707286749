

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import {useState} from "react";

function ProfilesList({ title, description, profiles, shadow, answerQuestionSetter }) {
  const renderProfiles = profiles.map(({ image, name, description, answer }) => (
    <MDBox key={name} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="h6" color="text">
          {description}
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
        <div>
            <MDButton component="a" onClick={answerQuestionSetter({name:true})} target="_blank" rel="noreferrer" style={{marginRight:3}} variant="gradient" color={"error"} >
                Yes
            </MDButton>
            <MDButton component="a" onClick={answerQuestionSetter({name:false})} target="_blank" rel="noreferrer" variant="gradient" color={"secondary"} >
                No
            </MDButton>
          </div>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="light" textTransform="capitalize">
          {description}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
