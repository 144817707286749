// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {useEffect, useMemo, useState} from "react";
import MDSnackbar from "../../../../../../components/MDSnackbar";
import LoadingOverlay from 'react-loading-overlay';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import YupPassword from 'yup-password';
import userService from "../../../../../../services/user.service";
import {doLogout} from "../../../../../../services/auth.service";

YupPassword(Yup);

const defaultInputValues = { currentPassword: '', newPassword: '', passwordConfirmation: ''};

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .min(6, 'Current Password must be at least 6 characters')
        .required('Current Password is required'),
    newPassword: Yup.string()
        .required('New Password is required')
        // .minNumbers(1, 'password must contain at least 1 number')
        // .minLowercase(1, 'password must contain at least 1 lower case letter')
        // .minUppercase(1, 'password must contain at least 1 upper case letter')
        .min(6, 'New Password must be at least 6 characters'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});
const ChangePassword = () => {
    const [values, setValues] = useState(defaultInputValues);

    const { register, handleSubmit, formState: { errors , isValid } } = useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const passwordRequirements = [
        "Password Required",
        "Min 6 characters",
    ];

    const renderPasswordRequirements = passwordRequirements.map((item, key) => {
        const itemKey = `element-${key}`;

        return (
          <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
              {item}
            </MDTypography>
          </MDBox>
        );
    });

    const handleChange = (value) => setValues(value);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );

    const handleClick = (e) => {
        e.preventDefault();
        setActiveLoading(true);
        userService.changePassword(values)
            .then((val)=>{
                console.log(val);
                setInfoSB(true);
                setActiveLoading(false);
                doLogout();
            })
            .catch((err) => {
                console.log(err);
                setErrorMessage(err.response.data.status);
                setErrorSB(true);
                setActiveLoading(false);
            });
    }

    const [activeLoading, setActiveLoading] = useState(false);
    return (
        <LoadingOverlay active={activeLoading} spinner text='Saving content...'>
            <Card id="change-password">
                {renderSB}
                <MDBox p={3}>
                <MDTypography variant="h5">Change Password</MDTypography>
              </MDBox>
                <MDBox component="form" pb={3} px={3} onSubmit={handleClick}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          name="currentPassword"
                          label="Current Password"
                          error={!!errors.currentPassword}
                          helperText={errors.currentPassword?.message}
                          value={values.currentPassword}
                          {...register("currentPassword", {required:true})}
                          onChange={(event) =>
                              handleChange({ ...values, currentPassword: event.target.value })}
                          inputProps={{ type: "password", autoComplete: "" }}
                        />

                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="New Password"
                          name="newPassword"
                          {...register('newPassword')}
                          error={!!errors.newPassword}
                          helperText={errors.newPassword?.message}
                          value={values.newPassword}
                          onChange={(event) =>
                              handleChange({ ...values, newPassword: event.target.value })}
                          inputProps={{ type: "password", autoComplete: "" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="passwordConfirmation"
                          label="Confirm New Password"
                          {...register('passwordConfirmation')}
                          error={!!errors.passwordConfirmation}
                          helperText={errors.passwordConfirmation?.message}
                          value={values.passwordConfirmation}
                          onChange={(event) =>
                              handleChange({ ...values, passwordConfirmation: event.target.value })}
                          inputProps={{ type: "password", autoComplete: "" }}
                        />
                      </Grid>
                    </Grid>
                    <MDBox mt={6} mb={1}>
                      <MDTypography variant="h5">Password requirements</MDTypography>
                    </MDBox>
                    <MDBox mb={1}>
                      <MDTypography variant="body2" color="text">
                        Please follow this guide for a strong password
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
                    <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                        {renderPasswordRequirements}
                    </MDBox>
                    <MDBox ml="auto">
                        <MDButton variant="gradient" color="dark" size="small" type={"submit"} disabled={!isValid}   >
                        update password
                        </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
            </Card>
        </LoadingOverlay>
    );
}

export default ChangePassword;
