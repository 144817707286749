import {useEffect, useMemo, useState} from "react";
import GHIconButton from "../../../../components/GHIconButton";
import CustomService from "../../../../services/custome.service";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {closureColumns, dayNames} from "../../../../components/Utils/HeaderColumns";

function loadDataTable(clickItem){

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(closureColumns);
    useEffect(()=> {
      CustomService.getClosures()
        .then((res) => {
          setRows(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [setColumns, setRows] )
    return {
        "columns": columns,
        "rows": rows !== null ? rows.map(function(element)  {
            console.log(element);
            return {
                  date: element.closure_date,
                  start: element.closure_start_time,
                  end: element.closure_end_time,
                  amenity: element.amenity.name,
                  status: element.status,
                  action: (
                    <GHIconButton  onClick={clickItem} id={element.id} title={"Delete"} icon={"delete"} size={"small"}  color="error" />
                  ),
                }
            }) : [],
    }
}

export default loadDataTable;