import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import MDButton from "../../../../../../components/MDButton";
import {useState} from "react";
import Switch from "@mui/material/Switch";





function AmenityPricing({validStep, uniqueId, oldValues, valueSetter, ...rest}) {

  const [freemium, setFreemium] = useState(true);
  const [price, setPrice] = useState(oldValues.price || 0);
  const [unit, setUnit] = useState(oldValues.unit || 'CAD $');

  const handlePriceChanged = (e) => {
      setPrice(e.text);

      valueSetter({...oldValues, price:e.target.value.toString(), unit:unit})
      // setFreeButtonColor("secondary")
  };
  if(oldValues.price === undefined || oldValues.price === null){
    valueSetter({...oldValues, price:parseFloat(0)});
  }
  return (
    <MDBox>
      <MDTypography variant="h5">Pricing</MDTypography>
      <MDBox mt={3}>

        <br/>
        <Grid container spacing={3}  >
          <Grid item xs={12} sm={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5} >
                <Switch checked={freemium}   onChange={() => {setFreemium(!freemium); setPrice(0)}} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button"  fontWeight="regular" color="text">
                  Is amenity Free ?
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField onKeyPress={handlePriceChanged} disabled={freemium} value={price} type="number"
                       label="Price" placeholder="99.00" inputProps={{min: 1, step: 0.1}} variant="standard"

            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
            <Autocomplete
              defaultValue="CAD $"
              disabled={freemium}
              options={["CAD $", "EUR €", "GBP £",  "USD $"]}
              onChange={(e,v)=>{
                setUnit(v.toString());
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default AmenityPricing;
