import * as React from 'react';
import {
    DataGrid,
    GridColDef,
    GridRowsProp,
    GridToolbar, GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton, GridToolbarQuickFilter
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from '@mui/x-data-grid-generator';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useState} from "react";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton sx={{ textTransform: "none" }} />
      <GridToolbarColumnsButton sx={{ textTransform: "none" }} />
      <GridToolbarQuickFilter sx={{ textTransform: "none" }} />
    </GridToolbarContainer>

  );
}

export default function DataGridView({columns, hideColumns, rows}) {
    return (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rows}
            rowHeight={40}
            disableRowSelectionOnClick
            columns={columns}
            components={{Toolbar: CustomToolbar,}}
            initialState={{
                columns: {
                  columnVisibilityModel: hideColumns,
                },
              }}
          />
        </div>
    );
}
