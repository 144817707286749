// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import SignInCover from "layouts/authentication/sign-in/cover";
import ResetCover from "layouts/authentication/reset-password/cover";
import ChangePasswordCover from "layouts/authentication/change-password/cover";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-2.jpg";
import Amenities from "./layouts/pages/amenities/list";
import AmenitiesBook from "./layouts/pages/amenities/book";
import InsertAmenity from "./layouts/pages/amenities/insert";
import AmenityType from "./layouts/pages/amenities/type";
import Users from "./layouts/pages/users/list";
import AmenityHoliday from "./layouts/pages/amenities/holiday";
import InsertUser from "./layouts/pages/users/insert";
// import Ads from "./layouts/pages/ads/insert";
import AdsList from "./layouts/pages/ads/list";
import Instances from "./layouts/pages/instances/list";
import RegisterCover from "./layouts/authentication/sign-up/cover";
import SignOut from "./layouts/authentication/singout";
import Countries from "./layouts/pages/countries";
import Provinces from "./layouts/pages/provinces";
import CurrencyList from "./layouts/pages/currencies";
import Clients from "./layouts/pages/clients";
import Cities from "./layouts/pages/cities";
import Units from "./layouts/pages/units";
import {doLogout, getUserAvatar, getUserIsLogin, getUserNickName, logout} from "./services/auth.service";
import Elevators from "./layouts/pages/elevators/list";
import Events from "./layouts/pages/events";
import Parking from "./layouts/pages/parking/list";
import {Navigate} from "react-router-dom";
import Buildings from "./layouts/pages/buildings";
import AmenityClosure from "./layouts/pages/amenities/closures";
import ParkingLevel from "./layouts/pages/parking/level";
import ParkingReserve from "./layouts/pages/parking/reserve";
import BookElevator from "./layouts/pages/elevators/book";
import KeysList from "./layouts/pages/key/list";
import BorrowKey from "./layouts/pages/key/borrow";
import PackageList from "./layouts/pages/package/list";
import ParkingList from "./layouts/pages/parking/list";
import ParkingLevelSpot from "./layouts/pages/parking/spot";
import EventType from "./layouts/pages/events/eventtype";
import EventList from "./layouts/pages/events";
import Traders from "./layouts/pages/key/trader";
import KeysTypeList from "./layouts/pages/key/type";
import SpotTypeList from "./layouts/pages/parking/spot_type";
import Staffs from "./layouts/pages/users/staffs";
import TestPage from "./layouts/pages/users/test";
import MyPackageList from "./layouts/pages/package/list/my_package";
import MyEventList from "./layouts/pages/events/myevents";
import ShopList from "./layouts/pages/shops";
import OrdersList from "./layouts/pages/shops/myorders";
import PartyRooms from "./layouts/pages/partyrooms/list";
import BookPartyRoom from "./layouts/pages/partyrooms/book";
import AfterPayment from "./layouts/pages/after_payment";
import PartyRoomBookingList from "./layouts/pages/partyrooms/booking_list";


const TrueValue = ({component}) => {
  if (getUserIsLogin()){
    return component;
  }else{
    // window.location.reload();
     logout();
     // console.log("logout123")
    return <Navigate to={"/authentication/login?timeout=true"} />
  }

};

const routes = [
  {
    type: "collapse",
    name: getUserNickName(),
    key: "brooklyn-alice",
    perms: ["all"],
    visible: true,
    icon: <MDAvatar src={getUserAvatar()} alt={getUserNickName()} size="sm" />,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        route: "/profile",
        component: <TrueValue component={<Settings />}  />,
        perms: ["all"],
        visible: true,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/logout",
        component: <TrueValue component={<SignOut />}  />,
        perms: ["all"],
        visible: true,
      },
      {
        name: "ResetPassword",
        key: "reset_password",
        route: "/authentication/reset_password",
        component: <ResetCover />,
        perms: ["all"],
        visible: false,
      },
      {
        name: "ChangePassword",
        key: "reset_password_confirmation",
        route: "/authentication/change_password",
        component: <ChangePasswordCover />,
        perms: ["all"],
        visible: false,
      },
      {
        name: "Login",
        key: "login",
        route: "/authentication/login",
        component: <SignInCover />,
        perms: ["all"],
        visible: false,
      },
      {
        name: "RegisterData",
        key: "register",
        route: "/authentication/register",
        component: <RegisterCover />,
        perms: ["all"],
        visible: false,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    perms: ["all"],
    visible: true,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Dashboard",
        key: "dashboards",
        route: "/dashboards",
        component: <TrueValue component={<Analytics />}  />,
        perms: ["all"],
        visible: true,
      },
    ],
  },
  { type: "title", title: "Admin", key: "title-pages" },
  {
    type: "collapse",
    name: "Shop",
    icon: <Icon fontSize="medium">shop</Icon>,
    key: "shop",
    visible: false,
    perms: ["all"],
    collapse: [
      {
        name: "Shop",
        key: "shop",
        route: "/shop",
        perms: ["all"],
        visible: true,
        component: <TrueValue component={<ShopList />}/>,
      },
      {
        name: "Shop",
        key: "shop",
        route: "/shop/order",
        perms: ["all"],
        visible: false,
        component: <TrueValue component={<AfterPayment />}/>,
      },
      {
        name: "My Orders",
        key: "orders",
        route: "/orders",
        perms: ["all"],
        visible: true,
        component: <TrueValue component={<OrdersList />}/>,
      },
    ]
  },
  { type: "title", title: "Admin", key: "title-pages" },
  {
    type: "collapse",
    name: "Party Rooms",
    icon: <Icon fontSize="medium">room</Icon>,
    key: "PartyRooms",
    visible: true,
    perms: ["all"],
    collapse: [
      {
        name: "Book Party Room",
        key: "BookPartyRoom",
        route: "/BookPartyRoom",
        perms: ["all"],
        visible: true,
        component: <TrueValue component={<BookPartyRoom />}  />,
      },
      {
        name: "Party Rooms",
        key: "PartyRoomList",
        route: "/PartyRoomList",
        perms: ["all"],
        visible: true,
        component: <TrueValue component={<PartyRooms />}  />,
      },
      {
        name: "Booking List",
        key: "BookingList",
        route: "/PartyRoom/BookingList",
        perms: ["staff", "admin", "super_admin"],
        visible: true,
        component: <TrueValue component={<PartyRoomBookingList />}  />,
      },
    ]
  },
  { type: "title", title: "Admin", key: "title-pages" },
  {
    type: "collapse",
    name: "Amenities",
    icon: <Icon fontSize="medium">pool</Icon>,
    key: "amenities",
    visible: true,
    perms: ["all"],
    collapse: [
      {
        name: "Booking",
        key: "amenities",
        route: "/amenities/book",
        perms: ["all"],
        visible: false,
        component: <TrueValue component={<AmenitiesBook />}  />,
      },
      {
        name: "Amenities",
        key: "amenities",
        route: "/amenities",
        perms: ["all"],
        visible: true,
        component: <TrueValue component={<Amenities />}  />,
      },
      {
        name: "Amenities",
        key: "amenities",
        route: "/amenities/insert",
        perms: ["staff", "admin", "super_admin"],
        visible: false,
        component: <TrueValue component={<InsertAmenity />}  />,
      },
      {
        name: "Amenity Types",
        key: "types",
        route: "/amenities/types",
        component: <TrueValue component={<AmenityType />}  />,
        perms: ["staff", "admin", "super_admin"],
        visible: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">person</Icon>,
    perms: ["staff", "admin", "super_admin"],
    visible: true,
    collapse: [
      {
        name: "Test Page",
        key: "TestPage",
          route: "/users/TestPage",
        component: <TrueValue component={<TestPage/>}/>,
        visible: false,
        perms: ["all"],
      },
      {
        name: "Members",
        key: "users",
        route: "/users",
        perms: ["staff", "admin", "super_admin"],
        visible: true,
        component: <TrueValue component={<Users />}  />,
      },
      {
        name: "Staffs",
        key: "Staffs",
        route: "/Staffs",
        perms: ["admin", "super_admin"],
        visible: true,
        component: <TrueValue component={<Staffs />}  />,
      }
    ],
  },
  {
    type: "collapse",
    name: "Parking",
    icon: <Icon fontSize="medium">local_parking</Icon>,
    key: "Parking",
    perms: ["all"],
    visible: false,
    collapse: [
      {
        name: "Reserve Parking",
        key: "ReserveParking",
        route: "/parking/ReserveParking",
        component: <TrueValue component={<ParkingReserve/>}/>,
        visible: true,
        perms: ["all"],
      },
      {
        name: "Parking",
        key: "parking",
        route: "/parking",
        component: <TrueValue component={<ParkingList/>}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      },
      {
        name: "Parking Level",
        key: "level",
        route: "/parking/level",
        component: <TrueValue component={<ParkingLevel/>}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      },
      {
        name: "Parking Spot",
        key: "spot",
        route: "/parking/level/spot",
        component: <TrueValue component={<ParkingLevelSpot/>}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      },
      {
        name: "Spot Type",
        key: "SpotType",
        route: "/parking/SpotType",
        component: <TrueValue component={<SpotTypeList/>}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      }
    ]
  },
  {
    type: "collapse",
    name: "Elevator",
    icon: <Icon fontSize="medium">elevator</Icon>,
    key: "elevator",
    perms: ["all"], // all
    visible: false,
    collapse: [
      {
        name: "BookElevator",
        key: "BookElevator",
        route: "/elevator/BookElevator",
        component: <TrueValue component={<BookElevator/>}/>,
        visible: true,
        perms: ["all"],
      },
      {
        name: "Elevator List",
        key: "elevator_list",
        route: "/elevator/elevator_list",
        component: <TrueValue component={<Elevators/>}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      },
    ]
  },
  {
    type: "collapse",
    name: "Keys",
    icon: <Icon fontSize="medium">key</Icon>,
    key: "keys",
    perms: ["staff", "admin", "super_admin"],
    visible: false,
    collapse: [
      {
        name: "Borrow Key",
        key: "Borrow",
        route: "/Keys/Borrow",
        component: <TrueValue component={<BorrowKey/>}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      },
      {
        name: "Keys",
        key: "Keys",
        route: "/Keys",
        component: <TrueValue component={<KeysList />}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      },
      {
        name: "Key Type",
        key: "KeyType",
        route: "/KeyType",
        component: <TrueValue component={<KeysTypeList />}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      },
      {
        name: "Contractor",
        key: "Contractor",
        route: "/Keys/Contractor",
        component: <TrueValue component={<Traders />}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      }
    ]
  },
  {
    type: "collapse",
    name: "Parcel",
    icon: <Icon fontSize="medium">local_shipping</Icon>,
    key: "parcels",
    perms: ["all"], // for all
    visible: false,
    collapse: [
      {
        name: "My Parcel",
        key: "MyParcel",
        route: "/MyParcel",
        component: <TrueValue component={<MyPackageList />}/>,
        visible: true,
        perms: ["all"], // for all
      },
      {
        name: "Parcel List",
        key: "Parcels",
        route: "/Parcels",
        component: <TrueValue component={<PackageList/>}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      }
    ]
  },
  {
    type: "collapse",
    name: "Events",
    icon: <Icon fontSize="medium">event</Icon>,
    key: "events",
    perms: ["all"], // for all
    visible: false,
    collapse: [
      {
        name: "My Events",
        key: "MyEvent",
        route: "/event/MyEvent",
        component: <TrueValue component={<MyEventList/>}/>,
        visible: true,
        perms: ["all"],
      },
      {
        name: "Event Type",
        key: "EventType",
        route: "/event/EventType",
        component: <TrueValue component={<EventType />}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      },
      {
        name: "Event List",
        key: "EventList",
        route: "/event/EventList",
        component: <TrueValue component={<EventList/>}/>,
        visible: true,
        perms: ["staff", "admin", "super_admin"],
      }
    ]
  },
  {
    type: "collapse",
    name: "Clients",
    icon: <Icon fontSize="medium">domain_add</Icon>,
    key: "clients",
    perms: ["staff", "admin", "super_admin"],
    visible: true,
    collapse: [
      {
        name: "Clients",
        key: "clients",
        route: "/clients",
        component: <TrueValue component={<Clients />}  />,
        visible: true,
        perms: ["admin", "super_admin"],
      },
      {
        name: "Buildings",
        key: "buildings",
        route: "/clients/buildings",
        perms: [ "staff", "admin", "super_admin"],
        visible: true,
        component: <TrueValue component={<Buildings />}  />,
      },
      {
        name: "Units",
        key: "units",
        route: "/clients/units",
        perms: ["staff", "admin", "super_admin"],
        visible: true,
        component: <TrueValue component={<Units />}  />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Instances",
    icon: <Icon fontSize="medium">apps</Icon>,
    key: "instances",
    perms: ["super_admin"],
    visible: false,
    collapse: [
      {
        name: "Instance List",
        key: "instances",
        route: "/instances",
        component:  <TrueValue component={<Instances />}  />,
        visible: true,
        perms: ["super_admin"],
      }
    ],
  },
  {
    type: "collapse",
    name: "Administrator",
    icon: <Icon fontSize="medium">settings</Icon>,
    key: "utils",
    perms: ["staff", "super_admin", "admin"],
    visible: true,
    collapse: [
      {
        name: "Countries",
        key: "countries",
        route: "/utils/countries",
        component:  <TrueValue component={<Countries />}  />,
        visible: true,
        perms: ["super_admin"],
      },
      {
        name: "Provinces",
        key: "provinces",
        route: "/utils/provinces",
        component: <TrueValue component={<Provinces />}  />,
        visible: true,
        perms: ["super_admin"],
      },
      {
        name: "Cities",
        key: "cities",
        route: "/utils/cities",
        component: <TrueValue component={<Cities />}  />,
        visible: true,
        perms: ["super_admin"],
      },
      {
        name: "Holidays",
        key: "holiday",
        route: "/utils/holiday",
        component: <TrueValue component={<AmenityHoliday />}  />,
        visible: true,
        perms: ["staff", "super_admin", "admin"],
      },
      {
        name: "Closure",
        key: "closures",
        route: "/utils/closures",
        component: <TrueValue component={<AmenityClosure />}  />,
        visible: true,
        perms: ["staff", "super_admin", "admin"],
      },
      {
        name: "Currencies",
        key: "currency",
        route: "/utils/currency",
        component: <TrueValue component={<CurrencyList />}  />,
        visible: false,
        perms: ["super_admin"],
      },
      {
        name: "Ads",
        key: "ads",
        route: "/utils/ads",
        component: <TrueValue component={<AdsList />}  />,
        visible: false,
        perms: ["admin", "super_admin"],
      },
    ],
  },
];

export default routes;
