import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getEventTypes = () => {
  return axios.get(`${getPrivateURL()}events/type/`, {withCredentials:true} );
};
export const getEvents = () => {
  return axios.get(`${getPrivateURL()}events/`, {withCredentials:true} );
};
export const getMyEvents = () => {
  return axios.get(`${getPrivateURL()}my_events/`, {withCredentials:true} );
};
export const getEvent = (Event_id) => {
  return axios.get(`${getPrivateURL()}events/` + Event_id, {withCredentials:true} );
};
export const insertEvent = (values) => {
  return axios.post(`${getPrivateURL()}events/`, values, {withCredentials:true} );
};
export const insertEventType = (values) => {
  return axios.post(`${getPrivateURL()}events/type/`, values, {withCredentials:true} );
};
export const updateEvent = (Event_id, data ) => {
  return axios.put(`${getPrivateURL()}events/${Event_id}`, data, {withCredentials:true});
};
export const removeEvent = (Event_id ) => {
  return axios.delete(`${getPrivateURL()}events/${Event_id}`, {withCredentials:true});
};
export const disableEvent = (Event_id ) => {
  return axios.patch(`${getPrivateURL()}events/${Event_id}`, {withCredentials:true});
};

const EventsService = {
  getMyEvents,
  getEvents,
  getEventTypes,
  getEvent,
  insertEvent,
  insertEventType,
  updateEvent,
  removeEvent,
  disableEvent,
};
export default EventsService;
