import {useEffect, useMemo} from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";
import {categoriesList, durationList, gapList, guestList, minorList} from "../../static_data";
import * as Yup from "yup";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import MDButton from "../../../../../../components/MDButton";
import MDAvatar from "../../../../../../components/MDAvatar";
import {Image} from "react-bootstrap";
import authService, {getUserAvatar} from "../../../../../../services/auth.service";
import {useRef} from "react";
import LoadingOverlay from "react-loading-overlay";

import insert from "assets/images/insert.png";
import userService from "../../../../../../services/user.service";
import amenitiesService from "../../../../../../services/amenities.service";
function AmenityMedia({validStep, uniqueId, oldValues, valueSetter, ...rest}) {
    validStep(false);
    // console.log("image :", oldValues.image)
    const [currentFileUrl, setCurrentFileUrl] = useState( typeof oldValues.image === "string" ? oldValues.image : insert);
     const onFileChange = event => {
        const file = event.target.files[0];
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            // Define an array of allowed image file extensions
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];

            // Check if the file extension is in the allowedExtensions array
            if (allowedExtensions.includes(fileExtension)) {
                // File is an image
                // setCurrentFile(event.target.files[0]);
                const objectUrl = URL.createObjectURL(file)
                setCurrentFileUrl(objectUrl)
                valueSetter({...oldValues, image:file})
                validStep(true);
                // onFileUpload(event.target.files[0])
                console.log('File is an image:', fileName);
            } else {
                // File is not an image
                alert("image file is not valid")
                console.log('File is not an image:', fileName);
                // You can display an error message or perform other actions for non-image files
            }
        }

    };
     validStep(oldValues.image !== undefined && oldValues.image !== null);

    const myRef = useRef(null);
    const clickElement = (ref) => {
      ref.current.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      );
    };


    const [activeLoading, setActiveLoading] = useState(false);
    const [values, setValues] = useState(null);
    // useEffect(() => { validStep(values !== null && values !== undefined); });
    const handleAddFile = (files) => { setValues(files); validStep(true); }
    return (
        <MDBox>
          <MDTypography variant="h5">Media</MDTypography>
          <MDBox mt={3} variant={"gradient"} sx={{"textAlign":"center"}}>

            <MDBox mb={1} ml={0.5} lineHeight={0}  display="inline-block">

              <input type={"file"} accept={"image/*"} style={{"display":"none"}} onChange={onFileChange} ref={myRef} />
                <LoadingOverlay active={activeLoading} spinner text='Saving content...'>
                    <Image width={"512"} height={"256"} src={currentFileUrl}  />
                </LoadingOverlay>

                <br/>
              <MDButton sx={{"margin":"12px"}} size={"medium"} color={"primary"} onClick={() => clickElement(myRef)}
                        circular={true} >Upload Image </MDButton>

              {/*<MDButton size={"medium"} color={"secondary"} circular={true} >Remove Image</MDButton>*/}
            </MDBox>


            {/*}*/}
          </MDBox>
        </MDBox>
    );
}

export default AmenityMedia;
