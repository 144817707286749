import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getClients = (instance_id) => {
  if (instance_id === undefined || instance_id === ""){
    return axios.get(`${getPrivateURL()}clients/`)
  }else{
    return axios.get(`${getPrivateURL()}clients/${instance_id}`, {withCredentials:true})
  }
};
export const getBuildingUnits = (building_id) =>  axios.get(`${getPrivateURL()}units/${building_id}/`);
export const getUnit = (unit_id) =>  axios.get(`${getPrivateURL()}units_info/${unit_id}/`);
export const getClientsBuilding = (client_id) =>  axios.get(`${getPrivateURL()}buildings/${client_id}/`);
export const getBuildings = () =>  axios.get(`${getPrivateURL()}buildings/`);
export const getClientMembers = (client_id) => axios.get(`${getPrivateURL()}clients/${client_id}/users/`);
export const getUnitMembers = (unit_id) => axios.get(`${getPrivateURL()}unit_members/${unit_id}/`);
export const getBuildingMembers = (building_id) => axios.get(`${getPrivateURL()}building_members/${building_id}/`);
export const getClientAmenities = (client_id) => axios.get(`${getPrivateURL()}clients/${client_id}/amenities/`);

export const insertClient = (values) => {
  return axios.post(`${getPrivateURL()}clients/`, values, {withCredentials:true} );
};
export const updateUnit = (unit_id, data ) => {
  return axios.put(`${getPrivateURL()}units/${unit_id}/`, data );
};
export const insertUnit = (data ) => {
  for (const dataKey in data) {
    data[dataKey] = data[dataKey].toString();
  }
  return axios.post(`${getPrivateURL()}units/`, data );
};
export const insertBuilding = (data ) => {
  for (const dataKey in data) {
    data[dataKey] = data[dataKey].toString();
  }
  return axios.post(`${getPrivateURL()}buildings/`, data, {withCredentials:true});
};

export const removeMemberFromBuilding = (data ) => {
  for (const dataKey in data) {
    data[dataKey] = data[dataKey].toString();
  }
  return axios.delete(`${getPrivateURL()}buildings/`, data, {withCredentials:true});
};
export const removeBuilding = (building_id ) => {
  return axios.delete(`${getPrivateURL()}buildings/${building_id}/`, {withCredentials:true});
};
export const removeClient = (client_id ) => {
  return axios.delete(`${getPrivateURL()}clients/${client_id}`, {withCredentials:true});
};

export const disableClient = (client_id ) => {
  return axios.patch(`${getPrivateURL()}clients/${client_id}`, {withCredentials:true});
};

const ClientsService = {
  insertClient,
  removeClient,
  disableClient,
  insertUnit,
  insertBuilding,
  getClients,
  getBuildings,
  getClientsBuilding,
  getBuildingUnits,
  getUnitMembers,
  getBuildingMembers,
  getClientMembers,
  getClientAmenities,


  getUnit,
  updateUnit,


  removeBuilding,
  removeMemberFromBuilding,
};
export default ClientsService;
