function validateForm(validationRole, source, valueOfElement) {
    console.log(source + " -----> " + valueOfElement)
    if(valueOfElement !== null && "is_required" in validationRole && validationRole["is_required"]){
        let error = valueOfElement.toString().length === 0;
        let errorMessage = error ? `${source} is required` : "";
        if (error){
            return {
                "error":error,
                "errorMessage":errorMessage,
            }
        }
    }
    if(valueOfElement !== null && "min" in validationRole && parseInt(validationRole["min"]) > 0){
        let minLength = parseInt(validationRole["min"])
        let error = valueOfElement.toString().length < minLength;
        let errorMessage = error ? `${source} min Length is ${minLength}` : "";
        if (error){
            return {
                "error":error,
                "errorMessage":errorMessage,
            }
        }
    }
    if(valueOfElement !== null && "max" in validationRole && parseInt(validationRole["max"]) > 0){
        let maxLength = parseInt(validationRole["max"])
        let error = valueOfElement.toString().length > maxLength;
        let errorMessage = error ? `${source} max Length is ${maxLength}` : "";
        if (error){
            return {
                "error":error,
                "errorMessage":errorMessage,
            }
        }
    }
    return {
        "error":false,
        "errorMessage":"",
    }
}

export default validateForm;