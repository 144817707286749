import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {getUserRole} from "../../../../services/auth.service";
import ClientsService from "../../../../services/clients.service";
import {elevatorColumns, parcelColumns, userColumns} from "../../../../components/Utils/HeaderColumns";
import GHIconButton from "../../../../components/GHIconButton";
import MDBadge from "../../../../components/MDBadge";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../../components/MDBox";
import {modalStyleClients, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import TextField from "@mui/material/TextField";
import {Link} from "react-router-dom";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import PackagesService from "../../../../services/packages.service";
import {BasicDateRangePicker, BasicDateTimePicker, BasicTimePicker} from "../../elevators/components";
import ElevatorsService from "../../../../services/elevators.service";

function PackageList() {

    const defaultInputValues = {  description: ""};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        description: Yup.string()
            .min(10, 'Parcel Description must be at least 10 characters')
            .required('Parcel Description is required'),
    });

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [units, setUnits] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadClients();
        loadPackage();
    }, [setClients, setBuildings, setUnits]);

    const loadClients = () => {
        ClientsService.getClients()
        .then((res) => {
            if(res.data !== undefined && res.data !== null) {
                setClients(res.data);
                if (res.data.length > 0){
                    loadBuilding(res.data[0].id);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadBuilding = (client_id) => {
        ClientsService.getClientsBuilding(client_id)
        .then((res) => {
            if(res.data !== undefined && res.data !== null) {
                setBuildings(res.data);
                if(res.data[0].length > 0){
                    loadUnit(res.data[0].id);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadUnit = (building_id) => {
        ClientsService.getBuildingUnits(building_id)
        .then((res) => {
            if(res.data !== undefined && res.data !== null) {
                setUnits(res.data);
                // loadProvince(res.data[0]);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }



    const [packageTableData, setPackageTableData] =
                        useState({"columns" : parcelColumns, "rows":[]});

    const loadPackage = () => {
        setPackageTableData({"columns" : parcelColumns, "rows":[]})
        PackagesService.getPackages()
        .then((res) => {
            console.log(res.data);
            setPackageTableData( {
                "columns": parcelColumns,
                "rows": res.data.map(function(element) {
                        return {
                              id: element.id,
                              code: element.code,
                              unit: element.unit,
                              client: element.client,
                              building: element.building,
                              deliver_to: element.deliver_to,
                              time: element.time,
                              description: element.description  ,
                              receive_date: element.receive_date  ,
                              deliver_date: element.deliver_date  ,
                              // status: element.status  ,
                              status: <MDBadge badgeContent={element.status} size="sm" container
                                       color={element.status === "received" ? "info" : "success"} />,

                              action: (
                                  <div>

                                      {element.status === "received" &&
                                          <GHIconButton onClick={handleClickItem} id={element.id} action={"deliver"}
                                                    rowName={element.client_name} title={"Deliver"} color={"success"}
                                                    icon={"done_all"} size={"medium"} />}
                                      {/*<GHIconButton onClick={handleClickItem} id={element.id} action={"expire"}*/}
                                      {/*              rowName={element.client_name} title={"Expire"} color={"warning"}*/}
                                      {/*              icon={"hourglass_bottom"} size={"medium"} />*/}
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"delete"}
                                                    rowName={element.client_name} title={"Remove"} color={"error"}
                                                    icon={"delete"} size={"medium"} />

                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setEditMode(false);
                setOpenInsertModel(true);
                break;
            case "edit" :
                setEditMode(true);
                handleOpenDialog(model);
                break
            case "delete" :
                handleOpenDialog(model);
                break;
            case "deliver":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            PackagesService.removePackage(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }if (dialogAction === "deliver") {
            PackagesService.deliverPackage(clickedItemId, deliverTo)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }
    }
    const loadSinglePackage = (package_id) =>{
        // alert(clickedItemId)
        if (package_id > 0){
             PackagesService.getPackage(package_id)
            .then((result)=>{
                loadBuilding(result.data.client.id)
                setValues({
                    id: package_id,
                    elevatorName: result.data.name,
                    height: result.data.height,
                    weight: result.data.weight,
                    client: {id:result.data.client.id, client_name:result.data.client.client_name},
                    building: {id:result.data.building.id, name:result.data.building.building_name},
                });
                setOpenInsertModel(true);
            }).catch((reason) =>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
        }

    };


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                setEditMode(true)
                loadSinglePackage(rowId)
                break;
            case "deliver":
                handleOpen(elem,"deliver");
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");
    const [deliverTo , setDeliverTo] = useState("")
    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon> { dialogAction + " confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to {dialogAction} this item ?<br/><br/>
                  {dialogAction === "deliver" && <MDInput label={"Deliver To"} value={deliverTo} fullWidth onChange={(e)=>setDeliverTo(e.target.value)} />}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){

            // console.log(values)
            let dataToSend = {
                "description": values.description.toString(),
                "receive_date": values.receive_date.toString(),
                "client_id": values.client.id,
                "building_id": values.building.id,
                "unit_id": values.unit.id,
            }
            // console.log(dataToSend);
            if (editMode){
                PackagesService.updatePackage(values.id, dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
            }else{
                PackagesService.insertPackage(dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
            }
        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [editMode, setEditMode] = useState(false);
    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleClients}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Parcel": "Insert New Parcel"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={clients}
                          value={values.client}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.client_name ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, client: value });
                            loadBuilding(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.client}
                                        helperText={errors.client?.message}
                                        label="Client"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("client")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={buildings}
                          value={values.building}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, building: value });
                            loadUnit(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.building}
                                        helperText={errors.building?.message}
                                        label="Building"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("building")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={units}
                          value={values.unit}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.unit ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, unit: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.unit}
                                        helperText={errors.unit?.message}
                                        label="Unit"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("unit")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BasicDateTimePicker label={"Receive Date"}
                                         onChange={(v)=>{
                                             let date = "20"+('0' + v.year()).slice(-2) + "-" + ('0' + (v.month()+1)).slice(-2) + "-" + ('0' + (v.date())).slice(-2)
                                             let time = ('0' + v.hour()).slice(-2) + ":" + ('0' + v.minute()).slice(-2)
                                             handleChange({ ...values, receive_date:date + "T" + time })
                                         }} />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          multiline={true}
                          rows={3}
                          aria-multiline={true}
                          error={!!errors.description}
                          helperText={errors.description?.message}
                          value={values.description}
                          {...register("description")}
                          onChange={(event) => {
                            handleChange({ ...values, description: event.target.value })
                          }}
                        label="Parcel Description "
                        placeholder="Bag From Parsing"  />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderUserListModal = () => {

        return <Modal open={openUserModel} onClose={(e)=>{handleClose("user")}} color={"warning"} >
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5">{selectedClientTitle} Client Member's List</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                 <Grid pb={2}  >
                    <MDButton color={"info"} size={"large"} variant={"gradient"}
                              to={"/users/insert?client_id=" + clickedItemId} component={Link} target={"_blank"}  >
                        <Icon>add</Icon>Add Member To {selectedClientTitle}
                    </MDButton>
                 </Grid>
                <Grid container spacing={3}>

                    <DataTable table={userTableData} showTotalEntries canSearch />
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };

    const renderPackageList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderUserListModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                  <MDButton variant="gradient" color="warning" size="large"
                            disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>
                    new Parcel
                  </MDButton>
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={packageTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderPackageList()}
          <Footer />
        </DashboardLayout>
    );


}

export default PackageList;