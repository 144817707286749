import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BookingCard from "examples/Cards/BookingCard";
// import Modal from 'react-modal';

// Images
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import {useState, useEffect, useMemo, useRef} from "react";
import MDButton from "../../../../components/MDButton";
import { saveAs } from 'file-saver';
import Modal from "@mui/material/Modal";
import {modalStyleUser} from "../../../../components/Utils/ModalStyle";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import {TextField} from "@mui/material";
import Switch from "@mui/material/Switch";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import AuthService from "../../../../services/auth.service";
import InstancesService from "../../../../services/instances.service";
import ClientsService from "../../../../services/clients.service";
import userService from "../../../../services/user.service";
import {SnackShower} from "../../../../components/Utils/Tools";
import {useSearchParams} from "react-router-dom";

function InsertUserComponent({settings, setSettings, snackBarSetting, setSnackBarSetting, ...ref}) {
    const defaultInputValues = { email: '', phone: '', firstName: '', lastName: '',  mainPerson: false}
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [buttonIcon, setButtonIcon] = useState("");
    const [currentFile, setCurrentFile] = useState(null);
    // const [isValid, setIsValid] = useState(null);
    const [values, setValues] = useState(defaultInputValues);

    let userRole = AuthService.getUserRole();

    const [instanceList , setInstanceList] = useState([]);
    const [selectedInstance , setSelectedInstance] = useState({});
    const [clientList , setClientList] = useState([]);
    const [selectedClient , setSelectedClient] = useState({});
    const [buildingList , setBuildingList] = useState([]);
    const [selectedBuilding , setSelectedBuilding] = useState({});
    const [unitList , setUnitList] = useState([]);
    const [selectedUnit , setSelectedUnit] = useState({});
    const loadInstance = () => {
        InstancesService.getInstances()
            .then((val)=>{
                setInstanceList(val.data);
                if(val.data.length > 0){
                    handleChange({...values, instance: val.data[0]});
                    setSelectedInstance(val.data[0]);
                    loadClient(val.data[0].id)
                }
            }).catch((e)=>{ console.log() })
    }
    const loadClient = (instance_id) => {
        ClientsService.getClients(instance_id)
            .then((val)=>{
                setClientList(val.data);
                if(val.data.length > 0){
                    handleChange({...values, client:val.data[0]});
                    setSelectedClient(val.data[0]);
                    loadBuilding(val.data[0].id);
                }
            }).catch((e)=>{ console.log() });
    }
    const loadBuilding = (client_id) => {
        ClientsService.getClientsBuilding(client_id)
            .then((val)=>{
                setBuildingList(val.data);
                if(val.data.length > 0){
                    handleChange({...values, building:val.data[0]});
                    setSelectedBuilding(val.data[0]);
                    loadUnits(val.data[0].id);
                }
            }).catch((e)=>{ console.log() });
    }
    const loadUnits = (building_id) => {
        ClientsService.getBuildingUnits(building_id)
            .then((val)=>{
                setUnitList(val.data);
                console.log(val.data)
                // alert('loadUnits');
                if(val.data.length > 0){
                    handleChange({...values, unit:val.data[0]});
                    setSelectedUnit(val.data[0]);
                }

            }).catch((e)=>{ console.log() });
    }
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        // console.log("Hello");
        const clientId = parseInt(searchParams.get('client_id'));
        const showInsert = Boolean(searchParams.get('insert'));
        // alert(showInsert)
        if (showInsert){
            setSettings({...settings, show: true})
        }
       if(userRole === "super_admin"){
            if(instanceList.length === 0){
                loadInstance();
            }
       }else if(userRole === "admin"){
            if(clientList.length === 0){
                loadClient();
            }
       }else if(userRole === "staff"){
            if(clientList.length === 0){
                loadClient();
            }
       }
    },[setInstanceList,setClientList, setUnitList, values, setValues]);


    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Email is not valid").required('Email is required'),
        phone: Yup.string(),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string(),
        unit: Yup.string(),
        client: Yup.string(),
        instance: Yup.string(),
        building: Yup.string(),
    });

    const { register, handleSubmit, formState: { errors ,isValid } } =  useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => setValues(value)
    const handleChange2 = (key, value) => {
        console.log(key, value);
        if (key === 'instance'){
            setSelectedInstance(value);
        }
        if (key === 'client'){
            setSelectedClient(value);
        }
        if (key === 'building'){
            setSelectedBuilding(value);
        }
        if (key === 'unit'){
            // setUnitList({...unitList, {id:0, "unit":value}})
            setSelectedUnit(value);
        }
    }

    // setInterval(()=>{setIsValid(validationSchema.isValidSync(values))}, 1000);

    const myRef = useRef(null);
    const onFileChange = event => { setCurrentFile(event.target.files[0]); };

    const onFileUpload = () => {
        setUploadInProgress(true);
        setButtonIcon("sync");
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append( "myFile", currentFile, currentFile.name );
        formData.append( "client", selectedClient.id );

        // Details of the uploaded file
        console.log(formData);
        // setInterval(1000, function finish() { })

        userService.createBulk(formData)
            .then((val)=>{
                console.log(val);
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                window.location.reload(true);
            }).catch((e)=>{
                console.log(e);
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: e.toString()})
            })
      // Request made to the backend api
      // Send formData object
      // post("api/uploadfile", formData);
    };

    const handleDownloadCsv = (e) => {saveAs(process.env.PUBLIC_URL + "/resource/template.csv", "template.csv");};


    const clickElement = (ref) => {
      ref.current.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      );
    };

    const onClickSaveData = (e) => {
        e.preventDefault();
        // alert("clicked, " + isValid);
        if (isValid){
            values["is_main"] = values["mainPerson"].toString();
            values["client"] = selectedClient.id.toString();
            // values["instance"] = selectedInstance.id.toString();
            values["building"] = selectedBuilding.id.toString();
            if (typeof selectedUnit === 'string' ){
                values["unit"] = selectedUnit.toString();
            }else{
                values["unit"] = selectedUnit.id.toString();
            }
            values["role"] = settings.showBulk ? "3": "4"
            // console.log(values);
            userService.createSingle(values)
                .then((val)=>{
                    console.log(val);
                    //
                    setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                    window.location.reload(true);
                }).catch((reason)=>{
                    console.log(reason.response.data.status);
                    setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: reason.response.data.status})
                })
            }

        // console.log(values);
    }

    return (
        <Modal open={settings.show}   color={"warning"} onClose={(e)=>{setSettings({...settings, show: false})}}>
          <MDBox sx={modalStyleUser}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3} pb={2}>
                <MDTypography variant="h5">Insert {settings.showBulk ? "User": "Staff"} </MDTypography> {/*In {settings.title}*/}
              </MDBox>
              <MDBox component="form" pb={3} >
                  <Grid container textAlign={"center"} >
                    <Grid container spacing={2} pt={2} px={2} xs={12} md={12}  >
                        {(userRole === "super_admin") ? <Grid item xs={12} md={3}>
                            <Autocomplete
                                disableClearable
                                  options={!instanceList ? [] : instanceList}
                                  value={selectedInstance}
                                  getOptionLabel={(option)=> option.name || "None"}
                                  onChange={(e,v)=>{
                                      handleChange2('instance', v);
                                      loadClient(v.id)
                                  }}
                                  renderInput={(params) =>
                                  <MDInput {...params} label={"Select Instance"}
                                           error={!!errors.instance}
                                           helperText={errors.instance?.message} />}
                            />
                        </Grid>: ""}
                        {(userRole === "staff" || userRole === "admin" || userRole === "super_admin") ? <Grid item xs={12} md={3}>
                            <Autocomplete
                                disableClearable
                                  options={!clientList ? [] : clientList}
                                  value={selectedClient}
                                  getOptionLabel={(option)=>option.client_name || "None"}
                                  onChange={(e,v)=>{
                                      handleChange2('client', v);
                                      loadBuilding(v.id)
                                  }}
                                  renderInput={(params) =>
                                  <MDInput {...params} label={"Select Client"}
                                           error={!!errors.client}
                                           helperText={errors.client?.message} />}
                            />
                        </Grid>: ""}
                        {(userRole === "staff" || userRole === "admin" || userRole === "super_admin") ? <Grid item xs={12} md={3}>
                            <Autocomplete
                                  disableClearable
                                  options={!buildingList ? [] : buildingList}
                                  value={selectedBuilding}
                                  getOptionLabel={(option)=>option.name || "None"}
                                  onChange={(e,v)=>{
                                      handleChange2('building', v);
                                      loadUnits(v.id);
                                  }}
                                  renderInput={(params) =>
                                  <MDInput {...params} label={"Select Building"}
                                           error={!!errors.building}
                                           helperText={errors.building?.message} />}
                            />
                        </Grid>: ""}
                        {(userRole === "staff" || userRole === "admin" || userRole === "super_admin") ? <Grid item xs={12} md={3}>
                            <Autocomplete
                                  disableClearable
                                  options={!unitList ? [] : unitList}
                                  value={selectedUnit}
                                  freeSolo
                                  autoSelect
                                  getOptionLabel={(option)=>option.unit || option || "None"}
                                  onChange={(e,v)=>{
                                      handleChange2('unit', v);
                                      // alert(v);

                                  }}
                                  renderInput={(params) =>
                                  <MDInput {...params} label={"Select Unit"}
                                                   onKeyDown={e => {
                                                      if (e.code === 'enter' && e.target.value) {
                                                        // setAutoCompleteValue(autoCompleteValue.concat(e.target.value));
                                                        //   alert(e.target.value);
                                                      }
                                                    }}

                                           error={!!errors.unit}
                                           helperText={errors.unit?.message} />}
                            />
                        </Grid>: ""}
                    </Grid>
                    <Grid container spacing={3} pt={4} px={2} xs={12} md={settings.showBulk ? 6: 12} >
                        <Grid item xs={12} md={12}>
                            <MDBox  pl={3}>
                                <MDTypography variant="h6">Create  {settings.showBulk ? "Single User": "Staff"}</MDTypography>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextField
                              required fullWidth type="email" label="Email" name="email"
                              error={!!errors.email}
                              helperText={errors.email?.message}
                              value={values.email}
                               autoComplete="new-password"
                              {...register("email")}
                              onChange={(event) =>
                                  handleChange({ ...values, email: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                              fullWidth type="text" label="Phone" name="phone"
                              error={!!errors.phone}
                              helperText={errors.phone?.message}
                              value={values.phone}
                               autoComplete="new-password"
                              {...register("phone")}
                              onChange={(event) =>
                                  handleChange({ ...values, phone: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                              required fullWidth type="text" label="First Name" name="firstName"
                              error={!!errors.firstName}
                              helperText={errors.firstName?.message}
                              value={values.firstName}
                              {...register("firstName")}
                              onChange={(event) =>
                                  handleChange({ ...values, firstName: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                              fullWidth type="text" label="Last Name" name="lastName"
                              error={!!errors.lastName}
                              helperText={errors.lastName?.message}
                              value={values.lastName}
                              {...register("lastName")}
                              onChange={(event) =>
                                  handleChange({ ...values, lastName: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MDBox display={settings.showBulk ? "flex": "none"} alignItems="center" mb={0.5} ml={-1.5}>

                              <MDBox width="80%" ml={0.5}  >
                                <MDTypography variant="button"   fontWeight="regular" color="text">
                                  Is Main Person ?
                                </MDTypography>
                              </MDBox>
                              <MDBox mt={0.5} >
                                <Switch checked={values.mainPerson}   onChange={(event, checked) => {
                                    setValues({...values, mainPerson: checked})
                                }} />
                              </MDBox>
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} pt={4} px={2} xs={12} md={6}
                          sx={settings.showBulk ? { "display": "flex"}: {"display":"none"}} >
                        <Grid item xs={12} md={12}>
                            <MDBox  pl={3}>
                                <MDTypography variant="h6">Create Bulks User</MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <input type={"file"} accept={".csv"} style={{"display":"none"}} onChange={onFileChange} ref={myRef} />
                            <MDButton size={"large"} fullWidth color={"primary"} variant={"gradient"} style={{display: currentFile===null ? 'block' : 'none'}}
                                      disabled={uploadInProgress} onClick={() => clickElement(myRef)}>
                              Select Csv of Users
                            </MDButton>
                            <MDButton  size={"large"} color={"success"}
                                       disabled={currentFile===null || uploadInProgress} fullWidth style={{display: currentFile===null ? 'none' : 'block'}} variant={"gradient"}  onClick={onFileUpload}>
                                Start creating users
                            </MDButton>
                            <Icon style={{display:buttonIcon===""?"none": "inline"}} fontSize={"small"}>{buttonIcon}</Icon>
                            <br/>

                            <MDButton fullWidth onClick={handleDownloadCsv}>
                                <MDTypography variant="h6" fontWeight={"light"} opacity={0.6}
                                              color={"secondary"}>Click here for download template csv</MDTypography>
                            </MDButton>
                        </Grid>
                    </Grid>
                    <Grid  spacing={2} pt={4}  item xs={12} md={12}  textAlign={"center"} >
                        <MDButton color={"info"} size={"large"} sx={{width:'50%'}} disabled={!isValid}
                                  onClick={onClickSaveData} >Save Data</MDButton>
                    </Grid>
                  </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>)

}

export default InsertUserComponent;
