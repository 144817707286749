import Icon from "@mui/material/Icon";
// import MDBadge from "../../../../components/MDBadge";
import {useEffect, useMemo, useState} from "react";
import CustomService from "../../../services/custome.service";
import GHIconButton from "../../../components/GHIconButton";
import ClientsService from "../../../services/clients.service";
import MDBadge from "../../../components/MDBadge";
import {clientColumns} from "../../../components/Utils/HeaderColumns";

function loadDataTable(instanceItem, clickItem){
    console.log(instanceItem.id);

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(clientColumns);
    if(instanceItem.id === undefined){
        // console.log("undefindeeeeeee");
        return {"columns":columns , "rows":[] };
    }
    useEffect(()=> {
      ClientsService.getClients()
        .then((res) => {
            console.log(res.data);
            setRows(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [setColumns, setRows] )
    ClientsService.getClients(instanceItem.id)
        .then((res) => { console.log(res.data); setRows(res.data); })
        .catch((error) => { console.log(error); });
    return {
        "columns": columns,
        "rows": rows.map(function(element)  {
            return {
                      id: element.id,
                      name: element.client_name,
                      code: element.client_code,
                      city: (
                          <div>
                              <h4>{element.address.city.province.country.name}<br/></h4>
                              <h5>{element.address.city.province.name} / {element.address.city.city_name}</h5>
                          </div>
                      ),
                      province: element.address.city.province.name,
                      country: element.address.city.province.country.name,
                      status: <MDBadge badgeContent={element.status} size="sm" container  color={element.status === "active" ? "success" : "error"} />,

                      address: element.address.address,
                      instance: element.instance.instance_name,
                      // members: 10,
                      action: (
                          <div>
                              <GHIconButton onClick={clickItem} id={element.id} action={"user_list"} rowName={element.client_name} title={"User List"} color={"info"} icon={"list_alt"} size={"medium"}  />
                              <GHIconButton onClick={clickItem} id={element.id} action={"edit"} rowName={element.client_name} title={"Edit"} color={"warning"} icon={"edit"} size={"medium"} />
                              <GHIconButton onClick={clickItem} id={ element.id} action={"change_status"}  rowName={element.client_name}
                                            title={element.status === "active" ? "Deactivated" : "Activate"}
                                            color={element.status === "active" ? "error" : "success"}
                                            icon={element.status === "active" ? "not_interested" : "check"} size={"medium"} />
                          </div>
                      ),
                }
        }),
    }
}

export default loadDataTable;
