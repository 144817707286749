import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BookingCard from "examples/Cards/BookingCard";
// import Modal from 'react-modal';

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import MDButton from "../../../../components/MDButton";
import DataTable from "../../../../examples/Tables/DataTable";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import dataTableData from "./data";
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import selectData from "../../account/settings/components/BasicInfo/data/selectData";
import FormField from "../../account/components/FormField";
import MDSnackbar from "../../../../components/MDSnackbar";
import loadDataTable from "./data";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import CustomService from "../../../../services/custome.service";
import AmenitiesService, {removeAmenityType} from "../../../../services/amenities.service";
import validateForm from "../../../../utils";
import amenitiesService from "../../../../services/amenities.service";
// import prepend from "react-hook-form/dist/utils/prepend";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function AmenityType() {

  const [amenityTypes, setAmenityTypes] = useState([]);
  const [amenityParentType, setAmenityParentType] = useState({});
  const [amenityType, setAmenityType] = useState("");
  useEffect(() => {
      AmenitiesService.getAmenitiesType()
      .then((res) => {
          console.log(res.data[0]);
          // prepend(res.data)
          res.data[res.data.length-1].amenity_type = "-- Without Parent --"
          setAmenityTypes(res.data);
          setAmenityParentType(res.data[0]);
      })
      .catch((error) => {
          console.log(error);
      });
  }, [setAmenityTypes, setAmenityParentType]);

  const [clickedItemId, setClickedItemId] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);

  const [open, setOpen] = useState(false);

  const [infoSB, setInfoSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleAgreeDeleteDialog = () => {
    AmenitiesService.removeAmenityType(clickedItemId)
        .then((response) =>{
            console.log(response);
            openInfoSB();
            window.location.reload();
        })
        .catch((err) => {
            console.log(err);
            setErrorMessage(err);
            openErrorSB();
        });
  };
  function handleDelete(elem) {
      handleOpenDialog();
      setClickedItemId(elem.target.id);
  }

  const [disableAction, setDisableAction] = useState(true);
  const [formData, setFormData] = useState({
      "amenity_name": { "validation":{is_required:true, min:2}, "error_status": false, "error_message":"", "value": ""},
      "parent": {"validation":{is_required:true}, "error_status": false, "error_message":"", "value": amenityParentType},
  })
  const setValue = (source, valueOfElement) => {
      if(source === "amenity_name") setAmenityType(valueOfElement);
      // if(source === "parent") setAmenityParentType(valueOfElement);
  }
  const saveDataClicked = (e) => {
      e.preventDefault();
      console.log(amenityParentType + ", "+ amenityType );
      if(!checkForm()){
          amenitiesService.insertAmenityType(amenityParentType.id + "", amenityType )
              .then((response) =>{
                  console.log(response);
                  openInfoSB();
                  window.location.reload();
              })
              .catch((err) => {
                  console.log(err);
                  setErrorMessage(err);
                  openErrorSB();
              });
      }
      handleClose();
  }
  const onChangeInput = (element, source) => {
      setDisableAction(false);
      let valueOfElement =  "";
      // console.log(element)
      if("target" in element){
          valueOfElement = element.target.value;
      }else if("id" in element) {
          valueOfElement = element.id
      }
      // console.log(valueOfElement)
      let errorObject = validateForm(formData[source]["validation"], source, valueOfElement)
      if(errorObject != null){
          setFormData(prevErrors => ({...prevErrors,
                      [source]: {
                          "validation":prevErrors[source]["validation"],
                          "error_status":errorObject["error"],
                          "error_message":errorObject["errorMessage"],
                          "value": valueOfElement}}))

      }
      setValue(source, valueOfElement);
  }
  const checkForm = () => {
      let is_check = false;
      for (const validationKey in formData) {
          let xData = validateForm(formData[validationKey]["validation"], validationKey, formData[validationKey]["value"])
          // console.log(xData)
          if(xData.error){
              is_check = true;
          }
      }
      setDisableAction(is_check);
      return is_check
  }


  const renderSB = (
      <div>
          <MDSnackbar
            color="success"
            icon="notifications"
            title="Amenity System"
            content="Your data saved successfully"
            dateTime=""
            open={infoSB}
            onClose={closeInfoSB}
            close={closeInfoSB}
          />
          <MDSnackbar
            color="error"
            icon="error"
            title="Amenity System"
            content={errorMessage}
            dateTime=""
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
          />
      </div>
  );

  const renderModal = () => {
    return <Modal open={open} onClose={handleClose} color={"warning"} >
      <MDBox sx={style}>
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">Insert Amenity Type</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                      clearIcon={false}
                      contentEditable={false}
                      value={amenityParentType}
                      options={amenityTypes}
                      onChange={(event,value) => {
                        setAmenityParentType(value);
                        onChangeInput(value, "parent")
                      }}
                      getOptionLabel={(option) => option.amenity_type}
                      renderInput={(params) => (
                            <FormField {...params} label="Parent" InputLabelProps={{ shrink: true }}  />
                      )}
                    />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormField
                    onChange={e => onChangeInput(e, "amenity_name")}
                    error={formData["amenity_name"].error_status}
                    helperText={formData["amenity_name"].error_message}
                    label="Amenity Type"
                    placeholder="Swimming"  />
              </Grid>
              <Grid item xs={12} md={12}>
                <MDButton color={"info"} size={"large"} onClick={saveDataClicked} >Save Data</MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  };



    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to delete this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


  const renderAmenityTypeList = () => {
    return(
        <MDBox my={3}>
          {renderSB}
          {renderModal()}
          {renderDialog()}
          <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <MDButton variant="gradient" color="warning" size="large" onClick={handleOpen}>
              new type
            </MDButton>
          </MDBox>
          <Card>
            <DataTable showTotalEntries={true}
                       table={loadDataTable(handleDelete)}
                       entriesPerPage={false} canSearch />
          </Card>
        </MDBox>
    )
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
        {renderAmenityTypeList()}
      <Footer />
    </DashboardLayout>
  );
}

export default AmenityType;
