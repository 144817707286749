import * as PropTypes from "prop-types";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import {Image} from "react-bootstrap";
import ad1 from "../../../assets/images/ads/ad.png";
import ad2 from "../../../assets/images/ads/ad2.png";
import {Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import customeService from "../../../services/custome.service";

function Ads({type}) {
    const [adItem, setAdItem] = useState("")
    return <></>;
    // if(type === "header"){
    //         return (<Grid container justifyContent="center">
    //                 <Button><Image src={ad1} height={70} width={500} thumbnail /></Button>
    //               </Grid>)
    //     }else {
    //         return (<Grid container justifyContent="center">
    //                 <Button><Image src={ad2} height={70} width={500} thumbnail /></Button>
    //               </Grid>)
    //     }
    // return adItem
}

Ads.defaultProps = {type: "header" };

Ads.propTypes = {type: PropTypes.string}

export default Ads;