
import GHIconButton from "../../../components/GHIconButton";
import {useEffect, useState} from "react";
import InstancesService from "../../../services/instances.service";
import {Image} from "react-bootstrap";
import MDBadge from "../../../components/MDBadge";
import {Typography} from "@mui/material";
import {instanceColumns} from "../../../components/Utils/HeaderColumns";



function loadDataTable(clickItem){

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState(instanceColumns);
    useEffect(()=> {
      InstancesService.getInstances()
          .then((res) => {
              setRows(res.data);
          })
          .catch((error) => { console.log(error); });
    }, [setColumns, setRows] )
    return {
        "columns": columns,
        "rows": rows.map(function(element)  {
            return {
                  id: element.id,
                  domain: element.domain,
                  logo: <Image width={70} height={70} src={element.logo} />,
                  name: element.name,
                  status: <MDBadge badgeContent={element.status} size="sm" container  color={element.status === "active" ? "success" : "error"} />,
                  client: element.clients,
                  member:  element.members,
                  action: (
                    <div>
                      <GHIconButton onClick={clickItem} id={element.id} action={"user_list"} rowName={element.name} title={"User List"} color={"info"} icon={"list_alt"} size={"medium"}  />
                      <GHIconButton onClick={clickItem} id={element.id} action={"client_list"} rowName={element.name} title={"Client List"} color={"secondary"} icon={"dns"} size={"medium"} />
                      <GHIconButton onClick={clickItem} id={element.id} action={"edit"} rowName={element.name} title={"Edit"} color={"warning"} icon={"edit"} size={"medium"} />
                      <GHIconButton onClick={clickItem}  id={element.id} action={"delete"} rowName={element.name} title={"Delete"} color={"error"} icon={"delete"} size={"medium"} />
                      <GHIconButton onClick={clickItem}  id={element.id} action={"disable"}
                                    rowName={element.name}
                                    title={element.status === "active"? "Disable" : "Active"}
                                    color={element.status === "active"? "info" : "error"}
                                    icon={element.status === "active"? "toggle_off" : "toggle_on"}
                                    size={"medium"} />
                    </div>
                  ),
            }
        }),
    }
}

export default loadDataTable;


// const dataTableDataInstance = {
//   columns: [
//
//   ],
//   rows: [
//     {
//       logo: <Image src={logo1} height={75} width={75} thumbnail />,
//       id: "1234-UDF",
//       name: "Core2Dou",
//       client: "12",
//       member: "156",
//       link: "https://google.com/",
//       actions: (
//         <div>
//           <GHIconButton title={"User List"} color={"info"} icon={"list_alt"} size={"medium"}  />
//           <GHIconButton title={"Client List"} color={"secondary"} icon={"dns"} size={"medium"} />
//           <GHIconButton title={"Edit"} color={"warning"} icon={"edit"} size={"medium"} />
//           <GHIconButton title={"Delete"} color={"error"} icon={"delete"} size={"medium"} />
//         </div>
//       ),
//     },
//     {
//       logo: <Image src={logo1} height={75} width={75} thumbnail />,
//       id: "1879-UDF",
//       name: "Electron",
//       client: "44",
//       member: "1560",
//       link: "https://google.com/",
//       actions: (
//         <div>
//           <GHIconButton title={"User List"} color={"info"} icon={"list_alt"} size={"medium"} />
//           <GHIconButton title={"Client List"} color={"secondary"} icon={"dns"} size={"medium"} />
//           <GHIconButton title={"Edit"} color={"warning"} icon={"edit"} size={"medium"} />
//           <GHIconButton title={"Delete"} color={"error"} icon={"delete"} size={"medium"} />
//         </div>
//       ),
//     },
//     {
//       logo: <Image src={logo1} height={75} width={75} thumbnail />,
//       id: "1256-UDF",
//       name: "ThereMoon",
//       client: "12",
//       member: "768",
//       link: "https://google.com/",
//       actions: (
//         <div>
//           <GHIconButton title={"User List"} color={"info"} icon={"list_alt"} size={"medium"} />
//           <GHIconButton title={"Client List"} color={"secondary"} icon={"dns"} size={"medium"} />
//           <GHIconButton title={"Edit"} color={"warning"} icon={"edit"} size={"medium"} />
//           <GHIconButton title={"Delete"} color={"error"} icon={"delete"} size={"medium"} />
//         </div>
//       ),
//     },
//
//   ],
// };
//
// export default dataTableDataInstance;
