import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import MDButton from "../../../../components/MDButton";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import {useState} from "react";
import PaymentService from "../../../../services/payment.service";

const CheckoutForm = () => {
      const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    PaymentService.createOrder("1223432")
        .then((value)=>{
            console.log(value.data)
        }).catch((reason)=>{
            console.log(value.data)
        });

    //
    // const result = await stripe.confirmPayment({
    //   elements,
    //   confirmParams: {
    //     return_url: "http://localhost:8081/api/private/order/123/complete",
    //     receipt_email:"Hello@gmail.com",
    //     shipping:{
    //         name:"shipping",
    //         address:{
    //             city:"tehran"
    //         },
    //         tracking_number:"232342"
    //     },
    //   },
    // });
    //
    // if (result.error) {
    //   // Show error to your customer (for example, payment details incomplete)
    //   console.log(result.error.message);
    //   console.log(result)
    //   setErrorMessage(result.error.message);
    // } else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    // }

  };

    const onLoaderStart = (e) =>{
        console.log("onLoaderStart");
    };
    const onReady = (e) =>{
        console.log("onReady");
    };
    const onChange = (e) =>{
        console.log("onChange");

    };
  return (
    <form onSubmit={handleSubmit}>
        <MDBox
          color="white"
          bgColor="dark"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}

        >
            <MDTypography  sx={{"marginBottom":"20px", "textAlign":"center"}}  color={"white"} variant={"h5"}
                           verticalAlign={"middle"}  fontWeight={"bold"}  >
                Pay to reserve Amenity</MDTypography>
          <PaymentElement  onLoaderStart={onLoaderStart} onReady={onReady} id={"pi_123"}
                           options={{layout:"accordion", business:{name:"AAIS"}}} onChange={onChange} />

          <MDButton  sx={{"marginTop":"20px"}} disabled={!stripe}  type={"submit"} fullWidth size={"large"}
                    variant={"gradient"} color={"success"}>Payment</MDButton>

            {errorMessage && <div>{errorMessage}</div>}
        </MDBox>
    </form>
  );
};

export default CheckoutForm;
