import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BookingCard from "examples/Cards/BookingCard";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import { getUserRole } from "../../../../services/auth.service";
import breakpoints from "../../../../assets/theme/base/breakpoints";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import InsertAmenity from "../insert";
import MDSocialButton from "../../../../components/MDSocialButton";
import AmenitiesList from "../item";
import {getAmenities, getAmenitiesType} from "../../../../services/amenities.service";



function Amenities() {
  const [selectedType, setSelectedType] = useState({});
  const [amenitiesType, setAmenitiesType] = useState([]);
  const [filterSelected, setFilterSelected] = useState(1);

  useEffect(() => {
    loadAmenityType();
  }, []);

  function loadAmenityType(){
      getAmenitiesType()
      .then((res) => {

        let defaultInstance = {"id":"", "size":1, "amenity_type":"-- All Category --" }
        if(res.data === null || res.data === undefined || res.data === ""){
            res.data = [];
        }
        res.data.unshift(defaultInstance);
        // console.log(res.data);
        let x = [];
        res.data.forEach(function (data) {
          if(data["size"] > 0){
            x.push(data);
          }
        })
        setAmenitiesType(x);
        setSelectedType(x[0])
        setFilterSelected(x[0].id)
      })
      .catch((error) => { console.log(error); });
  }

  function OnChangeItem(e,v) {
    console.log(v);
    setFilterSelected(v.id);
    setSelectedType(v);
  }

  const renderAmenityList = () => (
    <MDBox py={3}>
      <MDBox mt={3}>
        <Card>
          <Grid container spacing={3} p={3} lineHeight={1}>
            <Grid mb={2} xs={12} lg={6} item>
              <MDBox>
                <MDTypography variant="h5" fontWeight="medium">
                  All Amenities
                </MDTypography>
                <MDTypography variant="button" color="text">
                  select your building amenities and reserve it
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid mb={2} xs={12} lg={6} item>
              <MDBox>
                <Autocomplete
                  value={selectedType}
                  options={amenitiesType}
                  clearIcon={false}
                  contentEditable={false}
                  onChange={OnChangeItem}
                  getOptionLabel={(option) => option.amenity_type}
                  renderInput={(params) => <MDInput {...params}  label={"Category"} />}
                />
              </MDBox>
            </Grid>
          </Grid>
          <AmenitiesList filter={filterSelected} />
        </Card>
      </MDBox>
    </MDBox>
  );

  const renderMain = () => renderAmenityList();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderMain()}
      <Footer />
    </DashboardLayout>
  );
}

export default Amenities;
