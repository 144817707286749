
import { useState,useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

import {gapList, guestList, durationList, minorList, categoriesList } from "../../static_data"

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// import TextField from "@mui/material/TextField";

import { TextField } from "@mui/material";
import {useLocation} from "react-router-dom";
import AmenitiesService from "../../../../../../services/amenities.service";



function AmenityInfo({validStep, uniqueId, oldValues, valueSetter, ...rest}) {

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');


    // console.log('Hello', oldValues);
    const defaultInputValues = {
        amenityName:  oldValues['amenityName'] || '',
        minors: oldValues['minors'] || minorList[0],
        guests: oldValues['guests'] || guestList[0],
        duration : oldValues['duration'] || durationList[0],
        gap : oldValues['gap'] || gapList[0],
        category: oldValues['category'] || categoriesList[0],
        subCategory : '',
        capacity : "10",
        description : oldValues['description'] || "This is\nAmenity \nDescription"
      };

    const validationSchema = Yup.object().shape({
         amenityName: Yup.string()
              .min(3, 'Amenity Name must be at least 3 characters')
              .required('Amenity Name is required'),
          minors: Yup.number().required("minors is required").nullable(false),
          guests: Yup.number().required("guests is required").nullable(false),
          duration: Yup.string().required("duration is required ").nullable(false),
          gap: Yup.string().required("gap is required ").nullable(false),
          // category: Yup.string().required("category is required"),
          description: Yup.string(),
          capacity: Yup.string(),
      });



    const [values, setValues] = useState(defaultInputValues);
    const { register, handleSubmit, formState: { errors , isValid } } =
                        useForm({mode:"all", resolver:yupResolver(validationSchema)});
    const handleChange = (value) => {
        setValues(value)
        valueSetter(value)
    };




    useEffect(() => {
        if(id === null || id === undefined || id ===0){
             console.log("new amenity")
        }else{
          console.log("edit amenity")
          console.log(values)
            AmenitiesService.getAmenityInfo(id)
              .then(value => {
                  const data = value.data;
                  console.log(value.data)
                  let customData = {
                      'id':data["id"],
                      'image': data["image"].toString(),
                      'amenityName':data["name"].toString(),
                      'description':data["description"].toString(),
                      'gap':data["gap_duration"].toString() + " Minutes",
                      'duration':data["period_duration"].toString() + " Minutes",
                      'guests':data["guests_count"].toString() ,
                      'minors':data["minors_count"].toString(),
                      'capacity':data["all_capacity"].toString(),
                      'category':data["amenity_type"],
                      'price':data["price"].toString(),
                      'unit':data["unit"].toString(),
                  }
                  console.log(customData);
                  handleChange(customData);

                validStep(validationSchema.isValidSync(values));
              }).catch(reason => {

              });
        }
    },[setValues])

    // console.log();
    validStep(validationSchema.isValidSync(values));
    // console.log("errors -> " + isValid + "," + )
  return (
    <MDBox>
      <MDTypography variant="h5">Amenity Information</MDTypography>
      <MDBox mt={3} component={"form"} >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} >
              <TextField
                  type="text"
                  label="Amenity Name"
                  required
                  variant={"standard"}
                  fullWidth
                  name="amenityName"
                  error={!!errors.amenityName}
                  helperText={errors.amenityName?.message}
                  value={values.amenityName}
                  {...register("amenityName")}
                  onChange={(event) =>
                      handleChange({ ...values, amenityName: event.target.value })}

              />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              options={minorList}
              value={values.minors}
              onChange={(event,value) =>
                  handleChange({ ...values, minors: value })}
              renderInput={(params) =>
                  <MDInput {...params} label={"Minors"}
                           variant="standard"
                           error={!!errors.minors}
                           helperText={errors.minors?.message}
                           {...register("minors")} />}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              options={guestList}
              value={values.guests}
              onChange={(event,value) =>
                  handleChange({ ...values, guests: value })}
              renderInput={(params) =>
                  <MDInput {...params} label={"Guest"} variant="standard"
                           error={!!errors.guests}
                           helperText={errors.guests?.message}
                           {...register("guests")}
                  />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={durationList}
              value={values.duration}
              onChange={(event,value) =>
                  handleChange({ ...values, duration: value })}
              renderInput={(params) =>
                  <MDInput {...params} label={"Duration"} variant="standard"
                           error={!!errors.duration}
                           helperText={errors.duration?.message}
                           {...register("duration")}
                  />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={gapList}
              value={values.gap}
              onChange={(event,value) =>
                  handleChange({ ...values, gap: value })}
              renderInput={(params) =>
                  <MDInput {...params} label={"Gap"} variant="standard"
                           error={!!errors.gap}
                           helperText={errors.gap?.message}
                           {...register("gap")}
                  />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField value={values.capacity} type={"number"} name="capacity" label="Capacity" fullWidth required
                  variant={"standard"}
                      onChange={(e)=>{
                          handleChange({ ...values, capacity: e.target.value }) }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={2} display="inline-block">
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Category
              </MDTypography>
            </MDBox>
            <Autocomplete
              options={categoriesList}
              value={values.category}
              clearIcon={false}

              contentEditable={false}
              onChange={(e,value)=>{
                handleChange({ ...values, category: value })
              }}
              getOptionLabel={(label)=>label.amenity_type}
              renderInput={(params) =>
                  <MDInput {...params}
                           error={!!errors.category}
                           helperText={errors.category?.message}
                            {...register("category")}
                  />
              }
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Description&nbsp;&nbsp;
                <MDTypography variant="caption" color="text">
                  (optional)
                </MDTypography>
              </MDTypography>
            </MDBox>
            <TextField value={values.description}
                        name="description"
                       fullWidth
                      required
                       multiline={true}
                      onChange={(e)=>{
                          handleChange({ ...values, description: e.target.value }) }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default AmenityInfo;
