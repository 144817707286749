import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {getUserRole} from "../../../../services/auth.service";
import ClientsService from "../../../../services/clients.service";
import {keyColumns, keyTypeColumns} from "../../../../components/Utils/HeaderColumns";
import MDBadge from "../../../../components/MDBadge";
import GHIconButton from "../../../../components/GHIconButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../../components/MDBox";
import {modalStyleAds, modalStyleClients, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import TextField from "@mui/material/TextField";
import {Link} from "react-router-dom";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import KeysService from "../../../../services/keys.service";

function KeysTypeList() {

    const defaultInputValues = {  keyType: ""};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        keyType: Yup.string().required('Key Type is required'),
    });

    const { register, handleSubmit, formState: { errors  } } =
        useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [units, setUnits] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadKeyType();
    }, []);




    const [keyTypeTableData, setKeyTypeTableData] =
                        useState({"columns" : keyTypeColumns, "rows":[]});

    const loadKeyType = () => {
        setKeyTypeTableData({"columns" : keyTypeColumns, "rows":[]})
        KeysService.getKeyTypes()
        .then((res) => {
            console.log(res.data);
            setKeyTypeTableData( {
                "columns": keyTypeColumns,
                "rows": res.data.map(function(element) {
                        return {
                              id: element.id,
                              keyType: element.key_type,
                              action: (
                                  <div>
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"delete"}
                                                    rowName={element.client_name} title={"Remove"} color={"error"}
                                                    icon={"delete"} size={"medium"} />

                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(true);
                break;
            case "delete" :
                handleOpenDialog(model);
                break;
            case "change_status":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            KeysService.removeKeyType(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }
    }


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "change_status":
                handleOpen(elem,"change_status");
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){
            let dataToSend = {
                "key_type": values.keyType.toString()
            }
            console.log(dataToSend);

            KeysService.insertKeyType(dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [editMode, setEditMode] = useState(false);
    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleAds}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Key Type": "Insert New Key Type"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                          fullWidth
                          required
                          error={!!errors.keyType}
                          helperText={errors.keyType?.message}
                          value={values.keyType}
                          {...register("keyType")}
                          onChange={(event) => {
                            handleChange({ ...values, keyType: event.target.value })
                          }}
                        label="Key Type"
                        placeholder="Corridor Key"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };


    const renderKeysTypeList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space" alignItems="flex-start" mb={2}>
                  <MDButton variant="gradient" color="warning" size="large"
                            disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>
                    new key Type
                  </MDButton>
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={keyTypeTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderKeysTypeList()}
          <Footer />
        </DashboardLayout>
    );

}

export default KeysTypeList;