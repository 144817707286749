import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import Header from "../../../applications/kanban/components/Header";
import Board from "@asseinfo/react-kanban";
// import boards from "../../../applications/kanban/data";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import {v4 as uuidv4} from "uuid";
import parse from "html-react-parser";
import boards from "./data";
import {useEffect, useState} from "react";
import selectData from "../../account/settings/components/BasicInfo/data/selectData";
import MDBadge from "../../../../components/MDBadge";
import IconButton from "@mui/material/IconButton";
// import MDEditor from "../../../../../../components/MDEditor";

function AmenityQuestions({validStep, uniqueId, oldValues, valueSetter, ...rest}) {
  const [darkMode, setDarkMode] = useState(false);
  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");

  const openNewCardForm = (event, id) => setNewCardForm(id);
  const closeNewCardForm = () => setNewCardForm(false);
  const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);
    const [correctValue , setCorrectValue] = useState("No");
  return (
    <MDBox>
      <MDTypography variant="h5">Amenity Questions</MDTypography>

      <MDBox py={3}>
        <MDBox
          position="relative"
          my={1}
          sx={({
            palette: { light, background },
            functions: { pxToRem },
            borders: { borderRadius },
          }) => ({
            "& .react-kanban-column": {
              backgroundColor: darkMode ? background.card : light.main,
              width: pxToRem(1024),
              margin: `0 ${pxToRem(10)}`,
              padding: pxToRem(20),
              borderRadius: borderRadius.lg,
            },
          })}
        >
          <Board
            initialBoard={boards}
            allowAddCard
            allowRemoveCard
            allowRemoveColumn
            allowAddColumn

            draggable={true}

            renderColumnHeader={({ id, title }, { addCard }) => (
              <>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <MDTypography variant="h6">{title}</MDTypography>
                  <MDButton size="small" iconOnly onClick={(event) => openNewCardForm(event, id)}>
                    <Icon sx={{ fontWeight: "bold", color: ({ palette: { dark } }) => dark.main, }} >
                      add
                    </Icon>
                  </MDButton>
                </MDBox>
                {newCardForm === id ? (
                  <MDBox my={2.5}>
                    <Grid container>
                        <Grid item sm={8}>
                            <MDInput
                              value={formValue}
                              rows="3"
                              onChange={handeSetFormValue}
                              multiline
                              fullWidth
                            />
                        </Grid>
                        <Grid item pl={1} sm={4}>
                            <Autocomplete
                              defaultValue="Yes"
                              options={["Yes", "No"]}
                              onChange={(e,v)=>{
                                  setCorrectValue(v);
                              }}
                              renderInput={(params) => (
                                <FormField {...params} InputLabelProps={{ shrink: true }}  label={"Select Response"}/>
                              )}
                            />
                        </Grid>
                    </Grid>

                    <MDBox display="flex" mt={2}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        size="small"
                        onClick={() => {
                          addCard({ id: uuidv4(), template: {"question":formValue, "answer":correctValue} });
                          setFormValue("");
                        }}
                      >
                        add
                      </MDButton>
                      <MDBox ml={1}>
                        <MDButton
                          variant="gradient"
                          color="light"
                          size="small"
                          onClick={closeNewCardForm}
                        >
                          cancel
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                ) : null}
              </>
            )}
            renderCard={({ id, template }, { dragging, removeCard }) => (
              <>
              <MDBox
                key={id}
                // dragging={dragging.toString() || undefined}
                display="block" width="calc(1024px - 40px)" bgColor={darkMode ? "transparent" : "white"}
                color="text"  borderRadius="xl" mt={2.5} py={1.875} px={1.875} lineHeight={1.5}
                sx={{
                  border: ({ borders: { borderWidth }, palette: { white } }) =>
                    darkMode ? `${borderWidth[1]} solid ${white.main}` : 0,
                  fontSize: ({ typography: { size } }) => size.md,
                }}
              >
                {parse(template["question"])}
                <IconButton sx={{"float":"right"}}>
                    <Icon key={id} onClick={removeCard} fontSize={"medium"} color={"error"}  >delete</Icon>
                </IconButton>
              </MDBox>

              </>
            )}
            onCardNew={(card) => {
                let qList = [];
                card["columns"][0]["cards"].forEach(item =>{
                    console.log(item);
                    qList.push({"question":item["template"]["question"], "answer":item["template"]["answer"]});
                });
                valueSetter({...oldValues, questions: qList})
            }}
            onCardRemove={(card)=>{
                let qList = [];
                card["columns"][0]["cards"].forEach(item =>{
                    qList.push({"question":item["template"]["question"], "answer":item["template"]["answer"]});
                });
                valueSetter({...oldValues, questions: qList})
            }}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}




export default AmenityQuestions;
