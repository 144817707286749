import Icon from "@mui/material/Icon";
import MDBadge from "../../../../components/MDBadge";
import {useEffect, useMemo, useState} from "react";
import {getAmenities, getAmenitiesType} from "../../../../services/amenities.service";
import GHIconButton from "../../../../components/GHIconButton";

function loadDataTable(clickItem){
    const getByKey = (arr,key) => (arr.find(x => Object.keys(x)[0] === key) || {})[key]

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([
        { Header: "Name", accessor: "name",  width: "20%" },
        { Header: "Amenity Count", accessor: "amenityCount",  width: "20%" },
        { Header: "Parent", accessor: "parent", width: "25%" },
        { Header: "Actions", accessor: "action", width: "7%" },
    ]);
    useEffect(()=> {
      getAmenitiesType()
        .then((res) => {
          setRows(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [setColumns, setRows] )
    return {
        "columns": columns,
        "rows": rows.map(function(element)  {

            return {
                      id: element.id,
                      name: element.amenity_type,
                      parent: element.parent.amenity_type,
                      amenityCount: element.size,
                      action: (
                        <GHIconButton  onClick={clickItem} id={element.id} title={"Delete"} icon={"delete"} size={"small"}  color="error" />
                      ),
                }
            }),
    }
}

export default loadDataTable;
