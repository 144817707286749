import {useState, useMemo, useEffect} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Divider from "@mui/material/Divider";
import ReviewInfoCard from "./reviewCard";

function ReviewData({values, setValues, ...res}) {
    console.log(values)

  useEffect(() => {
      setValues({...values, isValid:true});
  }, [setValues]);
  return (
    <MDBox>
      <MDBox>
        <Grid item spacing={2} lg={6}>
          <MDTypography variant="h5">Review Information</MDTypography>
        </Grid>
      </MDBox>
      <MDBox pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <ReviewInfoCard
              title="profile information"
              description={"You are booking "+values.name+" on "+values.selectedDate.date+" at "+values.start_time+" with the following information:"}
              info={{
                fullName: values.completeName,
                mobile: values.phone || "_",
                reserveTime: values.selectedDate.date + " ("+values.selectedDate.weekday +") | " + values.start_time + " - " + values.end_time,
                minorsCount: values.minors || 0,
                guestsCount: values.guests || 0,
                email: values.email || "-",
                paymentAmount: values.price > 0 ? values.price + " " + values.currency : "Free",
              }}
              action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
            <Divider orientation="vertical" sx={{ mx: 0 }} />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default ReviewData;
