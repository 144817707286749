import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// Images
import Card from "@mui/material/Card";
import {useEffect, useState} from "react";
import MDButton from "../../../../components/MDButton";
import DataTableDataUsers from "./data";
import Modal from '@mui/material/Modal';
import InsertUserComponent from "../../users/insert";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DialogShower, SnackShower, TableShower} from "../../../../components/Utils/Tools";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {modalStyle} from "../../../../components/Utils/ModalStyle";
import {TextField} from "@mui/material";
import LoadingOverlay from 'react-loading-overlay';
import UserService from "../../../../services/user.service";
import {Label, LabelImportant} from "@mui/icons-material";
import {Image} from "react-bootstrap";
import MDAvatar from "../../../../components/MDAvatar";
import CoreService from "../../../../services/core.service";
import FormField from "../../account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import clientsService from "../../../../services/clients.service";


function Users() {


    const title= "New User"
    const defaultInputValues = { newPassword: '', passwordConfirmation: ''}
    const [values, setValues] = useState(defaultInputValues);
    const [isValid, setIsValid] = useState(false);
    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required('New Password is required')
            .min(6, 'New Password must be at least 6 characters'),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });
    const { register, handleSubmit, formState: { errors  } } =
                        useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const [activeLoading, setActiveLoading] = useState(false);
    const [showModalChangePassword, setShowModalChangePassword] = useState(false);
    const [showModalUserInfo, setShowModalUserInfo] = useState(false);
    const [showModalChangeUnit, setShowModalChangeUnit] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [snackBarSetting, setSnackBarSetting] = useState({type:"error", error_message:"", show:false});

    const [userInsertSetting, setUserInsertSetting] = useState({ show:false, showBulk:true,
        title:"Main Building"});
    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));
    };
    setTimeout(function (){
        setIsValid(validationSchema.isValidSync(values));
    }, 1000);

    const onClickSaveData = (e) => {
        console.log("onClickSaveData");

        e.preventDefault();
        if(isValid){
            setActiveLoading(true);
            values["user"] = selectedUser.id
            UserService.ChangePasswordAdminMode(values)
                .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                window.location.reload(true);
                setActiveLoading(false);
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status})
                setActiveLoading(false);
            });
        }

    }


    const onClickSaveDataUnits = (e) => {
        console.log("onClickSaveDataUnits");

        e.preventDefault();
         let createdValues = {
            'user':selectedUser.id,
            'unit':selectedUnit,
            'firstName':selectedFirstName,
            'lastName':selectedLastName,
            'email':selectedEmail,
            'client':selectedUser.client,
            'building': "0"
        }
        console.log(createdValues);
        if (selectedBuildingItem.id !== undefined && selectedBuildingItem.id !== null){
            createdValues["building"] =  selectedBuildingItem.id.toString()
        }
        setActiveLoading(true);
        UserService.updateUser(selectedUser.id, createdValues)
            .then((e)=>{

            setActiveLoading(false);
            setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
            window.location.reload(true);
        }).catch((err)=>{
            setActiveLoading(false);
            setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status})

        });

    }



    const onClickResendEmailItem = (elem) => {
        let id = elem.target.id;
        setActiveLoading(true);
        UserService.resendConfirmEmail(id)
            .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                // window.location.reload(true);
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status});
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
            });
    }
    const onClickDisableItem = (elem) => {
        let id = elem.target.id;
        setActiveLoading(true);
        UserService.disableUser(id)
            .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})
                // window.location.reload(true);
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
                window.location.reload(true);
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status});
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
            });
    }
    const onClickRemoveItem = (elem) => {
        let id = elem.target.id;
        setActiveLoading(true);
        UserService.deleteUser(id)
            .then((e)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "info", error_message: ""})

                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
                window.location.reload(true);
            }).catch((err)=>{
                setSnackBarSetting({...snackBarSetting, show: true, type: "error", error_message: err.response.data.status});
                setActiveLoading(false);
                setDialogSetting({...dialogSetting, show: false});
            });
    }

    const [dialogSetting, setDialogSetting] = useState({ show:false, onClickDone: onClickRemoveItem, id:""});


  const [searchParams] = useSearchParams();
    const onClickRow = (elem) => {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let client = elem.target.getAttribute("client");
        let rowId = elem.target.id;
        setSelectedUser({...selectedUser, id:rowId, name:rowName, client:client});
        // alert(actionToDo)
        switch (actionToDo) {
            case "change_password":
                setShowModalChangePassword(true);
                break;
            case "edit":
                setShowModalChangeUnit(true);
                setActiveLoading(true);
                getUserData(rowId);
                break;
            case "show_info":
                setShowModalUserInfo(true);
                getUserData(rowId);
                break;
            case "book_amenity":
                // alert(searchParams.get("amenity_id") )
                if(searchParams.has("amenity_id")){
                    window.location.href = `/amenities/book?id=${searchParams.get("amenity_id")}&userid=${rowId}`;
                }else{
                    window.location.href = `/amenities?userid=${rowId}`;
                }
                break;
                // window.location.href = "/amenities?userid=" + rowId
                // break;
            case "delete":
                setDialogSetting({...dialogSetting, title:"Delete", show:true, id:rowId, onClickDone: onClickRemoveItem})
                break;
            case "disable":
                setDialogSetting({...dialogSetting, title:"Disable/Enable", show:true, id:rowId, onClickDone:onClickDisableItem})
                break;
            case "resend_confirm":
                setDialogSetting({...dialogSetting, title:"Resend Confirmation Email", show:true, id:rowId, onClickDone:onClickResendEmailItem})
                break;
        }
    }
    const onClickNew = (e) => {
        console.log("onClickNew");
        // setShowModal(true);

        setUserInsertSetting({...userInsertSetting, show: true});
    }


    const [tableSetting, setTableSetting] = useState({ data: DataTableDataUsers,
                                                                    progressChanger:setActiveLoading,
                                                                    showButton: true,
                                                                    buttonText: title,
                                                                    buttonClick: onClickNew,
                                                                    onClickRow:onClickRow});

    const renderUserInfoModal = () => {
        return <Modal open={showModalUserInfo}   color={"warning"}
                     onClose={(e)=>{setShowModalUserInfo(false)}}>
          <MDBox sx={modalStyle}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3} pb={2}>
                <MDTypography variant="h6">User Info ({selectedUser.name})</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3}  >
                  <Grid container justifyContent={"center"} >
                    <Grid container spacing={2} pt={2} px={2} xs={12} md={12}   >

                        <Grid item xs={12} md={12}>
                            <MDAvatar src={ CoreService.BASE_API_URL + selectedImage}  bgColor={"dark"} size={"xxl"} shadow={"md"} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"}  />
                                {selectedFirstName} {selectedLastName}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"} />
                                {selectedUnit}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"} />
                                {selectedBuilding}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"} />
                                {selectedClient}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"} />
                                {selectedPostalCode}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"} />
                                {selectedPhone}</MDTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDTypography fontWeight={"bold"} textTransform={"capitalize"}   >
                                <LabelImportant color={"warning"} />
                                {selectedAddress}</MDTypography>
                        </Grid>


                    </Grid>
                  </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    }

    const renderChangePasswordModel = () =>{
       return <Modal open={showModalChangePassword}   color={"warning"}
                     onClose={(e)=>{setShowModalChangePassword(false)}}>
          <MDBox sx={modalStyle}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3} pb={2}>
                <MDTypography variant="h6">Change Password ({selectedUser.name})</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} >
                  <Grid container >
                    <Grid container spacing={2} pt={2} px={2} xs={12} md={12}   >
                        <Grid item xs={12} md={12}>
                            <TextField
                              required fullWidth
                              type="password" label="New Password" name="newPassword"
                              error={!!errors.newPassword}
                              helperText={errors.newPassword?.message}
                              value={values.newPassword}
                              {...register("newPassword")}
                              onChange={(event) =>
                                  handleChange({ ...values, newPassword: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                              required fullWidth
                              type="password" label="Confirm Password" name="passwordConfirmation"
                              error={!!errors.passwordConfirmation}
                              helperText={errors.passwordConfirmation?.message}
                              value={values.passwordConfirmation}
                              {...register("passwordConfirmation")}
                              onChange={(event) =>
                                    handleChange({ ...values, passwordConfirmation: event.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MDButton color={"info"} size={"large"} sx={{width:'50%'}}
                                      disabled={!isValid} onClick={onClickSaveData}  >Save Data</MDButton>
                        </Grid>

                    </Grid>
                  </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    }

    const [buildingList , setBuildingList] = useState([]);

    const loadBuildings = () => {
        clientsService.getBuildings()
            .then((res) => {
                let data = []
                res.data.forEach((k)=>{
                    data.push({"building_name":k.name, "id": k.id})
                })
                // console.log(data);
                setBuildingList(data);
                // setSelectedBuildingItem(data[0]);
            })
            .catch((e) => {
                console.log(e.toString());
            });
    }

    useEffect(()=>{
        loadBuildings();
    },[setBuildingList])



    const [selectedUnit, setSelectedUnit] = useState("")
    const [selectedFirstName, setSelectedFirstName] = useState("")
    const [selectedLastName, setSelectedLastName] = useState("")
    const [selectedImage, setSelectedImage] = useState("")
    const [selectedEmail, setSelectedEmail] = useState("")
    const [selectedBuilding, setSelectedBuilding] = useState("")
    const [selectedBuildingItem, setSelectedBuildingItem] = useState({})
    const [selectedClient, setSelectedClient] = useState("")
    const [selectedAddress, setSelectedAddress] = useState("")
    const [selectedPostalCode, setSelectedPostalCode] = useState("")
    const [selectedPhone, setSelectedPhone] = useState("")


    const getUserData = (userId) =>{
        UserService.getUser(userId)
            .then((result)=>{

                setActiveLoading(false);
                console.log(result.data);
                setSelectedFirstName(result.data["first_name"]);
                setSelectedLastName(result.data["last_name"]);
                setSelectedEmail(result.data["email"]);
                setSelectedImage(result.data["image"]);
                setSelectedBuilding(result.data["building"]);
                // setSelectedBuildingItem(
                //     {
                //         "building_name":result.data["building_item"]["building_name"],
                //         "id":result.data["building_item"]["id"]
                //     });
                // setSelectedBuildingItem(result.data["building_item"]);
                setSelectedClient(result.data["client"]);
                setSelectedUnit(result.data["unit"]);
                setSelectedAddress(result.data["address"]);
                setSelectedPostalCode(result.data["postal_code"]);
                setSelectedPhone(result.data["phone"]);
            }).catch((reason) =>{
                setActiveLoading(false);
                alert(reason);

                setSelectedUnit("");
                setSelectedFirstName("");
                setSelectedLastName("");
                setSelectedImage("");
                setSelectedBuilding("");
                setSelectedClient("");
            });
    }


    const renderChangeUnits = () => {
       return <Modal open={showModalChangeUnit}   color={"warning"}
                     onClose={(e)=>{setShowModalChangeUnit(false)}}>
          <MDBox sx={modalStyle}>
              <LoadingOverlay active={activeLoading}  spinner text='Loading...'>
                <Card id="basic-info" sx={{ overflow: "visible" }}>
                  <MDBox p={3} pb={2}>
                    <MDTypography variant="h6">Edit User ({selectedUser.name})</MDTypography>
                  </MDBox>
                  <MDBox component="form" pb={3} >
                      <Grid container >
                        <Grid container spacing={2} pt={2} px={2} xs={12} md={12}   >
                            <Grid item xs={12} md={6}>
                                <TextField
                                  required fullWidth
                                  value={selectedFirstName}
                                  type="text" label="First Name" name="firstName"
                                  onChange={(event) =>
                                      setSelectedFirstName(event.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                  required fullWidth
                                  value={selectedLastName}
                                  type="text" label="Last Name" name="lastName"
                                  onChange={(event) =>
                                      setSelectedLastName(event.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                   fullWidth
                                  value={selectedEmail}
                                  type="text" label="Email" name="email"
                                  onChange={(event) =>
                                      setSelectedEmail(event.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                      defaultValue={selectedBuildingItem}
                                      options={buildingList}
                                      getOptionLabel={(option) => option.building_name || ""}
                                      onChange={(e,v)=>{
                                          setSelectedBuildingItem(v)
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} label="Building" InputLabelProps={{ shrink: true }} />
                                      )}
                                    />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                  type="text" label="Unit Name" name="unit"
                                  value={selectedUnit}
                                  placeholder={"If you want change unit"}
                                  onChange={(event) =>
                                      setSelectedUnit(event.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <MDButton color={"info"} size={"large"} sx={{width:'50%'}}
                                          onClick={onClickSaveDataUnits} >Save Data</MDButton>
                            </Grid>

                        </Grid>
                      </Grid>
                  </MDBox>
                </Card>
              </LoadingOverlay>
          </MDBox>
        </Modal>
    }



    const renderUserList = () => {
        // console.log('renderUserList')
        return(
            <MDBox my={3}>
                {renderChangePasswordModel()}
                {renderChangeUnits()}
                {renderUserInfoModal()}
                <SnackShower values={snackBarSetting} setValues={setSnackBarSetting} />
                <DialogShower values={dialogSetting} setValues={setDialogSetting} />
                <TableShower values={tableSetting} setValues={setTableSetting} />
                {/*{renderTable()}*/}
                <InsertUserComponent settings={userInsertSetting} setSettings={setUserInsertSetting}
                                     snackBarSetting={snackBarSetting} setSnackBarSetting={setSnackBarSetting}  />
            </MDBox>
        )

        // return(
    //     <MDBox my={3}>
    //       {renderInfoSB}
    //       {renderModal()}
    //     <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
    //       <MDButton variant="gradient" color="info" size="large" onClick={handleOpen} to={"/users/insert"} component={Link}>
    //         new member
    //       </MDButton>
    //       {/*<MDButton variant="gradient" color="warning" size="large" onClick={handleOpen} to={"/users/insert"} component={Link}>*/}
    //       {/*  new staff*/}
    //       {/*</MDButton>*/}
    //       <MDBox display="flex">
    //         <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
    //           filters&nbsp;
    //           <Icon>keyboard_arrow_down</Icon>
    //         </MDButton>
    //         {renderMenu}
    //         <MDBox ml={1}>
    //           <MDButton variant="outlined" color="dark">
    //             <Icon>description</Icon>
    //             &nbsp;export csv
    //           </MDButton>
    //         </MDBox>
    //       </MDBox>
    //     </MDBox>
    //     <Card>
    //       <DataTable showTotalEntries={true} table={dataTableDataUsers} entriesPerPage={false} canSearch />
    //     </Card>
    //   </MDBox>
    // )
  };

    return (
      <DashboardLayout>
        <DashboardNavbar />
          <LoadingOverlay active={activeLoading} spinner text='Loading...'>
            {renderUserList()}
          </LoadingOverlay>
        <Footer />
      </DashboardLayout>
    );
}

export default Users;
