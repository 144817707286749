import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BookingCard from "examples/Cards/BookingCard";
// import Modal from 'react-modal';

// Images
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import MDButton from "../../../../components/MDButton";
import DataTable from "../../../../examples/Tables/DataTable";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import dataTableDataUsers from "./data";
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import selectData from "../../account/settings/components/BasicInfo/data/selectData";
import FormField from "../../account/components/FormField";
import MDSnackbar from "../../../../components/MDSnackbar";
import InsertUser from "../../users/insert";
import {Link} from "react-router-dom";
import MDInput from "../../../../components/MDInput";
import {Image} from "react-bootstrap";
import team1 from "../../../../assets/images/ads/ad1.gif";
import {modalStyle, modalStyleAds} from "../../../../components/Utils/ModalStyle";


function AdsList() {

  const [menu, setMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const saveDataClicked = (e) =>{
    e.preventDefault();
    handleClose();
    openInfoSB();
  }

   const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="notifications"
      title="Amenity System"
      content="Your data saved successfully"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderMenu = () => {
      return (
          <Menu
              anchorEl={menu}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(menu)}
              onClose={closeMenu}
              keepMounted
            >
              <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
              <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
              <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
              <Divider sx={{ margin: "0.5rem 0" }} />
              <MenuItem onClick={closeMenu}>
                <MDTypography variant="button" color="error" fontWeight="regular">
                  Remove Filter
                </MDTypography>
              </MenuItem>
            </Menu>
      )
  }
  const renderModal = () => {
    const [selectedImage, setSelectedImage] = useState("https://dummyimage.com/400x70/e8d8e8/131dab.jpg");
    // const removeSelectedImage = () => {
    //   setSelectedImage();
    // };
    const imageChange = (e) => {
      // alert(e.target.files)
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage(URL.createObjectURL(e.target.files[0]));
        // selectedImage)
      }
    };
    return <Modal open={open} onClose={handleClose} color={"warning"} >
      <MDBox sx={modalStyleAds}>
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">Insert Ads</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  defaultValue="Up-Banner"
                  options={["Up-Banner", "Down-Banner" ]}
                  renderInput={(params) => (
                    <FormField {...params} label="Ad Type" InputLabelProps={{ shrink: true }} />
                  )}s
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormField type={"url"}  placeholder={"https://google.com"} label={"URL"} ></FormField>
              </Grid>
              <Grid item xs={12} md={12}>
                <Image src={selectedImage} height={70} width={600} thumbnail alt={"ads"} rounded={true}  />
                {/*<FormField type={"image"} src={selectedImage}  />*/}
              </Grid>
              <Grid item xs={12} md={12}>
                {/*<FormField type={"file"}  />*/}

                <MDInput type={"file"} fullWidth onChange={imageChange} label="Image File" accept={"image/*"}   ></MDInput>
              </Grid>
              <Grid item xs={12} md={12}>
                <MDButton color={"info"} size={"large"} onClick={saveDataClicked} >Save Data</MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
  };

  const renderUserList = () => {
    return(
        <MDBox my={3}>
          {renderInfoSB}
          {renderModal()}
        <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" size="large" onClick={handleOpen}>
            new Advertisement
          </MDButton>
          <MDBox display="none">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable showTotalEntries={true} table={dataTableDataUsers} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
    )
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
        {renderUserList()}
      <Footer />
    </DashboardLayout>
  );
}

export default AdsList;
