import MDBadge from "../../../../components/MDBadge";
import {Icon} from "@mui/material";
import {userDashboardColumns} from "../../../../components/Utils/HeaderColumns";


const dataTableDataUser = {
  columns: userDashboardColumns,
  rows: [
    {
      name: "John Doe",
      amenity: "Indoor Pool",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "17/01/2022",
      client: "Gates of Bayview",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
    {
      name: "Jessie Jackson",
      amenity: "Kun Gym",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "15/08/2022",
      client: "Gates of Bayview",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
    {
      name: "Emma Watson",
      amenity: "Golf Room",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "18/09/2022",
      client: "Gates of Bayview",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
  ],
};

export default dataTableDataUser;
