import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {getUserRole} from "../../../../services/auth.service";
import ClientsService from "../../../../services/clients.service";
import {borrowKeyColumns, contractorColumns, keyColumns} from "../../../../components/Utils/HeaderColumns";
import KeysService, {borrowKey} from "../../../../services/keys.service";
import MDBadge from "../../../../components/MDBadge";
import GHIconButton from "../../../../components/GHIconButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../../components/MDBox";
import {modalStyleAds} from "../../../../components/Utils/ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import TextField from "@mui/material/TextField";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import {BasicDatePicker, BasicDateRangePicker} from "../../elevators/components";

function BorrowKey() {
    const defaultInputValues = { description:"" , endTime: "2023-01-01T08:00", startTime: "2023-01-01T16:00"};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        description: Yup.string() .required('Description is required'),
        startTime: Yup.string(),
        endTime: Yup.string(),
    });

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [units, setUnits] = useState([]);
    const [keys, setKeys] = useState([]);
    const [traders, setTraders] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadClients();
        loadBorrow();
        loadTrader();
    }, [setClients, setBuildings, setUnits]);

    const loadClients = (instance_id) => {
        ClientsService.getClients(instance_id)
        .then((res) => {
            if(res.data !== undefined && res.data !== null ){
                setClients(res.data);
                if (res.data.length > 0){
                    loadBuilding(res.data[0].id);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadBuilding = (client_id) => {
        ClientsService.getClientsBuilding(client_id)
        .then((res) => {
            if(res.data !== undefined && res.data !== null ){
                setBuildings(res.data);
                if(res.data[0].length > 0){
                    loadKey(res.data[0].id);
                    loadUnits(res.data[0].id);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const loadUnits = (building_id) => {
        ClientsService.getBuildingUnits(building_id)
        .then((res) => {
            if(res.data !== undefined && res.data !== null ){
                setUnits(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadKey = (building_id) => {
        KeysService.getKeys(building_id)
        .then((res) => {
            if(res.data !== undefined && res.data !== null ){
                setKeys(res.data);
            }
            // loadProvince(res.data[0]);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadTrader = () => {
        KeysService.getTraders()
        .then((res) => {
            if(res.data !== undefined && res.data !== null ){
                console.log(res.data);
                setTraders(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }



    const [borrowKeyTableData, setBorrowKeyTableData] =
                        useState({"columns" :
                            borrowKeyColumns, "rows":[]});

    const loadBorrow = () => {
        setBorrowKeyTableData({"columns" : borrowKeyColumns, "rows":[]})
        KeysService.getBorrows()
        .then((res) => {
            console.log(res.data);
            setBorrowKeyTableData( {
                "columns": borrowKeyColumns,
                "rows": res.data.map(function(element) {
                        return {
                              id: element.id,
                              key: element.key,
                              trader: element.trader,
                              client: element.client,
                              building: element.building,
                              borrowTime: element.borrow_time,
                              backTime: element.back_time,
                              realBackTime: element.real_back_time,
                              status: <MDBadge badgeContent={element.status === "active" ? "In-Working" : "Borrow-Back"} size="sm" container
                                       color={element.status === "active" ? "success" : "dark"} />,
                              action: (
                                  <div>
                                      {element.status === "active" && <GHIconButton onClick={handleClickItem} id={ element.id} action={"back"}
                                                    rowName={element.client_name} title={"Borrow Back"} color={"secondary"}
                                                    icon={"download"} size={"medium"} />}
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"delete"}
                                                    rowName={element.client_name} title={"Remove"} color={"error"}
                                                    icon={"delete"} size={"medium"} />

                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(true);
                break;
            case "delete" :
                handleOpenDialog(model);
                break;
            case "back":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
        }
    }

    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            KeysService.removeBorrow(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }else if (dialogAction === "back") {
            KeysService.backBorrow(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }
    }


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                handleOpen(elem,"edit");
                break;
            case "back":
                handleOpen(elem,"back");
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete Or Back confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete Or Back this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){
            console.log(values)
            // let unitId = 0;
            // console.log(values)
            // if (values.unit !== undefined && values.unit !== null ){
            //     unitId = values.unit.id;
            // }
            let dataToSend = {
                "client_id": values.client && values.client.id.toString(),
                "building_id": values.building && values.building.id.toString(),
                "trader_id": values.trader && values.trader.id,
                "unit":  values.unit &&  values.unit.id,
                "description": values.description,
                "borrow_date": values.startTime.toString(),
                "back_time": values.endTime.toString(),
                "key_id": values.key && values.key.id,
            }
            console.log(dataToSend);

            KeysService.borrowKey(dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [editMode, setEditMode] = useState(false);
    const renderInsertModal = () => {
        // let activeMode = "key"
        // const [, setActiveMode] = useState("key");
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleAds}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Key": "Borrow Key"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={clients}
                          value={values.client}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.client_name ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, client: value });
                            loadBuilding(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.client}
                                        helperText={errors.client?.message}
                                        label="Client"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("client")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={buildings}
                          value={values.building}
                          clearIcon={true}
                          clearOnEscape={true}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, building: value });
                            loadKey(value.id);
                            loadUnits(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.building}
                                        helperText={errors.building?.message}
                                        label="Building"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("building")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Autocomplete
                          required
                          options={keys}
                          value={values.key}
                          clearIcon={true}
                          clearOnEscape={true}
                          contentEditable={false}
                          getOptionLabel={(option) => option.key_name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values,  key: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.key}
                                        helperText={errors.key?.message}
                                        label="Key"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("key")} />
                          )}
                        />

                  </Grid>

                  <Grid item xs={12} m={1} md={1.6}>
                      <MDTypography textGradient={true} color={"text"} opacity={0.8}>- OR -</MDTypography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Autocomplete
                          required
                          options={units}
                          value={values.unit}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.unit ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, unit: value });

                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.unit}
                                        helperText={errors.unit?.message}
                                        label="Unit"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("unit")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                          required
                          options={traders}
                          value={values.trader}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, trader: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.trader}
                                        helperText={errors.trader?.message}
                                        label="Contractor"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("trader")} />
                          )}
                        />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <BasicDateRangePicker onAccept={(v)=>{
                        let start = "20" + ('0' + v[0].year()).slice(-2) + "-" +('0' + (v[0].month() + 1)).slice(-2) + "-" + ('0' + v[0].date()).slice(-2)
                        let end =  "20" + ('0' + v[1].year()).slice(-2) + "-" +('0' + (v[1].month()+1)).slice(-2) + "-" + ('0' + v[1].date()).slice(-2)
                        handleChange({...values, startTime:start, endTime:end});

                    }}  />
                  </Grid>
                  <Grid item xs={12} md={12} >

                    <TextField
                          fullWidth
                          required
                          multiline
                          rows={4}
                          error={!!errors.description}
                          helperText={errors.description?.message}
                          value={values.description}
                          {...register("description")}
                          onChange={(event) => {
                            handleChange({ ...values, description: event.target.value })
                          }}
                        label="Description"
                        placeholder="Description about this borrow"  />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };


    const handleDownloadCsv = (e) => {saveAs(process.env.PUBLIC_URL + "/resource/template.csv", "template.csv");};
    const borrowKeysList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space" alignItems="flex-start" mb={2}>
                  <MDButton variant="gradient" color="warning" size="large"
                            disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>
                    borrow key
                  </MDButton>
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={borrowKeyTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {borrowKeysList()}
          <Footer />
        </DashboardLayout>
    );

}

export default BorrowKey;