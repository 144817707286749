

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import burceMars from "assets/images/team-2.jpg";
import team2 from "../../../../../../assets/images/team-2.jpg";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import authService, {getClientName, getUserAvatar, getUserNickName} from "../../../../../../services/auth.service";
import userService from "../../../../../../services/user.service";
import {useRef} from "react";
import CoreService from "../../../../../../services/core.service";

import LoadingOverlay from 'react-loading-overlay';
import MDSnackbar from "../../../../../../components/MDSnackbar";
import Checkbox from "@mui/material/Checkbox";
function Header() {
    // const [currentFile, setCurrentFile] = useState(null);
    const [currentFileUrl, setCurrentFileUrl] = useState(getUserAvatar());

    const onFileChange = event => {
        // setCurrentFile(event.target.files[0]);
        const objectUrl = URL.createObjectURL(event.target.files[0])
        setCurrentFileUrl(objectUrl)
        onFileUpload(event.target.files[0])
    };


    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );

    const onFileUpload = (file) => {
    // setUploadInProgress(true);
    // setButtonIcon("sync");
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append( "myFile", file, file.name );

      // Details of the uploaded file
      console.log(file);
      setActiveLoading(true);
      userService.changeAvatar(formData)
          .then((val) =>{
              console.log("hello");
              authService.changeLocalAvatar(val.data["avatar"]);
              // window.location.reload();
              setActiveLoading(false);
              setInfoSB(true);
          })
          .catch((err) =>{
            console.log(err);
              setActiveLoading(false);
              setErrorMessage(err.response.data.status);
              setErrorSB(true);
          })
    };
    const myRef = useRef(null);

    const clickElement = (ref) => {
      ref.current.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      );
    };
    const [activeLoading, setActiveLoading] = useState(false);
    return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
            {renderSB}
            <Grid item xs={12} sm={2} container justifyContent="center">
                <MDBox position="relative" height="max-content" mx="auto">
              <input type={"file"} accept={"image/*"} style={{"display":"none"}} onChange={onFileChange} ref={myRef} />
                <LoadingOverlay active={activeLoading} spinner text='Saving content...'>
                    <MDAvatar src={currentFileUrl} alt="profile picture" size="xxl" variant="rounded" />
                </LoadingOverlay>
              <MDBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                <Tooltip title="Edit" placement="top">
                  <MDButton  variant="gradient" color="info" size="small" iconOnly onClick={() => clickElement(myRef)}>
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip>
                  <Tooltip title="Remove" placement="top" sx={{"marginLeft":1}}>
                  <MDButton  variant="gradient" color="error" size="small" iconOnly>
                    <Icon>remove</Icon>
                  </MDButton>
                </Tooltip>
              </MDBox>
            </MDBox>
            </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {getUserNickName()}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {getClientName()}
              </MDTypography>
            </MDBox>
          </Grid>
          {/*<Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>*/}
          {/*  <MDBox*/}
          {/*    display="flex"*/}
          {/*    justifyContent={{ md: "flex-end" }}*/}
          {/*    alignItems="center"*/}
          {/*    lineHeight={1}*/}
          {/*  >*/}
          {/*    <MDTypography variant="caption" fontWeight="regular">*/}
          {/*      Switch to {visible ? "invisible" : "visible"}*/}
          {/*    </MDTypography>*/}
          {/*    <MDBox ml={1}>*/}
          {/*      <Switch checked={visible} onChange={handleSetVisible} />*/}
          {/*    </MDBox>*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
        </Grid>
      </MDBox>
    </Card>
    );
}

export default Header;
