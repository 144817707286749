import { useState, useEffect, useMemo } from "react";

// react-router components
import {Routes, Route, Navigate, useLocation, useNavigate, Redirect} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routeItems from "routes";
import GreystoneWalkLogo from "assets/images/GreystoneWalkLogo.png";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import SignInCover from "layouts/authentication/sign-in/cover";
// Images
// import brandWhite from "assets/images/aais-logo.png";
// import brandDark from "assets/images/logo-ct-dark.png";
import authService, {getCurrentInstanceLogo, getCurrentInstanceName, getUserRole} from "./services/auth.service";
import userService from "./services/user.service";

import {ToastContainer,toast} from 'react-toastify'
export default function App() {
    const [controller, dispatch] = useMaterialUIController();
    const {
      miniSidenav,
      direction,
      layout,
      openConfigurator,
      sidenavColor,
      transparentSidenav,
      whiteSidenav,
      darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const { pathname } = useLocation();

    // Cache for the rtl
    useMemo(() => {
      const cacheRtl = createCache({
        key: "rtl",
        stylisPlugins: [rtlPlugin],
      });

      setRtlCache(cacheRtl);
    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
      if (miniSidenav && !onMouseEnter) {
        setMiniSidenav(dispatch, false);
        setOnMouseEnter(true);
      }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
      if (onMouseEnter) {
        setMiniSidenav(dispatch, true);
        setOnMouseEnter(false);
      }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
      document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    function RequireAuth({children}) {
        // console.log(children);
        let isAuthenticated = authService.getUserIsLogin();
        //console.log("RequireAuth -> " + isAuthenticated);
        if (isAuthenticated){
            return children;
        }else{
            return <Navigate to={"/authentication/login"} />
        }
    }

    const getRoutes = (allRoutes, perm) =>
        allRoutes.map((route) => {
              if (route.collapse) {
                return getRoutes(route.collapse, perm);
              }
              if (route.route) {
                if (
                  route.perms !== undefined &&
                  route.perms.length > 0 &&
                  (route.perms.indexOf("all") > -1 || route.perms.indexOf(perm) > -1)
                ) {

                  return <Route exact path={route.route} element={route.component} key={route.key} />;
                }
                return null;
              }
              return null;
        });
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <ToastContainer />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={GreystoneWalkLogo}
              brandName={getCurrentInstanceName()}
              routes={routeItems}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
            <Route path="/authentication/login" element={<SignInCover />}  />
            {getRoutes(routeItems, getUserRole())}
            <Route path="*" element={<Navigate to="/dashboards" />}  />
        </Routes>
      </ThemeProvider>
    );
  }
