import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BookingCard from "examples/Cards/BookingCard";
// import Modal from 'react-modal';

// Images
// import booking1 from "assets/images/products/product-1-min.jpg";
// import booking2 from "assets/images/products/product-2-min.jpg";
// import booking3 from "assets/images/products/product-3-min.jpg";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import MDButton from "../../../../components/MDButton";
import DataTable from "../../../../examples/Tables/DataTable";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
// import dataTableData from "./data";
import Modal from '@mui/material/Modal';
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Autocomplete from "@mui/material/Autocomplete";
// import selectData from "../../account/settings/components/BasicInfo/data/selectData";
import FormField from "../../account/components/FormField";
import MDSnackbar from "../../../../components/MDSnackbar";
import MDInput from "../../../../components/MDInput";
import loadDataTable from "./data";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import CustomService from "../../../../services/custome.service";
import validateForm from "../../../../utils";
import {modalStyle} from "../../../../components/Utils/ModalStyle";
import Autocomplete from "@mui/material/Autocomplete";
import AmenityService from "../../../../services/amenities.service";


function AmenityClosure() {

    const [clickedItemId, setClickedItemId] = useState(0);
    const [errorMessage, setErrorMessage] = useState(false);

    const [open, setOpen] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);

    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);

    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleAgreeDeleteDialog = () => {
      CustomService.removeClosure(clickedItemId)
          .then((response) =>{
              console.log(response);
              openInfoSB();
              setTimeout(function () { window.location.reload(); }, 1000);
          })
          .catch((err) => {
              console.log(err);
              setErrorMessage(err);
              openErrorSB();
          });
    };
    function handleDelete(elem) {
        handleOpenDialog();
        setClickedItemId(elem.target.id);
    }

    const [amenities, setAmenities] = useState([]);
    const [selectedAmenity, setSelectedAmenity] = useState({});

    useEffect(()=>{
        AmenityService.getAmenities("")
            .then(data=>{
                setAmenities(data.data);
                setSelectedAmenity(data.data[0])
            }).catch(reason => {

            })

    },[setAmenities, setSelectedAmenity])



    const [disableAction, setDisableAction] = useState(true);
    const [date, setDate] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [description, setDescription] = useState("");
    const [formData, setFormData] = useState({
        "date": { "validation":{is_required:true, min:3}, "error_status": false, "error_message":"", "value": ""},
        "start": { "validation":{is_required:true}, "error_status": false, "error_message":"", "value": ""},
        "end": { "validation":{is_required:true}, "error_status": false, "error_message":"", "value": ""},
        "description": { "validation":{is_required:false}, "error_status": false, "error_message":"", "value": ""},
    })
    const setValue = (source, valueOfElement) => {
        // console.log(source + " -> "+ valueOfElement);
        if(source === "date") setDate(valueOfElement);
        if(source === "start") setStart(valueOfElement);
        if(source === "end") setEnd(valueOfElement);
        if(source === "description") setDescription(valueOfElement);
    }
    const saveDataClicked = (e) =>{
        e.preventDefault();
        let data = {
            "start":start.toString(),
            "end":end.toString(),
            "amenity":selectedAmenity.id.toString() || "0",
            "date":date.toString(),
            "description":description.toString(),
        }
        console.log(data);
        if(!checkForm()){
            CustomService.insertClosure(data)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });
        }
        handleClose();
    }
    const onChangeInput = (element, source) => {
        setDisableAction(false);
        let valueOfElement =  "";
        // console.log(element)
        if("target" in element){
            valueOfElement = element.target.value;
        }else if("id" in element) {
            valueOfElement = element.id
        }
        console.log(valueOfElement)
        let errorObject = validateForm(formData[source]["validation"], source, valueOfElement)
        if(errorObject !=   null){
            setFormData(prevErrors => ({...prevErrors,
                        [source]: {
                            "validation":prevErrors[source]["validation"],
                            "error_status":errorObject["error"],
                            "error_message":errorObject["errorMessage"],
                            "value": valueOfElement}}))

        }
        setValue(source, valueOfElement);
    }
    const checkForm = () => {
        let is_check = false;
        for (const validationKey in formData) {
            let xData = validateForm(formData[validationKey]["validation"],
                            validationKey, formData[validationKey]["value"])
            // console.log(xData)
            if(xData.error){
                is_check = true;
            }
        }
        setDisableAction(is_check);
        return is_check
    }


    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={closeInfoSB}
              close={closeInfoSB}
            />
            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={closeErrorSB}
              close={closeErrorSB}
            />
        </div>
    );
    const renderModal = () => {
        return <Modal open={open} onClose={handleClose} color={"warning"} >
      <MDBox sx={modalStyle}>
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">Insert Closure</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                  <Autocomplete
                      value={selectedAmenity}
                      clearIcon={false}
                      contentEditable={false}
                      options={amenities}
                      onChange={(event,value) => {
                        setSelectedAmenity(value);
                      }}
                      getOptionLabel={(option) => option.name || "--"}
                      renderInput={(params) => (
                        <FormField {...params} label="Amenity" InputLabelProps={{ shrink: true }}   />
                      )}
                    />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormField label={"Select Date"}
                            onChange={e => onChangeInput(e, "date")}
                            error={formData["date"].error_status}
                            helperText={formData["date"].error_message}
                            type={"date"}   />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                        onChange={e => onChangeInput(e, "start")}
                        error={formData["start"].error_status}
                        helperText={formData["start"].error_message}
                        label="Start Time"
                        placeholder="18:30"  type={"time"} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                        onChange={e => onChangeInput(e, "end")}
                        error={formData["end"].error_status}
                        helperText={formData["end"].error_message}
                        label="Start Time"
                        placeholder="18:30"  type={"time"} />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormField
                        onChange={e => onChangeInput(e, "description")}
                        error={formData["description"].error_status}
                        helperText={formData["description"].error_message}
                        label="Description"
                        placeholder="description"   />
              </Grid>
              <Grid item xs={12} md={12}>
                <MDButton color={"info"} size={"large"} onClick={saveDataClicked} >Save Data</MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </Modal>
    };
    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to delete this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }
    const renderAmenityHolidayList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderModal()}
              {renderDialog()}
            <MDBox  display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
              <MDButton variant="gradient" color="info" size="large" onClick={handleOpen}>
                new Closure
              </MDButton>
            </MDBox>
            <Card>
              <DataTable showTotalEntries={true} table={loadDataTable(handleDelete)} entriesPerPage={false} canSearch />
            </Card>
          </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderAmenityHolidayList()}
          <Footer />
        </DashboardLayout>
    );
}

export default AmenityClosure;
