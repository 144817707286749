import { useEffect, useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
// import MDInput from "components/MDInput";

// NewProduct page components
// import FormField from "layouts/ecommerce/products/new-product/components/FormField";
// import Header from "../../../../applications/calendar/components/Header";

import EventCalendar from "../../../../../examples/Calendar";
import calendarEventsData from "../../../../applications/calendar/data/calendarEventsData";
import MDBadgeDot from "../../../../../components/MDBadgeDot";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../../../account/components/FormField";
import MDButton from "../../../../../components/MDButton";
import Card from "@mui/material/Card";
// import NextEvents from "../../../../applications/calendar/components/NextEvents";
// import ProductivityChart from "../../../../applications/calendar/components/ProductivityChart";

function SelectDate({values, setValues, ...other}) {
  // const [editorValue, setEditorValue] = useState(
  //   "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
  // );

  const handleEventClick = (arg) => {
    // const destNode = arg.el.parentNode;
    // // console.log(destNode)
    // destNode.click()
    // console.log(arg.event.backgroundColor)
    // arg.el.backgroundColor = 'blue';
      // $('a.fc-timeline-event.fc-h-event.fc-event').removeClass('active-event');
      // $('a.fc-timeline-event.fc-h-event.fc-event.'+ arg).addClass('active-event');


    // destNode.backgroundColor = "red"
  }
  const [selectedDate, setSelectedDate] = useState("");
  const handleDateClick = (e) => {
    // if(arg.el !== undefined){
    //   const eventDate = arg.event.start;
    //   alert(eventDate)
    // }
    // bind with an arrow function
    // alert(arg.dateStr);
    let selected = {} ;
    const day_capacity = e.target.getAttribute("capacity");
    const week_day = e.target.getAttribute("weekday");
    const date = e.target.getAttribute("date");
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    setSelectedDate(date);
    // e.target.style = "background:rgb(65 102 79)"


    // values.dates[0]["is_active"] = true;

    selected["times"] = JSON.parse(e.target.getAttribute("times"));
    selected["date"] = date;
    selected["week_day"] = days[week_day];
    selected["weekday"] = days[week_day];
    selected["day_capacity"] = day_capacity;

    setValues({...values,  selectedDate:selected, isValid:true, autoNext: true});

    // let valid_date = false;
    //
    // let message = "";
    //


    // values.dates.forEach(function (k){
    //       if (k["date"] === arg.dateStr ){
    //         if ( parseInt(k["day_capacity"]) > 0){
    //           valid_date = true;
    //           selected = k;
    //         }else{
    //           alert("capacity full")
    //         }
    //       }
    //   });
    //   if(valid_date){
    //     valid_date=false;
    //     setValues({...values,  selectedDate:selected, isValid:true});
    //   }else{
    //     setValues({...values,  isValid:false});
    //   }
  };

  const renderDate = () =>{
    let x = [];
    if (values.dates){
      values.dates.forEach(function (k){
        // console.log(k)
          x.push(
              <Grid item xs={12} md={3}>
                <MDButton color={selectedDate === k["date"] ? "dark" : k["class_label"] }
                          disabled={k["day_capacity"] === 0}
                          size={"large"} fullWidth date={k["date"]}
                          capacity={k["day_capacity"]} weekday={k["week_day"]} times={JSON.stringify(k["times"])}
                          onClick={handleDateClick}  >{k["date"]} ( {k["label"]} )</MDButton>
              </Grid>
          )
      })
    }
    return x;
  }

  return (
    <MDBox>
      <MDBox width="80%" textAlign="center" mx="auto" my={0}>
        <MDBox mb={1}>
          <MDTypography variant="h2" fontWeight="regular">
            Which day do you want to reserve ?
          </MDTypography>
        </MDBox>
        <MDTypography variant="body3" color="text">
          select date to rserve and click on next
        </MDTypography>
      </MDBox>
      <MDBox pt={3}>
        <Grid container spacing={3}>
          <Grid item xl={12} sx={{ height: "max-content" }}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  {renderDate()}

                </Grid>
              </MDBox>
            </Card>
            {/*{useMemo(*/}
            {/*  () => (*/}
            {/*    <EventCalendar*/}
            {/*      initialView="dayGridMonth"*/}
            {/*      dateClick={handleDateClick}*/}
            {/*      eventClick={handleEventClick}*/}
            {/*      events={loadDate()}*/}
            {/*    />*/}
            {/*  ),*/}
            {/*  [loadDate]*/}
            {/*)}*/}
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={1} pt={2}>
        <Grid item sm={3}>
          <MDBadgeDot badgeContent="Today" size="sm" color="warning" />
        </Grid>
        <Grid item sm={3}>
          <MDBadgeDot badgeContent="Available Date" size="sm" color="success" />
        </Grid>
        <Grid item sm={3}>
          <MDBadgeDot badgeContent="Full" size="sm" color="error" />
        </Grid>
        <Grid item sm={3}>
          <MDBadgeDot badgeContent="Holiday" size="sm" color="info" />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default SelectDate;
