import Icon from "@mui/material/Icon";
// import MDBadge from "../../../../components/MDBadge";
import {useEffect, useMemo, useState} from "react";
import CustomService from "../../../services/custome.service";
import GHIconButton from "../../../components/GHIconButton";

function loadDataTable(clickItem){

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([
        { Header: "Name", accessor: "name",  width: "20%" },
        { Header: "ShortName", accessor: "short",  width: "20%" },
        { Header: "Symbol", accessor: "symbol",  width: "20%" },
        { Header: "Actions", accessor: "action", width: "7%" },
    ]);
    useEffect(()=> {
      CustomService.getCurrencies()
        .then((res) => {
          setRows(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, [setColumns, setRows] )
    return {
        "columns": columns,
        "rows": rows.map(function(element)  {

            return {
                      id: element.id,
                      short: element.short_name,
                      name: element.name,
                      symbol: element.symbol,
                      action: (
                        <GHIconButton  onClick={clickItem} id={element.id} title={"Delete"} icon={"delete"} size={"small"}  color="error" />
                      ),
                }
            }),
    }
}

export default loadDataTable;
