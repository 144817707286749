import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const insertAmenity = (data) => {
  return axios.post(`${getPrivateURL()}amenities`, data, {withCredentials:true});
};
export const deleteAmenity = (amenityId) => {
  return axios.delete(`${getPrivateURL()}amenities/`  +amenityId,{withCredentials:true});
};
export const uploadImage = (form_data) => {
  return axios.post(`${getPrivateURL()}amenities/images`, form_data, {withCredentials:true,
    headers:{
    'Content-Type':'multipart/form-data'
    }});
};
export const getAmenities = (filter) => {
  console.log("Filter in request is " + filter)
  return axios.get(`${getPrivateURL()}amenities?filter=${filter}`);
};

export const getAmenitiesType = () => {
  return axios.get(`${getPrivateURL()}amenities/types`);
};

export const deleteAmenitiesType = (amenityType) => {
  return axios.delete(`${getPrivateURL()}amenities/types/${amenityType}` );
};

export const insertAmenityType = (parent_id, amenity_type) => {
  return axios.post(`${getPrivateURL()}amenities/types`, {parent_id, amenity_type}, {withCredentials:true} );
};

export const removeAmenityType = (parent_id) => {
  return axios.delete(`${getPrivateURL()}amenities/types/${parent_id.toString()}` );
};

export const getAmenityInfoCustomUser = (id, userid) => {
  return axios.get(`${getPrivateURL()}amenities/${id.toString()}/${userid.toString()}` );
};
export const getAmenityInfo = (id) => {
  return axios.get(`${getPrivateURL()}amenities/${id.toString()}` );
};

export const createBooking = (id, booking_data) => {
  return axios.post(`${getPrivateURL()}booking/amenities/${id.toString()}` , booking_data,
      {withCredentials:true});
};
export const getBookings = (start, end, status) => {
  return axios.get(`${getPrivateURL()}bookings/?start=${start}&end=${end}&status=${status}`, {withCredentials:true});
};
export const cancelBooking = (id) => {
  return axios.delete(`${getPrivateURL()}bookings/${id.toString()}` , {withCredentials:true});
};

const AmenitiesService = {
  insertAmenity,
  uploadImage,
  deleteAmenity,
  getAmenities,
  getAmenitiesType,
  deleteAmenitiesType,
  insertAmenityType,
  removeAmenityType,
  getAmenityInfo,
  getAmenityInfoCustomUser,
  createBooking,
  getBookings,
  cancelBooking,
};
export default AmenitiesService;
