import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {getUserRole} from "../../../../services/auth.service";
import ClientsService from "../../../../services/clients.service";
import {keyColumns} from "../../../../components/Utils/HeaderColumns";
import MDBadge from "../../../../components/MDBadge";
import GHIconButton from "../../../../components/GHIconButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import MDSnackbar from "../../../../components/MDSnackbar";
import Modal from "@mui/material/Modal";
import MDBox from "../../../../components/MDBox";
import {modalStyleAds, modalStyleClients, modalStyleUser} from "../../../../components/Utils/ModalStyle";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import TextField from "@mui/material/TextField";
import {Link} from "react-router-dom";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import KeysService, {getKeyItem} from "../../../../services/keys.service";
import ElevatorsService from "../../../../services/elevators.service";

function KeysList() {

    const defaultInputValues = {   keyName: ""};

    const [values, setValues] = useState(defaultInputValues);

    const validationSchema = Yup.object().shape({
        // type: Yup.string()
        //     .required('Key Type is required'),
        keyName: Yup.string()
            .required('Key Name is required'),
    });

    const { register, handleSubmit, formState: { errors  } } =
            useForm({mode:"all", resolver:yupResolver(validationSchema)});

    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };

    const [isValid, setIsValid] = useState(false);

    const [selectedInstance, setSelectedInstance] = useState({});
    const [units, setUnits] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [clients, setClients] = useState([]);
    const [keyTypes, setKeyTypes] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);

    const [infoSB, setInfoSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openInfoSB = () => setInfoSB(true);
    const closeInfoSB = () => setInfoSB(false);
    const [errorMessage, setErrorMessage] = useState("");
    const isAdmin = getUserRole() === "super_admin"
    useEffect(() => {
        loadClients();
        loadKey();
        loadKeyTypes();
    }, [setClients, setBuildings, setUnits]);

    const loadClients = (instance_id) => {
        ClientsService.getClients(instance_id)
        .then((res) => {
            console.log(res.data);
            setClients(res.data);
            if (res.data.length > 0){
                loadBuilding(res.data[0].id);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadBuilding = (client_id) => {
        ClientsService.getClientsBuilding(client_id)
        .then((res) => {
            console.log(res.data);
            setBuildings(res.data);
            if(res.data[0].length > 0){
                loadUnit(res.data[0].id);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const loadUnit = (building_id) => {
        ClientsService.getBuildingUnits(building_id)
        .then((res) => {
            console.log(res.data);
            setUnits(res.data);
            // loadProvince(res.data[0]);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const loadKeyTypes = () => {
        KeysService.getKeyTypes()
        .then((res) => {
            console.log(res.data);
            setKeyTypes(res.data);
            // loadProvince(res.data[0]);
        })
        .catch((error) => {
            console.log(error);
        });
    }



    const [keyTableData, setKeyTableData] =
                        useState({"columns" :
                            keyColumns, "rows":[]});

    const loadKey = () => {
        setKeyTableData({"columns" : keyColumns, "rows":[]})
        KeysService.getKeys()
        .then((res) => {
            console.log(res.data);
            setKeyTableData( {
                "columns": keyColumns,
                "rows": res.data.map(function(element) {
                        return {
                              id: element.id,
                              keyName: element.key_name,
                              type: element.type,
                              // unit: element.unit,
                              client: element.client,
                              building: element.building,
                              status: <MDBadge badgeContent={"available"} size="sm" container
                                       color={element.status === "active" ? "success" : "dark"} />,
                              action: (
                                  <div>

                                      <GHIconButton onClick={handleClickItem} id={element.id} action={"edit"}
                                                    rowName={element.client_name} title={"Edit"} color={"warning"}
                                                    icon={"edit"} size={"medium"} />
                                      <GHIconButton onClick={handleClickItem} id={element.id} action={"borrow"}
                                                    rowName={element.client_name} title={"Borrow"} color={"info"}
                                                    icon={"directions_run"} size={"medium"} />
                                      <GHIconButton onClick={handleClickItem} id={ element.id} action={"delete"}
                                                    rowName={element.client_name} title={"Remove"} color={"error"}
                                                    icon={"delete"} size={"medium"} />

                                  </div>
                              ),
                            }
                    })
            })

        })
        .catch((error) => {
          console.log(error);
        });
    }

    const [clickedItemId, setClickedItemId] = useState(0);

    const [openInsertModel, setOpenInsertModel] = useState(false);
    const [openUserModel, setOpenUserModel] = useState(false);

    const handleOpen = (event, model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(true);
                break;
            case "delete" :
                handleOpenDialog(model);
                break;
            case "change_status":
                handleOpenDialog(model);
                break;
        }
    }
    const handleClose = (model) => {
        switch (model){
            case "insert":
                setOpenInsertModel(false);
                break;
        }
    }


    const [dialogAction, setDialogAction] = useState("")
    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setDialogAction(action)
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleAgreeDeleteDialog = () => {
        if (dialogAction === "delete") {
            KeysService.removeKey(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }if (dialogAction === "change_status") {
            KeysService.disableKey(clickedItemId)
                .then((response) => {
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err);
                    openErrorSB();
                });

        }
    }


    function handleClickItem(elem) {
        let actionToDo = elem.target.getAttribute("action");
        let rowName = elem.target.getAttribute("rowname");
        let rowId = elem.target.id;
        // alert(rowId)
        setClickedItemId(rowId);
        setSelectedClientTitle(rowName);
        switch (actionToDo) {
            case "edit":
                setEditMode(true)
                loadSingleKey(rowId)
                break;
            case "borrow":
                window.location.href = "/Keys/Borrow?key=" + rowId
                // handleOpen(elem,"borrow");
                break;
            case "delete":
                handleOpen(elem,"delete");
                break;
        }
    }

    const [userTableData, setUserTableData] = useState({});
    const [selectedClientTitle, setSelectedClientTitle] = useState("");

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle><Icon color={"warning"} >warning</Icon>{" Delete/Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog} >Disagree</MDButton>
              <MDButton onClick={handleAgreeDeleteDialog}  size={"large"}  color={"info"}  >Agree</MDButton>
            </DialogActions>
          </Dialog>
        )
    }


    const loadSingleKey = (key_id) =>{
        // alert(clickedItemId)
        if (key_id > 0){
             KeysService.getKeyItem(key_id)
            .then((result)=>{
                loadBuilding(result.data.building.client.id)
                console.log(result.data)
                setValues({
                    id: key_id,
                    keyName : result.data.key_name,
                    building: {id:result.data.building.id, name:result.data.building.building_name},
                    client: {id:result.data.building.client.id, client_name:result.data.building.client.client_name},
                    type: {id:result.data.key_type.id, key_type:result.data.key_type.type_of_key},
                });
                setOpenInsertModel(true);
            }).catch((reason) =>{
                setErrorMessage(reason.response.data.status.toString());
                openErrorSB();
            });
        }

    };
    const saveDataClicked = (e) =>{
        e.preventDefault();
        if(isValid){
            // let unitId = 0;
            // console.log(values)
            // if (values.unit !== undefined && values.unit !== null ){
            //     unitId = values.unit.id;
            // }
            let dataToSend = {
                "client": values.client.id.toString(),
                "building_id": values.building.id,
                // "unit": unitId.toString(),
                "key_type_id": values.type.id,
                "key_name": values.keyName.toString()
            }
            console.log(dataToSend);
            if(editMode){
                KeysService.updateKey(values.id, dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });

            }else{
                KeysService.insertKey(dataToSend)
                .then((response) =>{
                    console.log(response);
                    openInfoSB();
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(err.response.data.status.toString());
                    openErrorSB();
                });
            }
        }
        // handleClose();
    }

    const renderSB = (
        <div>
            <MDSnackbar
              color="success"
              icon="notifications"
              title="Amenity System"
              content="Your data saved successfully"
              dateTime=""
              open={infoSB}
              onClose={()=>setInfoSB(false)}
              close={()=>setInfoSB(false)}  />

            <MDSnackbar
              color="error"
              icon="error"
              title="Amenity System"
              content={errorMessage}
              dateTime=""
              open={errorSB}
              onClose={()=>setErrorSB(false)}
              close={()=>setErrorSB(false)}/>
        </div>
    );
    const [editMode, setEditMode] = useState(false);
    const renderInsertModal = () => {
        return <Modal open={openInsertModel} onClose={(e)=>{handleClose("insert")}} color={"warning"} >
          <MDBox sx={modalStyleAds}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" >{editMode ? "Edit Key": "Insert New Key"}</MDTypography>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>

                    <TextField
                          fullWidth
                          required
                          error={!!errors.keyName}
                          helperText={errors.keyName?.message}
                          value={values.keyName}
                          {...register("description")}
                          onChange={(event) => {
                            handleChange({ ...values, keyName: event.target.value })
                          }}
                        label="Key name (or place name)"
                        placeholder="Left door corridor"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={clients}
                          value={values.client}
                          clearIcon={false}
                          clearOnBlur={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.client_name ?? "loading...."}
                          onChange={(event,value) => {
                            setValues({ ...values, client: value });
                            loadBuilding(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.client}
                                        helperText={errors.client?.message}
                                        label="Client"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("client")}/>
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                          required
                          options={buildings}
                          value={values.building}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.name ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, building: value });
                            // loadUnit(value.id);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.building}
                                        helperText={errors.building?.message}
                                        label="Building"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("building")} />
                          )}
                        />
                  </Grid>
                  {/*<Grid item xs={12} md={6}>*/}
                  {/*  <Autocomplete*/}
                  {/*        required*/}
                  {/*        options={units}*/}
                  {/*        value={values.unit}*/}
                  {/*        clearIcon={false}*/}
                  {/*        contentEditable={false}*/}
                  {/*        getOptionLabel={(option) => option.unit ?? "loading...."}*/}
                  {/*        onChange={(event,value) => {*/}
                  {/*          handleChange({ ...values, unit: value });*/}
                  {/*        }}*/}
                  {/*        renderInput={(params) => (*/}
                  {/*          <MDInput {...params}*/}
                  {/*                      error={!!errors.unit}*/}
                  {/*                      helperText={errors.unit?.message}*/}
                  {/*                      label="Unit"*/}
                  {/*                      InputLabelProps={{ shrink: true }}*/}
                  {/*                      {...register("unit")} />*/}
                  {/*        )}*/}
                  {/*      />*/}
                  {/*</Grid>*/}
                  <Grid item xs={12} md={12}>
                     <Autocomplete
                          required
                          options={keyTypes}
                          value={values.type}
                          clearIcon={false}
                          contentEditable={false}
                          getOptionLabel={(option) => option.key_type ?? "loading...."}
                          onChange={(event,value) => {
                            handleChange({ ...values, type: value });
                          }}
                          renderInput={(params) => (
                            <MDInput {...params}
                                        error={!!errors.type}
                                        helperText={errors.type?.message}
                                        label="Type"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("type")} />
                          )}
                        />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDButton disabled={!isValid} color={"info"} size={"large"}
                              onClick={saveDataClicked} >Save Data</MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
        </Modal>
    };


    const handleDownloadCsv = (e) => {saveAs(process.env.PUBLIC_URL + "/resource/template.csv", "template.csv");};
    const renderKeysList = () => {
        return(
            <MDBox my={3}>
              {renderSB}
              {renderInsertModal()}
              {renderDialog()}
              <MDBox  display="flex" justifyContent="space" alignItems="flex-start" mb={2}>
                  <MDButton variant="gradient" color="warning" size="large"
                            disabled={selectedInstance.id === ""} onClick={(e) => {handleOpen(e,"insert")}}>
                    new key
                  </MDButton>
                  <MDButton variant="gradient" color="secondary" sx={{"marginLeft":"20px", "display":"none"}}  size="large"
                             onClick={(e) => {handleOpen(e,"insert_bulk")}}>
                    new Key (From CSV)
                  </MDButton>
                  <a href={"#"} style={{"display":"none"}} onClick={handleDownloadCsv}>
                      <MDTypography variant={"caption"} fontWeight={"light"}   sx={{"margin":"20px"}} opacity={"0.5"}>Download Template</MDTypography>
                  </a>
              </MDBox>
              <Card>
                    <DataTable showTotalEntries={true}
                       table={keyTableData}
                       entriesPerPage={false}
                       canSearch />
              </Card>
            </MDBox>
        )
    };

    return (
        <DashboardLayout>
          <DashboardNavbar />
            {renderKeysList()}
          <Footer />
        </DashboardLayout>
    );

}

export default KeysList;