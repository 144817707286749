import authService from "../../../services/auth.service";
import SignInCover from "../sign-in/cover/index"
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

function SignOut() {
    useEffect(()=>{
        authService.logout();
    });
    const navigate = useNavigate();
    navigate("/authentication/login")
    return (<p>GoodBy3</p>)
}

export default SignOut;