import Icon from "@mui/material/Icon";
import MDBadge from "../../../../components/MDBadge";
// import MDButton from "../../../../components/MDButton";
// import IconButton from "../../../../assets/theme/components/iconButton";

const dataTableData = {
  columns: [
    { Header: "Name", accessor: "name", width: "20%" },
    { Header: "Amenity", accessor: "amenity", width: "25%" },
    { Header: "Client", accessor: "client" },
    { Header: "Book Date", accessor: "bookDate" },
    { Header: "Status", accessor: "status", width: "7%" },
    { Header: "Ticket", accessor: "downloadData", width: "7%" },
  ],
  rows: [
    {
      name: "Hanny Baniard",
      amenity: "Beautiful Castle",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "4/11/2021",
      client: "Wolf Wallstreet",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
    {
      name: "Jessie Jackson",
      amenity: "Beautiful Castle",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "4/11/2021",
      client: "Wolf Wallstreet",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
    {
      name: "Emma Watson",
      amenity: "Beautiful Castle",
      status: <MDBadge badgeContent="active" color="success" size="sm" container />,
      age: 42,
      bookDate: "4/11/2021",
      client: "Wolf Wallstreet",
      downloadData: (
        <Icon
          color="secondary"
          sx={{
            display: "grid",
            placeItems: "center",
          }}
        >
          download
        </Icon>
      ),
    },
  ],
};

export default dataTableData;
