

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {Link, useLocation} from "react-router-dom";
import MDSocialButton from "../../../components/MDSocialButton";
import {getUserRole} from "../../../services/auth.service";

function BookingCard({ image, title, description, price,currency, location,duration, action, bookId, buttonText }) {

    // alert(getUserRole())
    const isAdmin = getUserRole() === "super_admin" || getUserRole() === "admin"
  const renderUrl =(bookId) =>{
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('userid');
    if(id !== undefined && id !== null){
      return `/amenities/book?id=${bookId}&userid=${id}`
    }else{
      return `/amenities/book?id=${bookId}`
    }
  }

  return (
    <Card
      // sx={{
      //   "&:hover .card-header": {
      //     transform: action && "translate3d(0, -50px, 0)",
      //   },
      // }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="0"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox textAlign="center" pt={3} px={3}>
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={action ? -8 : -4.25}>
          {action}
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
          {title}
        </MDTypography>
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          {description}
        </MDTypography>
      </MDBox>
      <Divider />
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={0.5} pb={3} px={3} lineHeight={1}>
        <MDTypography variant="body2" fontWeight="regular" color="text">
          {price > 0 ? `${price} ${currency}`  + " / " : "Free / "}<h5 style={{display:"inline"}}>{duration}</h5>
        </MDTypography>
        <MDBox color="text" display="flex" alignItems="center">
          <MDSocialButton color="slack" size="small" component={Link} to={renderUrl(bookId)} sx={{"margin":"10px"}}>
            <Icon color="inherit" sx={{ m: 0.5 }}>addbox</Icon>
            {buttonText}
          </MDSocialButton>
          <MDSocialButton color="github" size="small" component={Link} to={`/users?amenity_id=${bookId}`} sx={{"display": isAdmin ? "flex" : "none"}}>
            <Icon color="inherit" sx={{ m: 0.5 }}>adjust</Icon>
            Book For Others
          </MDSocialButton>
          {/* <MDTypography variant="button" fontWeight="light" color="text"> */}
          {/*  {location} */}
          {/* </MDTypography> */}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of BookingCard
BookingCard.defaultProps = {
  action: false,
  buttonText: "Book Now",
};

// Typechecking props for the BookingCard
BookingCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bookId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  location: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  duration: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
};

export default BookingCard;
