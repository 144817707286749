import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

axios.defaults.withCredentials = true;

export const getInstances = () =>  axios.get(`${getPrivateURL()}instances/`);
export const insertInstance = (formData) =>  axios.post(`${getPrivateURL()}instances/`,
    formData, {withCredentials:true, headers:{
        'Content-Type':'multipart/form-data'
      }});
export const removeInstance = (instance_id) =>  axios.delete(`${getPrivateURL()}instances/${instance_id}/`, {withCredentials:true});
export const disableInstance = (instance_id) =>  axios.patch(`${getPrivateURL()}instances/${instance_id}/`, {withCredentials:true});

const InstancesService = {
  getInstances,
  // getAmenitiesType,
  // deleteAmenitiesType,
  insertInstance,
  removeInstance,
  disableInstance,
};
export default InstancesService;
